import React, { useState } from 'react'
import { Container, DropdownButton } from 'react-bootstrap'
import { DataGrid } from "@mui/x-data-grid";
import { NavLink } from 'react-router-dom';
import { Assets } from '../../constant/images';
import { Dropdown } from "react-bootstrap";
import { UserIcon } from './Tablesvg';
import DeleteModal from './DeleteModal';
import EditModal from './EditModal';


const TableComponent = () => {
    const [deletemodalShow, setDeleteModalShow] = useState(false);
    const [editmodalShow, setEditModalShow] = useState(false);

  const columns = [
    {
      field: "UserId",
      headerName: "Product Title",
      width: 350,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
            <div className="d-flex align-items-center mt-2">
              <img className="img-fluid" src={params.row.image} />
              <div className="product-table-title ps-2">
                {params.row.producttitle}
              </div>
            </div>
        );
      },
    },
    {
      field: "startdate",
      headerName: "Start Date",
      width: 350,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
              <div className="start-date">
              {params.row.startdate}
              </div>
        );
      },
    },
    {
        field: "startdate",
        headerName: "Start Date",
        width: 350,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => {
          return (
                <div className="start-date">
                {params.row.startdate}
                </div>
          );
        },
      },
    {
      field: "projectdate",
      headerName: "End Date",
      width: 350,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
              <div className="start-date">
              {params.row.projectdate}
              </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Project Status",
      width: 350,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (<div>{params.row.status}</div>)
      },
    },
    {
        headerName: "Action",
        width: 350,
        id: "action",
        name: "Action",
        align: "center",
        headerAlign: "center",
        renderCell: (cellValues) => {
          return (
            <div className="user-button">
              <DropdownButton id="dropdown-basic-button" title={<UserIcon />}>
                <Dropdown.Item
                  // onClick={() => setShowEditNew(true)}
                  onClick={() => setEditModalShow(true)}

                >
                  Edit
                </Dropdown.Item>
                <Dropdown.Item
                  // onClick={() => setShowDelete(true)}
                  onClick={() => setDeleteModalShow(true)}

                >
                  Delete
                </Dropdown.Item>
              </DropdownButton>
            </div>
          );
        },
      },
  ];

  const rows = [
    {
      id: 1,
      image: Assets.Tableavatarone,
      producttitle: "RW5562",
      startdate: "Mar 22, 2022",
      projectdate: "Mar 29, 2022",
      status: "Dispatched",
      price: "$120.00",
    },

    {
      id: 2,
      image: Assets.Tableavatartwo,
      producttitle: "RW5562",
      UserId: "02",
      startdate: "Mar 22, 2022",
      projectdate: "Mar 29, 2022",
      status: "In Process",
      price: "$120.00",
    },

    {
      id: 3,
      image: Assets.Tableavatarthree,
      producttitle: "RW5562",
      UserId: "03",
      startdate: "Mar 22, 2022",
      projectdate: "Mar 29, 2022",
      status: "Completed",
      price: "$120.00",
    },
    {
      id: 4,
      image: Assets.Tableavatarfour,
      producttitle: "RW5562",
      UserId: "04",
      startdate: "Mar 22, 2022",
      projectdate: "Mar 29, 2022",
      status: "Dispatched",
      price: "$120.00",
    },
    {
      id: 5,
      image: Assets.Tableavatarone,
      producttitle: "RW5562",
      UserId: "05",
      startdate: "Mar 22, 2022",
      projectdate: "Mar 29, 2022",
      status: "In Process",
      price: "$120.00",
    },
    {
      id: 6,
      image: Assets.Tableavatartwo,
      producttitle: "RW5562",
      UserId: "06",
      startdate: "Mar 22, 2022",
      projectdate: "Mar 29, 2022",
      status: "Completed",
      price: "$120.00",
    },
    {
      id: 7,
      image: Assets.Tableavatarthree,
      producttitle: "RW5562",
      UserId: "07",
      startdate: "Mar 22, 2022",
      projectdate: "Mar 29, 2022",
      status: "Dispatched",
      price: "$120.00",
    },
    {
      id: 8,
      image: Assets.Tableavatarfour,
      producttitle: "RW5562",
      UserId: "08",
      startdate: "Mar 22, 2022",
      projectdate: "Mar 29, 2022",
      status: "In Process",
      price: "$120.00",
    },
    {
      id: 9,
      image: Assets.Tableavatarone,
      producttitle: "RW5562",
      UserId: "09",
      startdate: "Mar 22, 2022",
      projectdate: "Mar 29, 2022",
      status: "Completed",
      price: "$120.00",
    },
  ];

  return (
    <>
    <Container>
    <div>
       
        <div  className="table-container">
        <div style={{ height: 560, width: "100%" }}>
          <DataGrid
            className="datagrid-table"
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[7]}
            checkboxSelection
          />
        </div>
        </div>
    </div>
    </Container>
    <DeleteModal
        heading="Delete Product"
        type="delete this product"
        btntext="Delete"
        display="onClick={()=>{props.onHide}}"
        show={deletemodalShow}
        onHide={() => setDeleteModalShow(false)}
      />

<EditModal
        heading="Edit Product"
        type="edit this product"
        btntext="Edit"
        display="onClick={()=>{props.onHide}}"
        show={editmodalShow}
        onHide={() => setEditModalShow(false)}
      />
    </>
  )
}

export default TableComponent