import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { BackArrow } from "./svg";
import SideMenu from "./SideMenu"
const SettingsLayout = ({children}) => {
  return (
    <>
      <section className="main-settings">
        <Container>
          <div className="bread-crumb d-flex align-items-center">
            <NavLink to="/my-profile">
              <BackArrow />
            </NavLink>
            <h2 className="ms-3">Settings</h2>
          </div>
          <Row className="mt-25">
            <Col lg={5}>
                <SideMenu/>
            </Col>
            <Col lg={7}>
                {children}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default SettingsLayout;
