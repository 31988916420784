import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Assets } from '../../constant/images'

const SigninPageSyntax = () => {

  return (
    <div className="signin-syntax">
        <Container>
            <div className='signin-container'>
                <Row>
                    <Col md={5}>
                      <div className="signin-data">
                      <div className='d-flex align-items-end justify-content-between'>
                      <img className="img-fluid" src={Assets.cuetipsignin}/><div className='already-account'>Already have an account? <span>Sign in</span></div>
                      </div>
                      <hr/>
                      <div className='main-heading'>Welcome to Cuetip</div>
                      <div className='sub-heading'>Tech and Data provider in the cannabis space</div>
                      <div className='description'>Cuetip is a Tech and Data provider of premium technology and services to the entire supply chain in the cannabis space. cuetip provides exclusive insights into the rapidly evolving
                      cannabis market and captures current data.</div>
                      <div className="short-hr">
                        <hr/>
                      </div>
                      <div>YOUR REQUIRED DATA WILL BE ENTERED HERE IN THIS DIV.</div>
                      </div>
                    </Col>
                </Row>
            </div>
        </Container>
    </div>
  )
}

export default SigninPageSyntax