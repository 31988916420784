import Aos from 'aos';
import React, { useEffect } from 'react'
import "aos/dist/aos.css";
import Particles from 'react-tsparticles';
import Particlesconfig from './particles';



const Particlesbg = () => {
    // useEffect(function () {
    //     Aos.init({ duration: 1000 });
    //   }, []);
  return (
    <div>
      {/* <div
        data-aos= "fade-up"
        style={{
          backgroundColor: "red",
          width: "50%",
          height: "100px",
          margin: "300px auto 300px auto"
        }}
      ></div> */}

<Particles
        params={Particlesconfig}
      />
    </div>
  )
}

export default Particlesbg