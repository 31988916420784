import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { FaQuoteRight } from "react-icons/fa";

// import required modules
import { Pagination } from "swiper";
import { Assets } from "../../constant/images";
const TestimonialsStyleOne = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <>
      <div className="testimonial-section-css">
        <div className="main-testimonial">
          <div className="main-heading-div">
            <div className="">
              <h2>CUSTOMERS REVIEWS</h2>
            </div>
            <div className="quote">
              <FaQuoteRight />
            </div>
            <div className="main-review-slider">
              <Swiper
                pagination={true}
                modules={[Pagination]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div className="test-detail">
                    <p className="desc">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Voluptatem voluptate suscipit asperiores quibusdam magnam,
                      assumenda accusantium facilis. Reprehenderit quidem
                      laborum ducimus possimus voluptatibus aut inventore dolor.
                      Dolorem dolore quae deleniti!
                    </p>
                    <div className="client-detail">
                      <div className="avatar">
                        <img
                          src={Assets.TestimonialAvatarOne}
                          alt=""
                          className=""
                        />
                      </div>
                      <div className="content">
                        <h6>Jane Dae</h6>
                        <p>Online Marketer</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="test-detail">
                    <p className="desc">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Voluptatem voluptate suscipit asperiores quibusdam magnam,
                      assumenda accusantium facilis. Reprehenderit quidem
                      laborum ducimus possimus voluptatibus aut inventore dolor.
                      Dolorem dolore quae deleniti!
                    </p>
                    <div className="client-detail">
                      <div className="avatar">
                        <img
                          src={Assets.TestimonialAvatarOne}
                          alt=""
                          className=""
                        />
                      </div>
                      <div className="content">
                        <h6>Jane Dae</h6>
                        <p>Online Marketer</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="test-detail">
                    <p className="desc">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Voluptatem voluptate suscipit asperiores quibusdam magnam,
                      assumenda accusantium facilis. Reprehenderit quidem
                      laborum ducimus possimus voluptatibus aut inventore dolor.
                      Dolorem dolore quae deleniti!
                    </p>
                    <div className="client-detail">
                      <div className="avatar">
                        <img
                          src={Assets.TestimonialAvatarOne}
                          alt=""
                          className=""
                        />
                      </div>
                      <div className="content">
                        <h6>Jane Dae</h6>
                        <p>Online Marketer</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestimonialsStyleOne;
