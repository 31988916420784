import React, { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const ExpandableSearch = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  const handleMouseLeave = () => {
    setIsExpanded(false);
  };

  return (
    <Container>
      <div className="search-bar mt-5" onMouseLeave={handleMouseLeave}>
        {!isExpanded && (
          <div className="search-container" onClick={handleClick}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.58341 17.5003C13.9557 17.5003 17.5001 13.9559 17.5001 9.58366C17.5001 5.2114 13.9557 1.66699 9.58341 1.66699C5.21116 1.66699 1.66675 5.2114 1.66675 9.58366C1.66675 13.9559 5.21116 17.5003 9.58341 17.5003Z"
                stroke="#5C5C5C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.3334 18.3337L16.6667 16.667"
                stroke="#5C5C5C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        )}
        {isExpanded && (
          <div className="expanded-search">
            <InputGroup>
              <Form.Control
                placeholder="Search Record"
                aria-label="Search Record"
                aria-describedby="basic-addon2"
              />
              <InputGroup.Text id="basic-addon2">
              <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.58341 17.5003C13.9557 17.5003 17.5001 13.9559 17.5001 9.58366C17.5001 5.2114 13.9557 1.66699 9.58341 1.66699C5.21116 1.66699 1.66675 5.2114 1.66675 9.58366C1.66675 13.9559 5.21116 17.5003 9.58341 17.5003Z"
                stroke="#5C5C5C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.3334 18.3337L16.6667 16.667"
                stroke="#5C5C5C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
              </InputGroup.Text>
            </InputGroup>
          </div>
        )}
      </div>
    </Container>
  );
};

export default ExpandableSearch;
