export function PatientsIcon(){
    return(
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 4C9.92893 4 8.25 5.67893 8.25 7.75C8.25 9.82107 9.92893 11.5 12 11.5C14.0711 11.5 15.75 9.82107 15.75 7.75C15.75 5.67893 14.0711 4 12 4ZM9.75 7.75C9.75 6.50736 10.7574 5.5 12 5.5C13.2426 5.5 14.25 6.50736 14.25 7.75C14.25 8.99264 13.2426 10 12 10C10.7574 10 9.75 8.99264 9.75 7.75Z" fill="#00BCC1"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9 13C6.92893 13 5.25 14.6789 5.25 16.75C5.25 18.8211 6.92893 20.5 9 20.5H15C17.0711 20.5 18.75 18.8211 18.75 16.75C18.75 14.6789 17.0711 13 15 13H9ZM6.75 16.75C6.75 15.5074 7.75736 14.5 9 14.5H15C16.2426 14.5 17.25 15.5074 17.25 16.75C17.25 17.9926 16.2426 19 15 19H9C7.75736 19 6.75 17.9926 6.75 16.75Z" fill="#00BCC1"/>
<path d="M7.75214 10.3887C7.59441 10.1353 7.29846 10 7 10C5.75736 10 4.75 8.99264 4.75 7.75C4.75 6.50736 5.75736 5.5 7 5.5C7.29846 5.5 7.59441 5.36473 7.75214 5.11135C7.75912 5.10014 7.76613 5.08896 7.7732 5.07782C8.0358 4.66331 7.90275 4.0764 7.415 4.0227C7.27873 4.0077 7.14027 4 7 4C4.92893 4 3.25 5.67893 3.25 7.75C3.25 9.82107 4.92893 11.5 7 11.5C7.14027 11.5 7.27873 11.4923 7.415 11.4773C7.90275 11.4236 8.0358 10.8367 7.7732 10.4222C7.76614 10.411 7.75912 10.3999 7.75214 10.3887Z" fill="#00BCC1"/>
<path d="M4.70829 18.3169C4.59477 18.1275 4.39439 18 4.17359 18H4C2.75736 18 1.75 16.9926 1.75 15.75C1.75 14.5074 2.75736 13.5 4 13.5H4.17359C4.39439 13.5 4.59477 13.3725 4.70829 13.1831C4.98539 12.7208 4.68468 12 4.14569 12H4C1.92893 12 0.25 13.6789 0.25 15.75C0.25 17.8211 1.92893 19.5 4 19.5H4.14569C4.68469 19.5 4.98539 18.7792 4.70829 18.3169Z" fill="#00BCC1"/>
<path d="M16.2268 10.4222C15.9642 10.8367 16.0973 11.4236 16.585 11.4773C16.7213 11.4923 16.8597 11.5 17 11.5C19.0711 11.5 20.75 9.82107 20.75 7.75C20.75 5.67893 19.0711 4 17 4C16.8597 4 16.7213 4.0077 16.585 4.0227C16.0973 4.0764 15.9642 4.66331 16.2268 5.07782C16.2339 5.08896 16.2409 5.10014 16.2479 5.11134C16.4056 5.36472 16.7015 5.5 17 5.5C18.2426 5.5 19.25 6.50736 19.25 7.75C19.25 8.99264 18.2426 10 17 10C16.7015 10 16.4056 10.1353 16.2479 10.3887C16.2409 10.3999 16.2339 10.411 16.2268 10.4222Z" fill="#00BCC1"/>
<path d="M19.2917 18.3169C19.0146 18.7792 19.3153 19.5 19.8543 19.5H20C22.0711 19.5 23.75 17.8211 23.75 15.75C23.75 13.6789 22.0711 12 20 12H19.8543C19.3153 12 19.0146 12.7208 19.2917 13.1831C19.4052 13.3725 19.6056 13.5 19.8264 13.5H20C21.2426 13.5 22.25 14.5074 22.25 15.75C22.25 16.9926 21.2426 18 20 18H19.8264C19.6056 18 19.4052 18.1275 19.2917 18.3169Z" fill="#00BCC1"/>
</svg>
    )
}

export function CareProviders(){
    return(
        <svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 0C9.92893 0 8.25 1.67893 8.25 3.75C8.25 5.82107 9.92893 7.5 12 7.5C14.0711 7.5 15.75 5.82107 15.75 3.75C15.75 1.67893 14.0711 0 12 0ZM9.75 3.75C9.75 2.50736 10.7574 1.5 12 1.5C13.2426 1.5 14.25 2.50736 14.25 3.75C14.25 4.99264 13.2426 6 12 6C10.7574 6 9.75 4.99264 9.75 3.75Z" fill="#FF5A4E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9 9C6.92893 9 5.25 10.6789 5.25 12.75C5.25 14.8211 6.92893 16.5 9 16.5H15C17.0711 16.5 18.75 14.8211 18.75 12.75C18.75 10.6789 17.0711 9 15 9H9ZM6.75 12.75C6.75 11.5074 7.75736 10.5 9 10.5H15C16.2426 10.5 17.25 11.5074 17.25 12.75C17.25 13.9926 16.2426 15 15 15H9C7.75736 15 6.75 13.9926 6.75 12.75Z" fill="#FF5A4E"/>
<path d="M7.75214 6.38865C7.59441 6.13527 7.29846 6 7 6C5.75736 6 4.75 4.99264 4.75 3.75C4.75 2.50736 5.75736 1.5 7 1.5C7.29846 1.5 7.59441 1.36473 7.75214 1.11135C7.75912 1.10014 7.76613 1.08896 7.7732 1.07782C8.0358 0.663308 7.90275 0.0764006 7.415 0.0227032C7.27873 0.00770099 7.14027 0 7 0C4.92893 0 3.25 1.67893 3.25 3.75C3.25 5.82107 4.92893 7.5 7 7.5C7.14027 7.5 7.27873 7.4923 7.415 7.4773C7.90275 7.4236 8.0358 6.83669 7.7732 6.42218C7.76614 6.41104 7.75912 6.39986 7.75214 6.38865Z" fill="#FF5A4E"/>
<path d="M4.70829 14.3169C4.59477 14.1275 4.39439 14 4.17359 14H4C2.75736 14 1.75 12.9926 1.75 11.75C1.75 10.5074 2.75736 9.5 4 9.5H4.17359C4.39439 9.5 4.59477 9.37252 4.70829 9.18313C4.98539 8.72082 4.68468 8 4.14569 8H4C1.92893 8 0.25 9.67893 0.25 11.75C0.25 13.8211 1.92893 15.5 4 15.5H4.14569C4.68469 15.5 4.98539 14.7792 4.70829 14.3169Z" fill="#FF5A4E"/>
<path d="M16.2268 6.42218C15.9642 6.83669 16.0973 7.4236 16.585 7.4773C16.7213 7.4923 16.8597 7.5 17 7.5C19.0711 7.5 20.75 5.82107 20.75 3.75C20.75 1.67893 19.0711 0 17 0C16.8597 0 16.7213 0.00770104 16.585 0.0227034C16.0973 0.0764008 15.9642 0.663307 16.2268 1.07782C16.2339 1.08896 16.2409 1.10014 16.2479 1.11134C16.4056 1.36472 16.7015 1.5 17 1.5C18.2426 1.5 19.25 2.50736 19.25 3.75C19.25 4.99264 18.2426 6 17 6C16.7015 6 16.4056 6.13527 16.2479 6.38865C16.2409 6.39986 16.2339 6.41104 16.2268 6.42218Z" fill="#FF5A4E"/>
<path d="M19.2917 14.3169C19.0146 14.7792 19.3153 15.5 19.8543 15.5H20C22.0711 15.5 23.75 13.8211 23.75 11.75C23.75 9.67893 22.0711 8 20 8H19.8543C19.3153 8 19.0146 8.72082 19.2917 9.18313C19.4052 9.37252 19.6056 9.5 19.8264 9.5H20C21.2426 9.5 22.25 10.5074 22.25 11.75C22.25 12.9926 21.2426 14 20 14H19.8264C19.6056 14 19.4052 14.1275 19.2917 14.3169Z" fill="#FF5A4E"/>
</svg>

    )
}

export function Finance(){
    return(
        <svg width="25" height="14" viewBox="0 0 25 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.287598" y="-5" width="24" height="24" fill="#7367F0"/>
</svg>
    )
}

export function Star(){
    return(
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.7299 2.51001L14.4899 6.03001C14.7299 6.52002 15.3699 6.99001 15.9099 7.08001L19.0999 7.61001C21.1399 7.95001 21.6199 9.43001 20.1499 10.89L17.6699 13.37C17.2499 13.79 17.0199 14.6 17.1499 15.18L17.8599 18.25C18.4199 20.68 17.1299 21.62 14.9799 20.35L11.9899 18.58C11.4499 18.26 10.5599 18.26 10.0099 18.58L7.01991 20.35C4.87991 21.62 3.57991 20.67 4.13991 18.25L4.84991 15.18C4.97991 14.6 4.74991 13.79 4.32991 13.37L1.84991 10.89C0.389909 9.43001 0.859909 7.95001 2.89991 7.61001L6.08991 7.08001C6.61991 6.99001 7.25991 6.52002 7.49991 6.03001L9.25991 2.51001C10.2199 0.600015 11.7799 0.600015 12.7299 2.51001Z" stroke="#FFB547" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    )
}

export function BlueStar(){
    return(
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.7299 2.51001L14.4899 6.03001C14.7299 6.52002 15.3699 6.99001 15.9099 7.08001L19.0999 7.61001C21.1399 7.95001 21.6199 9.43001 20.1499 10.89L17.6699 13.37C17.2499 13.79 17.0199 14.6 17.1499 15.18L17.8599 18.25C18.4199 20.68 17.1299 21.62 14.9799 20.35L11.9899 18.58C11.4499 18.26 10.5599 18.26 10.0099 18.58L7.01991 20.35C4.87991 21.62 3.57991 20.67 4.13991 18.25L4.84991 15.18C4.97991 14.6 4.74991 13.79 4.32991 13.37L1.84991 10.89C0.389909 9.43001 0.859909 7.95001 2.89991 7.61001L6.08991 7.08001C6.61991 6.99001 7.25991 6.52002 7.49991 6.03001L9.25991 2.51001C10.2199 0.600015 11.7799 0.600015 12.7299 2.51001Z" stroke="#3B7AFE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
    )
}