import React from "react";
import { Modal } from "react-bootstrap";
import { Assets } from "./images";

const NotificationModalOne = (props) => {
  return (
    <div>
    <Modal
      {...props}
      className="notification-modal"
      size="md"
      aria-labelledby="contained-modal-title-center"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="header d-flex w-100 align-items-center justify-content-between ">
            <h6 className="p-0 m-0 modal-heading">
              Notifications <span>(2)</span>
            </h6>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="notification-container">
          <div className="notification-card mb-2 border-bottom pb-2">
            <div
              className={`notification-box  d-flex align-items-center p-3`}
            >
              <div>
                <img src={Assets.AvatarNotifTwo} alt="Avatar" />
              </div>
              <div className="noti-content-time-div ms-3 d-flex justify-content-between w-100">
                <div className="notif_para">
                  <b>Donec placerat</b> nunc a metus eleifend tincidunt.
                  Mauris sagittis rhoncus ligula,
                </div>

                <small className="time-small">23m ago</small>
              </div>
            </div>
            <div
              className={`notification-box  d-flex align-items-center p-3`}
            >
              <div>
                <img src={Assets.AvatarNotifTwo} alt="Avatar" />
              </div>
              <div className="noti-content-time-div ms-3 d-flex justify-content-between w-100">
                <div className="notif_para">
                  <b>Donec placerat</b> nunc a metus eleifend tincidunt.
                  Mauris sagittis rhoncus ligula,
                </div>

                <small className="time-small">23m ago</small>
              </div>
            </div>
            <div
              className={`notification-box  d-flex align-items-center p-3`}
            >
              <div>
                <img src={Assets.AvatarNotifTwoe} alt="Avatar" />
              </div>
              <div className="noti-content-time-div ms-3 d-flex justify-content-between w-100">
                <div className="notif_para">
                  <b>Donec placerat</b> nunc a metus eleifend tincidunt.
                  Mauris sagittis rhoncus ligula,
                </div>

                <small className="time-small">23m ago</small>
              </div>
            </div>
            <div
              className={`notification-box  d-flex align-items-center p-3`}
            >
              <div>
                <img src={Assets.AvatarNotifTwo} alt="Avatar" />
              </div>
              <div className="noti-content-time-div ms-3 d-flex justify-content-between w-100">
                <div className="notif_para">
                  <b>Donec placerat</b> nunc a metus eleifend tincidunt.
                  Mauris sagittis rhoncus ligula,
                </div>

                <small className="time-small">23m ago</small>
              </div>
            </div>
            <div
              className={`notification-box  d-flex align-items-center p-3`}
            >
              <div>
                <img src={Assets.AvatarNotifTwo} alt="Avatar" />
              </div>
              <div className="noti-content-time-div ms-3 d-flex justify-content-between w-100">
                <div className="notif_para">
                  <b>Donec placerat</b> nunc a metus eleifend tincidunt.
                  Mauris sagittis rhoncus ligula,
                </div>

                <small className="time-small">23m ago</small>
              </div>
            </div>
            <div
              className={`notification-box  d-flex align-items-center p-3`}
            >
              <div>
                <img src={Assets.AvatarNotifTwo} alt="Avatar" />
              </div>
              <div className="noti-content-time-div ms-3 d-flex justify-content-between w-100">
                <div className="notif_para">
                  <b>Donec placerat</b> nunc a metus eleifend tincidunt.
                  Mauris sagittis rhoncus ligula,
                </div>

                <small className="time-small">23m ago</small>
              </div>
            </div>
            <div
              className={`notification-box  d-flex align-items-center p-3`}
            >
              <div>
                <img src={Assets.AvatarNotifTwo} alt="Avatar" />
              </div>
              <div className="noti-content-time-div ms-3 d-flex justify-content-between w-100">
                <div className="notif_para">
                  <b>Donec placerat</b> nunc a metus eleifend tincidunt.
                  Mauris sagittis rhoncus ligula,
                </div>

                <small className="time-small">23m ago</small>
              </div>
            </div>
            <div
              className={`notification-box  d-flex align-items-center p-3`}
            >
              <div>
                <img src={Assets.AvatarNotifTwo} alt="Avatar" />
              </div>
              <div className="noti-content-time-div ms-3 d-flex justify-content-between w-100">
                <div className="notif_para">
                  <b>Donec placerat</b> nunc a metus eleifend tincidunt.
                  Mauris sagittis rhoncus ligula,
                </div>

                <small className="time-small">23m ago</small>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  </div>
  )
}

export default NotificationModalOne