import React, { useState } from "react";
import {
  FaHome,
  FaBaseballBall,
  FaYoutube,
  FaAddressCard,
  FaWordpress,
  FaBackward,
} from "react-icons/fa";
import { CSSTransition } from "react-transition-group";

const Sidebar = () => {
  const [openmenu, setOpenMenu] = useState(true);
  return (
    <div>
      <div className="d-flex">
        <div className={openmenu ? "sidebar" : "sidebar-collapse"}>
          <div
            className="backarrow-container"
            onClick={() => {
              setOpenMenu(!openmenu);
            }}
          >
            <FaBackward />
          </div>
          <div className="sidebar-container">
            <ul>
              <li>
                <FaHome />
                <CSSTransition
                  in={openmenu}
                  timeout={500}
                  classNames={"fade"}
                  unmountOnExit
                >
                  <span>Dashboard</span>
                </CSSTransition>
              </li>
              <li>
                <FaBaseballBall />{" "}
                <CSSTransition
                  in={openmenu}
                  timeout={500}
                  classNames={"fade"}
                  unmountOnExit
                >
                  <span>Order Management</span>
                </CSSTransition>
              </li>
              <li>
                <FaYoutube />{" "}
                <CSSTransition
                  in={openmenu}
                  timeout={500}
                  classNames={"fade"}
                  unmountOnExit
                >
                  <span>Category Management</span>
                </CSSTransition>
              </li>
              <li>
                <FaAddressCard />{" "}
                <CSSTransition
                  in={openmenu}
                  timeout={500}
                  classNames={"fade"}
                  unmountOnExit
                >
                  <span>Settings</span>
                </CSSTransition>
              </li>
              <li>
                <FaWordpress />{" "}
                <CSSTransition
                  in={openmenu}
                  timeout={500}
                  classNames={"fade"}
                  unmountOnExit
                >
                  <span>Logout</span>
                </CSSTransition>
              </li>
            </ul>
          </div>
        </div>
        <div className="right-section">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Veniam odit
          eveniet temporibus praesentium consequatur nostrum labore ea placeat
          pariatur tenetur rerum mollitia quos omnis magnam eos, qui at
          voluptatem asperiores.Lorem Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Dolore excepturi magnam, molestiae magni minima,
          sint eos quas reprehenderit consequuntur, soluta in nam! Similique
          alias, pariatur omnis ipsam eaque ad repudiandae!
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
