import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Assets } from "../../constant/images";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ProductDesignTwo from "../ProductCard/ProductDesignTwo";

const BaileyComponents = () => {
  return (
    <div>
      <div className="banner-section">
        <div className="banner-title">Accessories</div>
        <div className="bailey-tabs ">
          <ul>
            <li>All</li>
            <li>Accessories</li>
            <li>Bags</li>
            <li>Dresses</li>
            <li>Kids</li>
            <li>Men</li>
            <li>Shoes</li>
            <li>T-Shirts</li>
            <li>Women</li>
          </ul>
        </div>
      </div>
      <div className="pt-5" style={{ backgroundColor: "e8e8e8" }}
>
        <Container>
          <Row>
            <Col md={3}>
              <div className="category-box">
                <div className="category-heading">Categories</div>
                <div className="category-types">Uncategorized (0)</div>
                <div className="category-types">Accessories (5)</div>
                <div className="category-types">Bags (3)</div>
                <div className="category-types">Dresses (3)</div>
                <div className="category-types">Kids (2)</div>
                <div className="category-types">Men (3)</div>
                <div className="category-types">Shoes (2)</div>
                <div className="category-types">T-shirt (2)</div>
                <div className="category-types">Women (2)</div>
              </div>
              <div className="filter-box">
                <div className="filter-heading">Filter by</div>
                <Row>
                  <Col md={6}>
                    <div className="filter-type">L (1)</div>
                  </Col>
                  <Col md={6}>
                    <div className="filter-type">S (1)</div>
                  </Col>
                  <Col md={6}>
                    <div className="filter-type">M (1)</div>
                  </Col>
                  <Col md={6}>
                    <div className="filter-type">XL (1)</div>
                  </Col>
                </Row>
              </div>
              <div className="color-box">
                <div className="color-heading">Color</div>
                <div className="mb-2">
                  <Row>
                    <Col md={4}>
                      <div className="d-flex align-items-center">
                        <div
                          className="color-circle"
                          style={{ backgroundColor: "black" }}
                        ></div>
                        <div className="color-name">Black</div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="d-flex align-items-center">
                        <div
                          className="color-circle"
                          style={{ backgroundColor: "brown" }}
                        ></div>
                        <div className="color-name">Brown</div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="mb-2">
                  <Row>
                    <Col md={4}>
                      <div className="d-flex align-items-center">
                        <div
                          className="color-circle"
                          style={{ backgroundColor: "gray" }}
                        ></div>
                        <div className="color-name">Gray</div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="d-flex align-items-center">
                        <div
                          className="color-circle"
                          style={{ backgroundColor: "green" }}
                        ></div>
                        <div className="color-name">Green</div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="mb-2">
                  <Row>
                    <Col md={4}>
                      <div className="d-flex align-items-center">
                        <div
                          className="color-circle"
                          style={{ backgroundColor: "red" }}
                        ></div>
                        <div className="color-name">Red</div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="d-flex align-items-center">
                        <div
                          className="color-circle"
                          style={{ backgroundColor: "yellow" }}
                        ></div>
                        <div className="color-name">Yellow</div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="mb-2"></div>
              </div>
              <div className="tags-box">
                <div className="tags-heading">Product tags</div>
                {/* <div className="tags-box">Hello</div> */}
                <ul>
                  <li>Bags</li>
                  <li>Accessories</li>
                  <li>Baily</li>
                  <li>Dresses</li>
                  <li>Men</li>
                  <li>T-Shirts</li>
                  <li>Women</li>
                </ul>
              </div>
              <div className="products-box">
                <div className="products-heading">Products</div>
                <div className="d-flex align-items-center">
                  <div className="img-box">
                    <img className="img-fluid" src={Assets.ProductThree} />
                  </div>
                  <div>
                    <div className="product-title">Fashion Cross Body Bags</div>
                    <div className="product-price">$112.00</div>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-2">
                  <div className="img-box">
                    <img className="img-fluid" src={Assets.ProductOne} />
                  </div>
                  <div>
                    <div className="product-title">Fashion Cross Body Bags</div>
                    <div className="product-price">$112.00</div>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-2">
                  <div className="img-box">
                    <img className="img-fluid" src={Assets.ProductTwo} />
                  </div>
                  <div>
                    <div className="product-title">Fashion Cross Body Bags</div>
                    <div className="product-price">$112.00</div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={9}>
              <div className="breadcrumb-box">
                <Breadcrumb>
                  <Breadcrumb.Item>Home</Breadcrumb.Item>
                  <Breadcrumb.Item >
                    Shop
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Page 1</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <ProductDesignTwo/>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default BaileyComponents;
