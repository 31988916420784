import React from "react";
import Accordion from "react-bootstrap/Accordion";

const Faq = () => {
  return (
    <>
      <section className="main-accordion">
        <h2>FAQs</h2>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Frequently asked question 1</Accordion.Header>
            <Accordion.Body>
              To begin Pairing your SkyValet, first remove the “plastic” to
              activate the Bluetooth lock. This is located within the main
              compartment of the suitcase on the backend of the Lock.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Frequently asked question 2</Accordion.Header>
            <Accordion.Body>
              To begin Pairing your SkyValet, first remove the “plastic” to
              activate the Bluetooth lock. This is located within the main
              compartment of the suitcase on the backend of the Lock.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Frequently asked question 3</Accordion.Header>
            <Accordion.Body>
              To begin Pairing your SkyValet, first remove the “plastic” to
              activate the Bluetooth lock. This is located within the main
              compartment of the suitcase on the backend of the Lock.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>Frequently asked question 4</Accordion.Header>
            <Accordion.Body>
              To begin Pairing your SkyValet, first remove the “plastic” to
              activate the Bluetooth lock. This is located within the main
              compartment of the suitcase on the backend of the Lock.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </section>
    </>
  );
};

export default Faq;
