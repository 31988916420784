import React from 'react'
import { Col,  Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { Assets } from '../../constant/images'

const DashboardSidebar = (props) => {
  return (
    <div>
        <Row>
            <Col md={2}>
                <div className='dashboard-sidebar-css'>
                        <div className='d-flex align-items-center logo-container'>
                            <img src={Assets.cuetiplogo}/>
                            <div className='cuetip-text'>Cuetip</div>
                        </div>
                        <div className="icon-container">
                            <ul>
                                    <NavLink to="/dashboard-sidebar/dashboard" className="link-active">
                                    <li>
                                        Dashboard
                                    </li>
                                    </NavLink>
                                    <NavLink to="/dashboard-sidebar/project" className="link-active">
                                    <li>
                                        Projects
                                    </li>
                                    </NavLink>
                                    <NavLink to="/dashboard-sidebar/nutrient" className="link-active">
                                    <li>
                                        Nutrients
                                    </li>
                                    </NavLink>
                                    <NavLink to="/dashboard-sidebar/customer" className="link-active">
                                    <li>
                                        Customers Sales
                                    </li>
                                    </NavLink>
                                    <NavLink to="/dashboard-sidebar/contractor" className="link-active">
                                    <li>
                                        Contractors
                                    </li>
                                    </NavLink>
                                    <NavLink to="/dashboard-sidebar/report"  className="link-active">
                                    <li>
                                        Reports
                                    </li>
                                    </NavLink>
                                    <NavLink to="/dashboard-sidebar/travel" className="link-active">
                                    <li>
                                        Travel & Entertainment
                                    </li>
                                    </NavLink>
                                    <NavLink to="/dashboard-sidebar/sales" className="link-active">
                                    <li>
                                        Sales & Marketing
                                    </li>
                                    </NavLink>
                                    <NavLink to="/dashboard-sidebar/workforce" className="link-active">
                                    <li>
                                        Workforce
                                    </li>
                                    </NavLink>
                                    <NavLink to="/dashboard-sidebar/capex" className="link-active">
                                    <li>
                                        CapEx
                                    </li>
                                    </NavLink>
                                    <NavLink to="/dashboard-sidebar/fx" className="link-active">
                                    <li>
                                        FX
                                    </li>
                                    </NavLink>
                                    <NavLink to="/dashboard-sidebar/break" className="link-active">
                                    <li>
                                        Break Even Analysis
                                    </li>
                                    </NavLink>
                                    <NavLink to="/dashboard-sidebar/utilities" className="link-active">
                                    <li>
                                        Utilities
                                    </li>
                                    </NavLink>
                            </ul>
                        </div>
                </div>
            </Col>
            <Col md={10}>
                {props.children}
            </Col>
        </Row>
    </div>
  )
}
export default DashboardSidebar