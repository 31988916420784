import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import SideMenu from "./SideMenu";
import SettingMenu from "./SettingSideMenu";



import Nav from "react-bootstrap/Nav";

import Form from "react-bootstrap/Form";
const PrivacyPage = () => {
  return (
    <>
            <div className="mainpadding">
          <section className="main-privacy-section margin-bottom-150">
            <Container fluid>
              <Row>
                <Col
                  xs={12}
                  md={3}
                  sm={12}
                  xl={3}
                  xxl={2}
                  className="mb-xl-auto sidebar-class-col"
                >
                  <SideMenu />
                </Col>

                <Col className="setting-col" sm={12} md={4} xl={4} xxl={3}>
                  <SettingMenu />
                </Col>

                <Col className="privacy-col" sm={12} md={6} xl={7} xxl={7}>
                  <section className="privacy-section">
                    <h6>Privacy Policy</h6>
                    <div className="privacy-content">
                      <p>
                        This is dummy copy. It is not meant to be read. It has
                        been placed here solely to demonstrate the look and feel
                        of finished, typeset text. Only for show. He who
                        searches for meaning here will be sorely disappointed.
                        These words are here to provide the reader with a basic
                        impression of how actual text will appear in its final
                        presentation.
                      </p>

                      <p>
                        This is dummy copy. It is not meant to be read. It has
                        been placed here solely to demonstrate the look and feel
                        of finished, typeset text. Only for show. He who
                        searches for meaning here will be sorely disappointed.
                        These words are here to provide the reader with a basic
                        impression of how actual text will appear in its final
                        presentation. This is dummy copy. It is not meant to be
                        read. It has been placed here solely to demonstrate the
                        look and feel of finished, typeset text. Only for show.
                        He who searches for meaning here will be sorely
                        disappointed. These words are here to provide the reader
                        with a basic impression of how actual text will appear
                        in its final presentation.
                      </p>

                      <p>
                        This is dummy copy. It is not meant to be read. It has
                        been placed here solely to demonstrate the look and feel
                        of finished, typeset text. Only for show. He who
                        searches for meaning here will be sorely disappointed.
                        These words are here to provide the reader with a basic
                        impression of how actual text will appear in its final
                        presentation. This is dummy copy. It is not meant to be
                        read. It has been placed here solely to demonstrate the
                        look and feel of finished, typeset text. Only for show.
                        He who searches for meaning here will be sorely
                        disappointed. These words are here to provide the reader
                        with a basic impression of how actual text will appear
                        in its final presentation. This is dummy copy. It is not
                        meant to be read. It has been placed here solely to
                        demonstrate the look and feel of finished, typeset text.
                        Only for show. He who searches for meaning here will be
                        sorely disappointed. These words are here to provide the
                        reader with a basic impression of how actual text will
                        appear in its final presentation.
                      </p>

                      <p>
                        This is dummy copy. It is not meant to be read. It has
                        been placed here solely to demonstrate the look and feel
                        of finished, typeset text. Only for show. He who
                        searches for meaning here will be sorely disappointed.
                      </p>
                    </div>
                  </section>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
    </>
  )
}

export default PrivacyPage