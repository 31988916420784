import React, { useState } from "react";
import {
  FaFacebookF,
  FaGithub,
  FaPlusCircle,
  FaSkype,
  FaTwitter,
} from "react-icons/fa";
import { Assets } from "../../constant/images";

const OurTeamStyleOne = () => {
  const [teamMembers, setOurTeamMembers] = useState([]);
  return (
    <>
    <div className="our-team-section">
      <section id="team" class="pb-5">
        <div class="container">
          <h5 class="section-title h1">OUR TEAM</h5>
          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-4">
              <div class="image-flip">
                <div class="mainflip flip-0">
                  <div class="frontside">
                    <div class="card">
                      <div class="card-body text-center">
                        <p className="team-img-div">
                          <img
                            class=" img-fluid"
                            src={Assets.OurTeamOne}
                            alt="card image"
                          />
                        </p>
                        <h4 class="card-title">Sunlimetech</h4>
                        <p class="card-text">
                          This is basic card with image on top, title,
                          description and button.
                        </p>
                        {/* <a
                          href="https://www.fiverr.com/share/qb8D02"
                          class="btn btn-primary btn-sm"
                        >
                          
                      
                        </a> */}
                            <FaPlusCircle />
                      </div>
                    </div>
                  </div>
                  <div class="backside">
                    <div class="card">
                      <div class="card-body text-center mt-4">
                        <h4 class="card-title">Sunlimetech</h4>
                        <p class="card-text">
                          This is basic card with image on top, title,
                          description and button.This is basic card with image
                          on top, title, description and button.This is basic
                          card with image on top, title, description and button.
                        </p>
                        <ul class="list-inline">
                          <li class="list-inline-item">
                            <a
                              class="social-icon text-xs-center"
                              target="_blank"
                              href="https://www.fiverr.com/share/qb8D02"
                            >
                              {/* <i class="fa fa-facebook"></i> */}
                              <FaFacebookF />
                            </a>
                          </li>
                          <li class="list-inline-item">
                            <a
                              class="social-icon text-xs-center"
                              target="_blank"
                              href="https://www.fiverr.com/share/qb8D02"
                            >
                              {/* <i class="fa fa-twitter"></i> */}
                              <FaTwitter />
                            </a>
                          </li>
                          <li class="list-inline-item">
                            <a
                              class="social-icon text-xs-center"
                              target="_blank"
                              href="https://www.fiverr.com/share/qb8D02"
                            >
                              {/* <i class="fa fa-skype"></i> */}
                              <FaGithub />
                            </a>
                          </li>
                          <li class="list-inline-item">
                            <a
                              class="social-icon text-xs-center"
                              target="_blank"
                              href="https://www.fiverr.com/share/qb8D02"
                            >
                              {/* <i class="fa fa-google"></i> */}
                              <FaSkype />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4">
              <div class="image-flip">
                <div class="mainflip flip-0">
                  <div class="frontside">
                    <div class="card">
                      <div class="card-body text-center">
                        <p className="team-img-div">
                          <img
                            class=" img-fluid"
                            src={Assets.OurTeamOne}
                            alt="card image"
                          />
                        </p>
                        <h4 class="card-title">Sunlimetech</h4>
                        <p class="card-text">
                          This is basic card with image on top, title,
                          description and button.
                        </p>
                        {/* <a
                          href="https://www.fiverr.com/share/qb8D02"
                          class="btn btn-primary btn-sm"
                        >
                          
                          
                        </a> */}
                        <FaPlusCircle />
                      </div>
                    </div>
                  </div>
                  <div class="backside">
                    <div class="card">
                      <div class="card-body text-center mt-4">
                        <h4 class="card-title">Sunlimetech</h4>
                        <p class="card-text">
                          This is basic card with image on top, title,
                          description and button.This is basic card with image
                          on top, title, description and button.This is basic
                          card with image on top, title, description and button.
                        </p>
                        <ul class="list-inline">
                          <li class="list-inline-item">
                            <a
                              class="social-icon text-xs-center"
                              target="_blank"
                              href="https://www.fiverr.com/share/qb8D02"
                            >
                              {/* <i class="fa fa-facebook"></i> */}
                              <FaFacebookF />
                            </a>
                          </li>
                          <li class="list-inline-item">
                            <a
                              class="social-icon text-xs-center"
                              target="_blank"
                              href="https://www.fiverr.com/share/qb8D02"
                            >
                              {/* <i class="fa fa-twitter"></i> */}
                              <FaTwitter />
                            </a>
                          </li>
                          <li class="list-inline-item">
                            <a
                              class="social-icon text-xs-center"
                              target="_blank"
                              href="https://www.fiverr.com/share/qb8D02"
                            >
                              {/* <i class="fa fa-skype"></i> */}
                              <FaGithub />
                            </a>
                          </li>
                          <li class="list-inline-item">
                            <a
                              class="social-icon text-xs-center"
                              target="_blank"
                              href="https://www.fiverr.com/share/qb8D02"
                            >
                              {/* <i class="fa fa-google"></i> */}
                              <FaSkype />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4">
              <div class="image-flip">
                <div class="mainflip flip-0">
                  <div class="frontside">
                    <div class="card">
                      <div class="card-body text-center">
                        <p className="team-img-div">
                          <img
                            class=" img-fluid"
                            src={Assets.OurTeamOne}
                            alt="card image"
                          />
                        </p>
                        <h4 class="card-title">Sunlimetech</h4>
                        <p class="card-text">
                          This is basic card with image on top, title,
                          description and button.
                        </p>
                        {/* <a
                          href="https://www.fiverr.com/share/qb8D02"
                          class="btn btn-primary btn-sm"
                        >
                          
                          
                        </a> */}
                        <FaPlusCircle />
                      </div>
                    </div>
                  </div>
                  <div class="backside">
                    <div class="card">
                      <div class="card-body text-center mt-4">
                        <h4 class="card-title">Sunlimetech</h4>
                        <p class="card-text">
                          This is basic card with image on top, title,
                          description and button.This is basic card with image
                          on top, title, description and button.This is basic
                          card with image on top, title, description and button.
                        </p>
                        <ul class="list-inline">
                          <li class="list-inline-item">
                            <a
                              class="social-icon text-xs-center"
                              target="_blank"
                              href="https://www.fiverr.com/share/qb8D02"
                            >
                              {/* <i class="fa fa-facebook"></i> */}
                              <FaFacebookF />
                            </a>
                          </li>
                          <li class="list-inline-item">
                            <a
                              class="social-icon text-xs-center"
                              target="_blank"
                              href="https://www.fiverr.com/share/qb8D02"
                            >
                              {/* <i class="fa fa-twitter"></i> */}
                              <FaTwitter />
                            </a>
                          </li>
                          <li class="list-inline-item">
                            <a
                              class="social-icon text-xs-center"
                              target="_blank"
                              href="https://www.fiverr.com/share/qb8D02"
                            >
                              {/* <i class="fa fa-skype"></i> */}
                              <FaGithub />
                            </a>
                          </li>
                          <li class="list-inline-item">
                            <a
                              class="social-icon text-xs-center"
                              target="_blank"
                              href="https://www.fiverr.com/share/qb8D02"
                            >
                              {/* <i class="fa fa-google"></i> */}
                              <FaSkype />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
    </>
  );
};

export default OurTeamStyleOne;
