import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Button } from 'react-bootstrap';
import { Assets } from '../../constant/images';

const BannerFirst = () => {
    return (
        <>
            <section className="section firstBannerSection">
                <Carousel className="bannerFirst" fade pause={true}>
                    <Carousel.Item className="bg-PaleBrown">
                        <img
                            className="d-block w-100 h-100"
                            src={Assets.bannerFirst2}
                            alt="Second slide"
                        />
                        <Carousel.Caption className='bannerCaption bannerCaption_firstBanner'>
                            <small className='tagline'>Last Summer</small>
                            <span>20% </span>
                            <h1 className='title'>all items of this season</h1>
                            <p className='para'>Get decorated with our clothes, Wear better, look better, Wear to be confident</p>
                            <Button href="/" variant="primary" size="lg">
                                Shop Now
                            </Button>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item className="bgPink">
                        <img
                            className="d-block w-100 h-100"
                            src={Assets.bannerFirst1}
                            alt="First slide"
                        />
                        <Carousel.Caption className='bannerCaption bannerCaption_firstBanner'>
                            <small>Last Summer</small>
                            <span>20% </span>
                            <h1>all items of this season</h1>
                            <p>Get decorated with our clothes, Wear better, look better, Wear to be confident</p>
                            <Button href="/" variant="primary" size="lg">
                                Shop Now
                            </Button>
                        </Carousel.Caption>
                    </Carousel.Item>

                    <Carousel.Item className='bg-PaleGrey'>
                        <img
                            className="d-block w-100 h-100"
                            src={Assets.bannerFirst3}
                            alt="Third slide"
                        />

                        <Carousel.Caption className='bannerCaption bannerCaption_firstBanner'>
                            <small>Last Summer</small>
                            <span>20% </span>
                            <h1>all items of this season</h1>
                            <p>Get decorated with our clothes, Wear better, look better, Wear to be confident</p>
                            <Button href="/" variant="primary" size="lg">
                                Shop Now
                            </Button>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </section>
        </>
    )
}

export default BannerFirst