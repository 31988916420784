import React from "react";
import Button from "react-bootstrap/Button";
import { NavLink, Link} from 'react-router-dom';
import Modal from "react-bootstrap/Modal";
import { Col, Container, Row } from "react-bootstrap";

const LogoutAlert = (props) => {
  return (
    <>
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >

      <section className="logout-popup">
        <Container>
          <Row>
            <Col>
              <h1>Log Out Alert</h1>
              <hr className="bottom-border" />
              <p>Are you logging out?</p>
              <div className="btn-div">
                <Link to="/signin" className="yes-btn">Yes</Link>
                <Button onClick={props.onHide} className="no-btn">No</Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Modal>
  </>
  )
}

export default LogoutAlert