import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { Assets } from '../../constant/images';
import Carousel from 'react-bootstrap/Carousel';
import { Button } from 'react-bootstrap';

const BannerSecond = () => {
    return (
        <section className="section second-banner-section">
            <div className="container">
                <Row>
                    <Col lg={8}>
                        <Carousel className="bannerSecond h-100" fade pause={true}>
                            <Carousel.Item className='h-100'>
                                <img
                                    src={Assets.bannerSecond1}
                                    alt="Second slide"
                                    className='w-100 h-100'
                                />
                                <Carousel.Caption className='bannerCaption bannerCaption_secondBanner'>
                                    <small className='red-color'>Exclusive Offer -20% Off This Week </small>
                                    <h1 className='title'>Samsung Gear<br /> Sale 70% Off </h1>
                                    <p className='price'>Starting at <span className='red-color'>$560.99</span></p>
                                    <Button href="/" variant="primary" size="lg">
                                        Shop Now
                                    </Button>
                                </Carousel.Caption>
                            </Carousel.Item>
                            {/* <Carousel.Item className='h-100'>
                                <img
                                    className="d-block w-100 h-100"
                                    src={Assets.bannerSecond2}
                                    alt="Second slide"
                                />
                                <Carousel.Caption className='bannerCaption bannerCaption_secondBanner'>
                                    <small className='red-color'>Exclusive Offer -20% Off This Week </small>
                                    <h1 className='title'>Samsung<br /> S7/S7 edge 2018</h1>
                                    <p className='price'>Starting at <span className='red-color'>$560.99</span></p>
                                    <Button href="/" variant="primary" size="lg">
                                        Shop Now
                                    </Button>
                                </Carousel.Caption>
                            </Carousel.Item> */}
                        </Carousel>
                    </Col>
                    <Col lg={4}>
                        <div className="bannerCard-wrapper">
                            <div className="banner-card__first">
                                <img src={Assets.bannerCard1} alt="banner-card" />
                            </div>
                            <div className="banner-card__second">
                                <img src={Assets.bannerCard2} alt="deal card" />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </section >
    )
}

export default BannerSecond