import React, {useState} from "react";
import NotificationModalOne from "./NotificationModalOne";
import { NotificationIcon } from "./svg";
const NotificationLayout = () => {
  const [shownotif, setShowNotif] = useState(false);
  return (
    <>
      <div
        onClick={() => setShowNotif(true)}
        className="icons-bg cursor-variable"
      >
        <NotificationIcon />
      </div>
      <NotificationModalOne
        show={shownotif}
        onHide={() => setShowNotif(false)}
      />
    </>
  );
};

export default NotificationLayout;
