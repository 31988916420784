import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const Home = () => {
  return (
    <div className="mt_50">
      <Container>
        <Row>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/signup">
              <div className="navigation-tabs">
                <div className="main-heading">Sign Up Form</div>
                <div className="desc">Signup form with validations</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/signin">
              <div className="navigation-tabs">
                <div className="main-heading">Sign In Form</div>
                <div className="desc">Signin form with validations</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/forget-password">
              <div className="navigation-tabs">
                <div className="main-heading">Forgot Password</div>
                <div className="desc">
                  Forgot password form with validations
                </div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/create-new-password">
              <div className="navigation-tabs">
                <div className="main-heading">New Password</div>
                <div className="desc">Create new password with validations</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/otp-verify">
              <div className="navigation-tabs">
                <div className="main-heading">OTP Verification</div>
                <div className="desc">Otp form with validations</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/product-design">
              <div className="navigation-tabs">
                <div className="main-heading">Product Design One</div>
                <div className="desc">First example of product card</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/product-design-2">
              <div className="navigation-tabs">
                <div className="main-heading">Product Design Two</div>
                <div className="desc">Second example of product card</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/typewrite-banner">
              <div className="navigation-tabs">
                <div className="main-heading">Typewritter Banner</div>
                <div className="desc">Banner with typewriting heading</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/services-cards">
              <div className="navigation-tabs">
                <div className="main-heading">Services Card</div>
                <div className="desc">Services Cards</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/accordion">
              <div className="navigation-tabs">
                <div className="main-heading">Accordion</div>
                <div className="desc">Example of accordion with image</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/search-bar">
              <div className="navigation-tabs">
                <div className="main-heading">SearchBar</div>
                <div className="desc">Search bar of Rum</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/tiles">
              <div className="navigation-tabs">
                <div className="main-heading">Tiles 1</div>
                <div className="desc">One example of tiles like wtt</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/tiles2">
              <div className="navigation-tabs">
                <div className="main-heading">Tiles 2</div>
                <div className="desc">Second example of tiles like EzFi</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/table">
              <div className="navigation-tabs">
                <div className="main-heading">Table</div>
                <div className="desc">Table component used in afrotierre</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/chart">
              <div className="navigation-tabs">
                <div className="main-heading">Chart</div>
                <div className="desc">Chart used in afrotierre and ezfi</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/line">
              <div className="navigation-tabs">
                <div className="main-heading">Line Graph</div>
                <div className="desc">
                  Line Graph used in afrotierre and ezfi
                </div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/twoline">
              <div className="navigation-tabs">
                <div className="main-heading">2 Data Line Graph</div>
                <div className="desc">2 Data Line Graph used in ezfi</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/sidebar-new">
              <div className="navigation-tabs">
                <div className="main-heading">Animated Sidebar</div>
                <div className="desc">Collapsable animated sidebar</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/bailey-page">
              <div className="navigation-tabs">
                <div className="main-heading">Bailey Page</div>
                <div className="desc">Ecommerce page template</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/react-counter">
              <div className="navigation-tabs">
                <div className="main-heading">Counter Banner</div>
                <div className="desc">Demo sites banner with counter</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/react-counter-cards">
              <div className="navigation-tabs">
                <div className="main-heading">Counter Cards</div>
                <div className="desc">Cards with counter</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/scrollable-tabs">
              <div className="navigation-tabs">
                <div className="main-heading">Scrollable Tabs</div>
                <div className="desc">Scrollable Tabs</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/first-banner">
              <div className="navigation-tabs">
                <div className="main-heading">First Banner</div>
                <div className="desc">First Banner</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/second-banner">
              <div className="navigation-tabs">
                <div className="main-heading">Second Banner</div>
                <div className="desc">Second Banner</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/hover-products">
              <div className="navigation-tabs">
                <div className="main-heading">Hover Products</div>
                <div className="desc">Hover Products</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/product-size-chart">
              <div className="navigation-tabs">
                <div className="main-heading">Size Chart</div>
                <div className="desc">Product Size Chart</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/dashboard-popup">
              <div className="navigation-tabs">
                <div className="main-heading">Multi Step Popup</div>
                <div className="desc">Multi Step Popup</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/testimonial">
              <div className="navigation-tabs">
                <div className="main-heading">Testimonial</div>
                <div className="desc">Testimonial</div>
              </div>
            </NavLink>
          </Col>
          {/* Umair's Components */}
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/test-style-one">
              <div className="navigation-tabs">
                <div className="main-heading">Testimonial 1</div>
                <div className="desc">Testimonial 1</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/category-style-one">
              <div className="navigation-tabs">
                <div className="main-heading">Category 1</div>
                <div className="desc">Category Style 1</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/newsletter-style-one">
              <div className="navigation-tabs">
                <div className="main-heading">Newsletter</div>
                <div className="desc">Newsletter</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/product-card-style-one">
              <div className="navigation-tabs">
                <div className="main-heading">Product Card</div>
                <div className="desc">Product Card</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/our-team-style-one">
              <div className="navigation-tabs">
                <div className="main-heading">Our Team</div>
                <div className="desc">Our Team Component</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/ourteamstyletwo">
              <div className="navigation-tabs">
                <div className="main-heading">Our Team 2</div>
                <div className="desc">Our Team Second Component</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/search-style-one">
              <div className="navigation-tabs">
                <div className="main-heading">Search</div>
                <div className="desc">Search Component</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/product-card-style-two">
              <div className="navigation-tabs">
                <div className="main-heading">Product Card</div>
                <div className="desc">Product Card Style 2</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/notification-layout">
              <div className="navigation-tabs">
                <div className="main-heading">Notification Layout</div>
                <div className="desc">Notification Popup Component</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/ad-page">
              <div className="navigation-tabs">
                <div className="main-heading">Ad Page</div>
                <div className="desc">Advertisement Page</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/edit-profile-page">
              <div className="navigation-tabs">
                <div className="main-heading">Edit Profile Page</div>
                <div className="desc">Edit Profile Page</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/order-review-page">
              <div className="navigation-tabs">
                <div className="main-heading">Order Review Page</div>
                <div className="desc">Order Review Page</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/settings/ChangePasswordPage">
              <div className="navigation-tabs">
                <div className="main-heading">Change Password</div>
                <div className="desc">Change Password Page</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/settings/TermsPage">
              <div className="navigation-tabs">
                <div className="main-heading">Terms Page</div>
                <div className="desc">Terms Page</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/settings/PrivacyPage">
              <div className="navigation-tabs">
                <div className="main-heading">Privacy Page</div>
                <div className="desc">Privacy Page</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/product-detail-page">
              <div className="navigation-tabs">
                <div className="main-heading">Product Detail</div>
                <div className="desc">Product Detail Page</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/shopping-cart">
              <div className="navigation-tabs">
                <div className="main-heading">Shopping Cart</div>
                <div className="desc">Shopping Cart Page</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/payment-method-page">
              <div className="navigation-tabs">
                <div className="main-heading">Payment Method</div>
                <div className="desc">Payment Method Page</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/settings/change-password">
              <div className="navigation-tabs">
                <div className="main-heading">Change Password</div>
                <div className="desc">Change Password Page</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/footers/first-footer">
              <div className="navigation-tabs">
                <div className="main-heading">First Footer</div>
                <div className="desc">Footer Component</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/footers/second-footer">
              <div className="navigation-tabs">
                <div className="main-heading">Second Footer</div>
                <div className="desc">Footer Component</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/settings/faq">
              <div className="navigation-tabs">
                <div className="main-heading">FAQ</div>
                <div className="desc">FAQ Component</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/antdtable">
              <div className="navigation-tabs">
                <div className="main-heading">ANTD Table</div>
                <div className="desc">ANT Design Table</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/antdsubtable">
              <div className="navigation-tabs">
                <div className="main-heading">ANTD Sub Table</div>
                <div className="desc">ANT Design Sub Table</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/expand-search">
              <div className="navigation-tabs">
                <div className="main-heading">Expanded Search Bar</div>
                <div className="desc">Expanded Search used in Afrotierre and Cuetip</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/signinsyntax">
              <div className="navigation-tabs">
                <div className="main-heading">Sign In Boiler Plate</div>
                <div className="desc">Sign In template used in all dashboard websites with image on one side</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/dashboard-sidebar">
              <div className="navigation-tabs">
                <div className="main-heading">Dashboard Sidebar</div>
                <div className="desc">Dashboard sidebar templates in all sites (Afrotierre, Ezfi, Cuetip)</div>
              </div>
            </NavLink>
          </Col>
          <Col md={6} lg={4} xl={3}>
            <NavLink to="/toaster">
              <div className="navigation-tabs">
                <div className="main-heading">React Toastify</div>
                <div className="desc">React Toastify Container used to show thankyou or confirmation message</div>
              </div>
            </NavLink>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Home;
