export function UserIcon() {
    return (
      <svg
        width="4"
        height="14"
        viewBox="0 0 4 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="1.55556" cy="6.99989" r="1.55556" fill="#707581" />
        <circle cx="1.55556" cy="1.55556" r="1.55556" fill="#707581" />
        <circle cx="1.55556" cy="12.4442" r="1.55556" fill="#707581" />
      </svg>
    );
  }

  export function CrossIcon() {
    return (
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="24"
          height="23.7126"
          transform="translate(0 0.880249)"
          fill="#F3F3F3"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.0457 5.0761C19.603 4.66218 18.9104 4.66143 18.4669 5.0744L12.5 10.6299L6.53315 5.07441C6.08961 4.66144 5.39697 4.66218 4.95433 5.0761L4.89889 5.12795C4.41897 5.57674 4.4198 6.33192 4.90071 6.77968L10.768 12.2425L4.90071 17.7053C4.4198 18.1531 4.41897 18.9083 4.8989 19.3571L4.95434 19.4089C5.39697 19.8228 6.08961 19.8236 6.53315 19.4106L12.5 13.8551L18.4668 19.4106C18.9104 19.8236 19.603 19.8228 20.0457 19.4089L20.1011 19.3571C20.581 18.9083 20.5802 18.1531 20.0993 17.7053L14.232 12.2425L20.0993 6.77968C20.5802 6.33192 20.581 5.57674 20.1011 5.12794L20.0457 5.0761Z"
          fill="#5C5C5C"
        />
      </svg>
    );
  }
  
  export function ProfileRightArrow() {
    return (
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.0316 16.4673C9.73741 16.7589 9.7353 17.2338 10.0269 17.528C10.3185 17.8222 10.7933 17.8243 11.0875 17.5327L12.8823 15.7539C13.5582 15.084 14.1107 14.5364 14.5025 14.0485C14.9099 13.5412 15.2049 13.0263 15.2837 12.4082C15.3182 12.1371 15.3182 11.8629 15.2837 11.5918C15.2049 10.9737 14.9099 10.4588 14.5025 9.95146C14.1107 9.46359 13.5582 8.91605 12.8823 8.24611L11.0875 6.46731C10.7933 6.17573 10.3185 6.17784 10.0269 6.47204C9.7353 6.76624 9.73741 7.24111 10.0316 7.53269L11.7946 9.28C12.5101 9.98914 13.0009 10.4772 13.333 10.8907C13.6556 11.2924 13.7662 11.5504 13.7957 11.7815C13.8142 11.9266 13.8142 12.0734 13.7957 12.2185C13.7662 12.4496 13.6556 12.7076 13.333 13.1093C13.0009 13.5228 12.5101 14.0109 11.7946 14.72L10.0316 16.4673Z"
          fill="#707581"
        />
      </svg>
    );
  }