import React, { useState } from "react";
import { Button } from "react-bootstrap";
import DashboardPopup from "./DashboardPopup";

const PopupCallingScreen = ({border, link1, backstatus, link2, mainheading, description, modalbody}) => {
  const [modalShow, setModalShow] = useState(false);
    console.log(border,"sadsd")
  return (
    <div>
      <Button className="popup-btn" onClick={() => setModalShow(true)}>
        + Create Project
      </Button>
      <DashboardPopup modalbody={modalbody} mainheading={mainheading} description={description} link1={link1} link2={link2} backstatus={backstatus} border={border} show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
};

export default PopupCallingScreen;
