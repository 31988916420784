export const Assets = {
    Logo : require("./images/home_page/commonthreadlogo.png"),
  
    AvatarOne: require("./images/home_page/avatarone.png"),
    mainImg : require("./images/get_started/mainimg.png"),
    WatchEmoji : require("./images/reactions/eyes.png"),
    LikeEmoji : require("./images/reactions/like.png"),
    DisLikeEmoji : require("./images/reactions/dislike.png"),
    HeartEmoji : require("./images/reactions/heart.png"),
    NeutralEmoji : require("./images/reactions/neutral.png"),
    ProfileImg : require("./images/settings/avatar.png"),
    videothumbnail: require("./images/home_page/videothumbnail.png"),
    thumbnailtwo: require ("./images/home_page/thumbnailtwo.png"),
    thumbnailthree: require ("./images/home_page/thumbnailthree.png"),
    thumbnailfour: require ("./images/home_page/thumbnailfour.png"),
    thumbnailfive: require ("./images/home_page/thumbnailfive.png"),
    playcircle: require("./images/home_page/play.png"),
    read: require("./images/home_page/read.png"),
    dietone: require("./images/home_page/dietone.png"),
    diettwo: require("./images/home_page/diettwo.png"),
    dietthree: require("./images/home_page/dietthree.png"),
    groupimgone: require("./images/home_page/groupimgone.png"),
    groupimgtwo: require("./images/home_page/groupimgtwo.png"),
    groupimgthree: require("./images/home_page/groupimgthree.png"),
    groupimgfour: require("./images/home_page/groupimgfour.png"),
    memberone: require("./images/group_page/memberone.png"),
    membertwo: require("./images/group_page/membertwo.png"),
    memberthree: require("./images/group_page/memberthree.png"),
    memberfour: require("./images/group_page/memberfour.png"),
    memberfive: require("./images/group_page/memberfive.png"),
    membersix: require("./images/group_page/membersix.png"),
    memberseven: require("./images/group_page/memberseven.png"),
    membereight: require("./images/group_page/membereight.png"),
    membernine: require("./images/group_page/membernine.png"),
    memberten: require("./images/group_page/memberten.png"),
    membereleven: require("./images/group_page/membereleven.png"),
    membertwelve: require("./images/group_page/membertwelve.png"),
    playlistone: require("./images/user_profiles/seeyouagain.png"),
    playlisttwo: require("./images/user_profiles/sorry.png"),
    playlistthree: require("./images/user_profiles/uptownfunk.png"),
    playlistfour: require("./images/user_profiles/blackspace.png"),
    playlistfive: require("./images/user_profiles/sugar.png"),
    profileAvatar: require("./images/user_profiles/profileavatar.png"),
    ReferralBonusImg: require("./images/home_page/referralbonus.png"),
    ScanQr: require("./images/modals/scanqr.png"),
    UploadPic: require("./images/modals/uploadpic.png"),
    CheckUrl: require("./images/modals/check.png"),
    AvatarNotifOne: require("./images/modals/avatarone.png"),
    AvatarNotifTwo: require("./images/modals/avatartwo.png"),
    AvatarNotifThree: require("./images/modals/avatarthree.png"),
    AvatarNotifFour: require("./images/modals/avatarfour.png"),
    AvatarNotifFive: require("./images/modals/avatarfive.png"),
    SearchAvatar1: require("./images/modals/SearchAvatar1.png"),
    SearchAvatar2: require("./images/modals/SearchAvatar2.png"),
    SearchAvatar3: require("./images/modals/SearchAvatar3.png"),
    SearchAvatar4: require("./images/modals/SearchAvatar4.png"),
    SearchAvatar5: require("./images/modals/SearchAvatar5.png"),
    SplashImage: require("./images/splashscreeen/splashlogo.png"),
    SplashImagetwo: require("./images/splashscreeen/img-splash.png"),
}




