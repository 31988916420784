import React from "react";
import { Line } from "react-chartjs-2";
import moment from "moment";
import {Chart, Filler} from 'chart.js';
import { Bar } from "react-chartjs-2";
import { Container } from "react-bootstrap";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from "chart.js";

// import faker from 'faker';

const LineGraph = (props) => {

    Chart.register(Filler);

  //

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display:false,
        position: "top",
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };
  const labels = ["01", "02", "03", "04", "05", "06", "07"];

  const data = {
    labels,
    datasets: [
      {
        label: "Active",
        // data: labels.map(() =>
        //   faker.datatype.number({ min: -1000, max: 1000 })
        // ),
        borderColor: "rgba(219, 187, 81, 1)",
        backgroundColor: "rgba(219, 187, 81, 0.5)",
        lineTension: 0.3,
      },

    ],
  };

  const data2 = {
    labels: ["Jan", "Feb", "Mar",  "Apr", "May", "Jun", "Jul", "Aug","Sep","Oct","Nov","Dec"],
    datasets: [
      {
        label: "Income",
        data: [5, 10,13,14,14.5,15,16,18,19,20,21,18,19],
        fill: true,
        backgroundColor: "rgba(219, 187, 81, 0.1)",
        borderColor: "rgba(219, 187, 81, 1)",
        lineTension: 0.3,
        // Line tension handles the curve of graph

      },

    ],

  };

  


  return (
    <div className="mt-5">
      <Container>
        <Line options={options} data={data2} />
      </Container>
    </div>
  );
};

export default LineGraph;
