import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Assets } from '../../constant/images';
import { FaArrowRight } from "react-icons/fa";


const ServicesCard = () => {
  return (
    <div className='services-card-section'>
    <Container>
        <Row>
            <Col md={3}>
                <div className='card-body-style'>
                    <div><img className='img-fluid' src={Assets.ServiceOne}/></div>
                    <div className='data-container d-flex align-items-center justify-content-between'>
                        <div className="card-title">Printing Services</div>
                        <div className="arrow-container"><FaArrowRight/></div>
                    </div>
                </div>
            </Col>
            <Col md={3}>
                <div className='card-body-style'>
                    <div><img className='img-fluid' src={Assets.ServiceTwo}/></div>
                    <div className='data-container d-flex align-items-center justify-content-between'>
                        <div className="card-title">Design Services</div>
                        <div className="arrow-container"><FaArrowRight/></div>
                    </div>
                </div>
            </Col>
            <Col md={3}>
                <div className='card-body-style'>
                    <div><img className='img-fluid' src={Assets.ServiceThree}/></div>
                    <div className='data-container d-flex align-items-center justify-content-between'>
                        <div className="card-title">Digital Services</div>
                        <div className="arrow-container"><FaArrowRight/></div>
                    </div>
                </div>
            </Col>
            <Col md={3}>
                <div className='card-body-style'>
                    <div><img className='img-fluid' src={Assets.ServiceFour}/></div>
                    <div className='data-container d-flex align-items-center justify-content-between'>
                        <div className="card-title">Copywriting Services</div>
                        <div className="arrow-container"><FaArrowRight/></div>
                    </div>
                </div>
            </Col>
        </Row>
    </Container>
    </div>
  )
}

export default ServicesCard