import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import ReactStars from "react-rating-stars-component";
import { Assets } from "../../constant/images";
import { ShoppingCartIcon } from "../../constant/svgs";

const ProductDesignOne = () => {
  return (
    <div>
      <Container>
        <Row>
        <Col md={3}>
            <div className="product-card">
              <div className="product-img">
                <img className="img-fluid" src={Assets.SmartWatch} />
                <div className="badge"><span>Sold Out</span></div>
              </div>
              <div className="product-title">Classic Smart Watch</div>
              <ReactStars
                count={5}
                size={24}
                isHalf={true}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                activeColor="#DBBB51"
                color="gray"
                value={4}
                edit="false"
              />
              <div className="d-flex align-items-center mt-2">
              <div className="product-price">Rs. 3,950</div><div className="ps-3"><strike>Rs. 5,000</strike></div>
              </div>
              <div>
                <Button className="product-btn w-100"><ShoppingCartIcon/> <span>Add to Cart</span></Button>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="product-card">
              <div className="product-img">
                <img className="img-fluid" src={Assets.SmartWatch} />
                <div className="badge"><span>Sold Out</span></div>
              </div>
              <div className="product-title">Classic Smart Watch</div>
              <ReactStars
                count={5}
                size={24}
                isHalf={true}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                activeColor="#DBBB51"
                color="gray"
                value={4}
                edit="false"
              />
              <div className="d-flex align-items-center mt-2">
              <div className="product-price">Rs. 3,950</div><div className="ps-3"><strike>Rs. 5,000</strike></div>
              </div>
              <div>
                <Button className="product-btn w-100"><ShoppingCartIcon/> <span>Add to Cart</span></Button>
              </div>
            </div>
          </Col>        <Col md={3}>
            <div className="product-card">
              <div className="product-img">
                <img className="img-fluid" src={Assets.SmartWatch} />
                <div className="badge"><span>Sold Out</span></div>
              </div>
              <div className="product-title">Classic Smart Watch</div>
              <ReactStars
                count={5}
                size={24}
                isHalf={true}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                activeColor="#DBBB51"
                color="gray"
                value={4}
                edit="false"
              />
              <div className="d-flex align-items-center mt-2">
              <div className="product-price">Rs. 3,950</div><div className="ps-3"><strike>Rs. 5,000</strike></div>
              </div>
              <div>
                <Button className="product-btn w-100"><ShoppingCartIcon/> <span>Add to Cart</span></Button>
              </div>
            </div>
          </Col>        <Col md={3}>
            <div className="product-card">
              <div className="product-img">
                <img className="img-fluid" src={Assets.SmartWatch} />
                <div className="badge"><span>Sold Out</span></div>
              </div>
              <div className="product-title">Classic Smart Watch</div>
              <ReactStars
                count={5}
                size={24}
                isHalf={true}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                activeColor="#DBBB51"
                color="gray"
                value={4}
                edit="false"
              />
              <div className="d-flex align-items-center mt-2">
              <div className="product-price">Rs. 3,950</div><div className="ps-3"><strike>Rs. 5,000</strike></div>
              </div>
              <div>
                <Button className="product-btn w-100"><ShoppingCartIcon/> <span>Add to Cart</span></Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProductDesignOne;
