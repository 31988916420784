import React, { useState } from "react";
import { Container, Row, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { PopupCloseIcon } from "./svg";
import Form from "react-bootstrap/Form";
import { success } from "./msgs";

const ExitDeleteModal = (props) => {
  const navigate = useNavigate();
  const [useCT, setUseCt] = useState(false);
  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="exitmodalcss"
      >
        <section className="exit-delete-modal">
          <Container>
            <Row>
              <div className="col-12">
                <div className="exit-delete-content">
                  <h2>{props.heading}</h2>
                  <p>{props.description}</p>
                  <div className="exit-buttons-position">
                    <div>
                      <NavLink to={props.link}>
                        <Button
                          className="exit-btn mt-2 mt-sm-0"
                          onClick={() => {
                            success(
                              <>
                                <div className="toast-text">
                                  <h2>Group Left</h2>
                                  <p>Group has been left</p>
                                </div>
                              </>
                            );
                            props.onHide();
                            setTimeout(() => {
                              props.setModalGroupDetails(false);
                              navigate("/groups/join-group");
                            }, 2000);
                          }}
                        >
                          {props.orangebtn}
                        </Button>
                      </NavLink>
                    </div>
                    <div className="ms-0 ms-sm-4 ">
                      <NavLink to="#">
                        <Button
                          className="cancel-btn  mt-2 mt-sm-0 mb-3 mb-sm-0"
                          onClick={props.onHide}
                        >
                          {props.graybtn}
                        </Button>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </section>
      </Modal>
    </>
  );
};

export default ExitDeleteModal;
