import React from 'react';
// Here is the scss for thi footer:
import "./first-footer.scss"
import { Row, Col } from 'react-bootstrap';
import { Location, PhoneIcon, MailEnvelope, TekRevolLogo, FacebookLogo, TwitterLogo, InstagramLogo, TelegramPlane } from '../../../constant/svgs';
import { Assets } from '../../../constant/images';

const FooterFirst = () => {
    return (
        <div className='first-footer-css'>
                    <>
            <footer className="footer-section first-footer">
                <div className="container">
                    <div className="footer-cta pt-5 pb-5">
                        <Row>
                            <Col xl={4} md={4} className="mb-30">
                                <div className="single-cta">
                                    <div className="icon-img">
                                        <Location />
                                    </div>

                                    <div className="cta-text">
                                        <h4>Find us</h4>
                                        <a href="https://www.google.com/maps/dir/24.9404101,67.106047/tekrevol/@24.9330075,67.0902069,15z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3eb339e0065dd7ab:0x148d42c933285137!2m2!1d67.0957113!2d24.9277934"> Tekrevol, TH 7 FL 3, Karachi, 75300</a>
                                    </div>
                                </div>
                            </Col>
                            <Col xl={4} md={4} className="mb-30">
                                <div className="single-cta">
                                    <div className="icon-img">
                                        <PhoneIcon />
                                    </div>
                                    <div className="cta-text">
                                        <h4>Call us</h4>
                                        <a href="tel:8003629239">(800) 362-9239</a>
                                    </div>
                                </div>
                            </Col>
                            <Col xl={4} md={4} className="mb-30">
                                <div className="single-cta">
                                    <div className="icon-img">
                                        <MailEnvelope />
                                    </div>
                                    <div className="cta-text">
                                        <h4>Mail us</h4>
                                        <a href="mailto:info@tekrevol.com">
                                            info@tekrevol.com</a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="footer-content pt-5 pb-5">
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 mb-50 pb-4">
                                <div className="footer-widget">
                                    <div className="footer-logo">
                                        <a href="https://www.tekrevol.com/">
                                            <img
                                                src={Assets.loremIpsumLogo}
                                                alt="Second slide"
                                                className='w-100 h-100'
                                            />
                                        </a>
                                    </div>
                                    <div className="footer-text">
                                        <p>Lorem ipsum dolor sit amet, consec tetur adipisicing elit, sed do eiusmod tempor incididuntut consec tetur adipisicing
                                            elit,Lorem ipsum dolor sit amet.</p>
                                    </div>
                                    <div className="footer-social-icon">
                                        <span>Follow us</span>
                                        <div className="social-list">
                                            <a href="#" className='social-item'>
                                                <div className="social-link">
                                                    <FacebookLogo />
                                                </div>
                                            </a>
                                            <a href="#" className='social-item'>
                                                <div className="social-link">
                                                    <TwitterLogo />
                                                </div>
                                            </a>
                                            <a href="#" className='social-item'>
                                                <div className="social-link">
                                                    <InstagramLogo />
                                                </div>
                                            </a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                                <div className="footer-widget">
                                    <div className="footer-widget-heading">
                                        <h3>Useful Links</h3>
                                    </div>
                                    <ul>
                                        <li><a href="#">Home</a></li>
                                        <li><a href="#">about</a></li>
                                        <li><a href="#">services</a></li>
                                        <li><a href="#">portfolio</a></li>
                                        <li><a href="#">Contact</a></li>
                                        <li><a href="#">About us</a></li>
                                        <li><a href="#">Our Services</a></li>
                                        <li><a href="#">Expert Team</a></li>
                                        <li><a href="#">Contact us</a></li>
                                        <li><a href="#">Latest News</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                                <div className="footer-widget">
                                    <div className="footer-widget-heading">
                                        <h3>Subscribe</h3>
                                    </div>
                                    <div className="footer-text mb-25">
                                        <p>Don’t miss to subscribe to our new feeds, kindly fill the form below.</p>
                                    </div>
                                    <div className="subscribe-form">
                                        <form action="#">
                                            <input type="text" placeholder="Email Address" />
                                            <button className='subscribe-button'>
                                                <TelegramPlane />
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 text-center text-lg-left">
                                <div className="copyright-text">
                                    <p>Copyright © 2023, All Right Reserved <a href="https://www.tekrevol.com/">TekRevol</a></p>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                                <div className="footer-menu">
                                    <ul>
                                        <li><a href="#">Home</a></li>
                                        <li><a href="#">Terms</a></li>
                                        <li><a href="#">Privacy</a></li>
                                        <li><a href="#">Policy</a></li>
                                        <li><a href="#">Contact</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
        </div>
    )
}

export default FooterFirst