import React from 'react'
import { Container } from 'react-bootstrap'

const ParentContainer = (props) => {
  return (
    <>
    <div className='parent-container'>
    <Container>
        {props.children}
    </Container>
    </div>
    </>
  )
}

export default ParentContainer