import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CountUp from "react-countup";
import { Assets } from "../../constant/images";

const ReactCounter = () => {
  const data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  return (
    <div>
      <div>
        <p></p>
      </div>
      <div className="demo-banners">
        <Container>
          <div className="text-center demo-heading">
            <div className="counter">
              <CountUp start={50} end={100} />+
            </div>
            <div className="ps-2">Demo Sites</div>
          </div>
          <div className="demo-description">
            The7 comes with 60+ prebuilt dummy websites complete with exclusive
            templates for Elementor, WPBakery Page Builder & Slider Revolution.
            <br />
            Create a perfect foundation for your new website in minutes!
          </div>
          <Row>
            {data.map((item) => (
              <Col md={3}>
                <div className="post-thumbnail">
                  <img className="img-fluid" src={Assets.Demo} />
                  <div className="overlay"></div>
                  <div className="icons">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path
                        d="M16.004 9.414l-8.607 
                  8.607-1.414-1.414L14.589 8H7.004V6h11v11h-2V9.414z"
                      ></path>
                    </svg>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ReactCounter;
