import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import { Button } from "react-bootstrap";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Nav, { NavLink } from "react-bootstrap/Nav";
import { FreeMode, Navigation, Thumbs } from "swiper";


// import RealtedProducts from "../Realted Products/RealtedProducts";

import productsliderimg from "./images/productdetail-slider.png";
// import StarIcon from '../../constants/svg'


import { ArrowDownIcon, ArrowUpIcon } from "./svg";
import { useNavigate } from "react-router-dom";

const ProductDetailPage = () => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [count, setCount] = useState(1);
    const incrementCount = () => {
      setCount(count + 1);
    };
    const decrementCount = () => {
      if (count > 0) setCount(count - 1);
    };
    const navigate = useNavigate();
    const CartNavigate = () => {
      navigate('/shopping-cart')
    };
  return (
    <div className="mainpadding">
    <div className="product-detail-container">
      <section className="main-productdetails-section">
        <Container fluid>
          <Row>
            <Col xs={12} xl={6}>
              <div className="prodcut-detailcard-slider">
                <>
                  <Swiper
                    spaceBetween={10}
                    navigation={false}
                    thumbs={{
                      swiper:
                        thumbsSwiper && !thumbsSwiper.destroyed
                          ? thumbsSwiper
                          : null,
                    }}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiper2"
                  >
                    <SwiperSlide>
                      <img src={productsliderimg} className="img-fluid" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={productsliderimg} className="img-fluid" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={productsliderimg} className="img-fluid" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={productsliderimg} className="img-fluid" />
                    </SwiperSlide>
                  </Swiper>
                  <Swiper
                    onSwiper={setThumbsSwiper}
                    spaceBetween={1}
                    slidesPerView={5}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiper"
                  >
                    <SwiperSlide>
                      <img src={productsliderimg} className="img-fluid" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={productsliderimg} className="img-fluid" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={productsliderimg} className="img-fluid" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={productsliderimg} className="img-fluid" />
                    </SwiperSlide>
                  </Swiper>
                </>
              </div>
            </Col>
            <Col xs={12} xl={6}>
              <div className="product-details-section">
                <div className="top-detail-section">
                  <p className="product-detail-category">Men's shoe</p>
                  <p className="product-detail-rating">
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.43116 0.229004L10.4004 6.28962H16.7729L11.6174 10.0353L13.5866 16.0959L8.43116 12.3502L3.27569 16.0959L5.2449 10.0353L0.0894394 6.28962H6.46194L8.43116 0.229004Z"
                        fill="#FF8847"
                      />
                    </svg>
                    <span>(4.5)</span>
                  </p>
                </div>
                <h2 className="product-detail-name">Nike Air Max</h2>
                <div className="second-detail-section">
                  <h2>$125.00</h2>
                  <p>In Stock</p>
                </div>
                <hr className="bottom-border-line" />
                <p className="product-detail-para">
                  This is dummy copy. It is not meant to be read. It has
                  been placed here solely to demonstrate the look and feel
                  of finished, typeset text.
                </p>

                <Row>
                  <Col
                    md={8}
                    xxl={6}
                    className="product-detail-col-right"
                  >
                    <div className="product-select-size">
                      <InputGroup size="lg">
                        <InputGroup.Text id="inputGroup-sizing-lg">
                          Select Size
                        </InputGroup.Text>
                        <Form.Select aria-label="Default select example">
                          <option>5.5</option>
                          <option value="1">1.5</option>
                          <option value="2">2.5</option>
                          <option value="3">3</option>
                        </Form.Select>
                      </InputGroup>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col
                    md={8}
                    xxl={6}
                    className="product-detail-col-right"
                  >
                    <div className="product-select-color">
                      <p>Select Color</p>
                      <div className="radio-colors">
                        <Form.Check
                          type="radio"
                          aria-label="radio 1"
                          id="darkgrey"
                          name="color"
                        />
                        <Form.Check
                          type="radio"
                          aria-label="radio 2"
                          id="black"
                          name="color"
                        />
                        <Form.Check
                          type="radio"
                          aria-label="radio 3"
                          id="lightgrey"
                          name="color"
                        />
                        <Form.Check
                          type="radio"
                          aria-label="radio 4"
                          id="pink"
                          name="color"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col
                    md={8}
                    xxl={6}
                    className="product-detail-col-right"
                  >
                    <div className="quantity-addcard">
                      <div className="qunatity-div">
                        <div
                          className="arrow-down-icon"
                          onClick={decrementCount}
                        >
                          <ArrowDownIcon />
                        </div>

                        <Form.Control
                          type="number"
                          id="quantity"
                          value={count}
                          disabled
                        />
                        <div
                          className="arrow-up-icon"
                          onClick={incrementCount}
                        >
                          <ArrowUpIcon />
                        </div>
                      </div>
                      <Button className="w-100" onClick={CartNavigate}>
                        <svg
                          width="23"
                          height="22"
                          viewBox="0 0 23 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.5 0.801758L3.75333 1.19176L4.79658 13.6208C4.88 14.6359 5.72825 15.4148 6.74658 15.4116H18.5647C19.5364 15.4138 20.3608 14.6988 20.4984 13.7368L21.5265 6.63226C21.6413 5.83817 21.0899 5.10151 20.2969 4.98667C20.2276 4.97692 4.11517 4.97151 4.11517 4.97151"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M13.8228 8.97526H16.8268"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M6.27146 19.167C6.59755 19.167 6.8608 19.4313 6.8608 19.7563C6.8608 20.0824 6.59755 20.3467 6.27146 20.3467C5.94538 20.3467 5.68213 20.0824 5.68213 19.7563C5.68213 19.4313 5.94538 19.167 6.27146 19.167Z"
                            fill="white"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M18.4917 19.167C18.8178 19.167 19.0821 19.4313 19.0821 19.7563C19.0821 20.0824 18.8178 20.3467 18.4917 20.3467C18.1656 20.3467 17.9023 20.0824 17.9023 19.7563C17.9023 19.4313 18.1656 19.167 18.4917 19.167Z"
                            fill="white"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <span>Add to Cart</span>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  </div>
  )
}

export default ProductDetailPage