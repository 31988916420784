import React, { useState } from "react";
import { Table, Space } from "antd";
import { Container, Dropdown, DropdownButton } from "react-bootstrap";
import DeleteModal from "../Table/DeleteModal";
import EditModal from "../Table/EditModal";
import { UserIcon } from "../Table/Tablesvg";

function AntdSubTable() {
    const [deletemodalShow, setDeleteModalShow] = useState(false);
    const [editmodalShow, setEditModalShow] = useState(false);
  const data = [
    {
      key: "1",
      name: "John Doe",
      age: 32,
      address: "New York",
      children: [
        {
          key: "1-1",
          name: "John Doe Jr.",
          age: 10,
          address: "New York",
        },
        {
          key: "1-2",
          name: "Jane Doe",
          age: 12,
          address: "New York",
        },
      ],
    },
    {
      key: "2",
      name: "Mike Smith",
      age: 42,
      address: "Los Angeles",
      children: [
        {
          key: "2-1",
          name: "Mike Jr.",
          age: 15,
          address: "Los Angeles",
        },
        {
          key: "2-2",
          name: "Sophia Smith",
          age: 18,
          address: "Los Angeles",
        },
      ],
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Action",
      key: "action",
      render: () => (
        <Space size="middle">
          <div className="user-button">
            <DropdownButton id="dropdown-basic-button" title={<UserIcon />}>
              <Dropdown.Item
                // onClick={() => setShowEditNew(true)}
                onClick={() => setEditModalShow(true)}
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                // onClick={() => setShowDelete(true)}
                onClick={() => setDeleteModalShow(true)}
              >
                Delete
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </Space>
      ),
    },
  ];

  const expandableRowRender = (record, index) => {
    return (
      <Table
        columns={columns}
        dataSource={record.children}
        pagination={false}
      />
    );
  };

  return (
    <>
    <Container>
      <Table
        columns={columns}
        dataSource={data}
        expandable={{
          expandRowByClick: true,
          expandedRowRender: expandableRowRender,
        }}
      />
    </Container>
          <DeleteModal
          heading="Delete Product"
          type="delete this product"
          btntext="Delete"
          display="onClick={()=>{props.onHide}}"
          show={deletemodalShow}
          onHide={() => setDeleteModalShow(false)}
        />
  
          <EditModal
          heading="Edit Product"
          type="edit this product"
          btntext="Edit"
          display="onClick={()=>{props.onHide}}"
          show={editmodalShow}
          onHide={() => setEditModalShow(false)}
        />
        </>
  );
}

export default AntdSubTable;
