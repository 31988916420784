import React from "react";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  SPECIAL_CHARACTER_ARRAY,
  SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS,
  VALIDATIONS,
  PASSWORD_FORMAT,
  EXCLUDE_NUMBER_INPUT,
  VALIDATIONS_TEXT,
} from "../../constant/app-constant";

const ForgetPasswordForm = () => {

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const password = watch("password");
  const navigate = useNavigate();
  const onSubmit = (data) => {
    navigate("/");
  };


  return (
    <>
      <div className="main-auth-form">
        <div className="auth_from forgot-hw">
          <h1>Forgot Password</h1>
          <p>Enter email address to receive a verification code</p>
          <Form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="group_box">
              <Form.Label>Email</Form.Label>
              <div className="from_box email_icon">
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  maxLength={VALIDATIONS.EMAIL}
                  {...register("email", {
                    maxLength: {
                      value: VALIDATIONS.EMAIL,
                      message: VALIDATIONS_TEXT.EMAIL_MAX,
                    },
                    required: {
                      value: true,
                      message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                    },
                    pattern: {
                      value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                      message: VALIDATIONS_TEXT.EMAIL_FORMAT,
                    },
                  })}
                />
                {errors.email && (
                  <small className="text-red error_from">
                    {errors.email.message}
                  </small>
                )}
              </div>
            </Form.Group>

            <div>
              <Button className="mt-3" type="submit">Submit</Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ForgetPasswordForm;
