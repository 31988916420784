import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { CloseIcon } from "./svg";
import { OpenIcon } from "./svg";
import {
  SPECIAL_CHARACTER_ARRAY,
  SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS,
  VALIDATIONS,
  PASSWORD_FORMAT,
  EXCLUDE_NUMBER_INPUT,
  VALIDATIONS_TEXT,
} from "../../constant/app-constant";
import CopyUrlModal from "./CopyUrlModal";
import { success } from "./msgs";
import { ToastContainer, toast } from "react-toastify";

const ChangePassword = () => {
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [modalShowCopyUrl, setModalShowCopyUrl] = React.useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const password = watch("password");
  const navigate = useNavigate();
  const onSubmit = (data) => {
    // setModalShowCopyUrl(true)
    success(
      <>
        <div className="toast-text">
          <h2>Password Changed</h2>
          <p>Your password has been changed</p>
        </div>
      </>
    );
  };
  //  setTimeout(() => {
  //   setModalShowCopyUrl(false)
  // }, 1000);

  return (
    <>
      <section className="main-change-password">
        <div className="auth_from">
          <h1>Create New Password</h1>
          <p>Create a new password</p>
          <Form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="group_box">
              <Form.Label>Current Password</Form.Label>
              <div className="from_box pass_icon">
                <Form.Control
                  type={!showCurrentPassword ? "password" : "text"}
                  placeholder="Enter your current password"
                  maxLength={VALIDATIONS_TEXT.PASSWORD_MAX}
                  {...register("currentpassword", {
                    minLength: {
                      value: VALIDATIONS.PASSWORD_MIN,
                      message: VALIDATIONS_TEXT.PASSWORD_MIN,
                    },
                    maxLength: {
                      value: VALIDATIONS.PASSWORD_MAX,
                      message: VALIDATIONS_TEXT.PASSWORD_MAX,
                    },
                    required: {
                      value: true,
                      message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                    },
                    pattern: {
                      value: /^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/,
                      message: VALIDATIONS_TEXT.PASSWORD_FORMAT,
                    },
                  })}
                />
                {errors.currentpassword && (
                  <small className="text-red error_from error_from">
                    {errors.currentpassword.message}
                  </small>
                )}
                {/* <div className="error_from">
                Please Psssword Filed is Required
              </div> */}
              </div>
              <span className="iconfeild">
                {showCurrentPassword ? (
                  <span
                    className="toggle-password"
                    onClick={() => setShowCurrentPassword(false)}
                  >
                    <OpenIcon />
                  </span>
                ) : (
                  <span
                    onClick={() => setShowCurrentPassword(true)}
                    className="toggle-password"
                  >
                    <CloseIcon />
                  </span>
                )}
              </span>
            </Form.Group>
            <Form.Group className="group_box">
              <Form.Label>Password</Form.Label>
              <div className="from_box pass_icon">
                <Form.Control
                  type={!showNewPassword ? "password" : "text"}
                  placeholder="Enter Password"
                  maxLength={VALIDATIONS_TEXT.PASSWORD_MAX}
                  {...register("password", {
                    minLength: {
                      value: VALIDATIONS.PASSWORD_MIN,
                      message: VALIDATIONS_TEXT.PASSWORD_MIN,
                    },
                    maxLength: {
                      value: VALIDATIONS.PASSWORD_MAX,
                      message: VALIDATIONS_TEXT.PASSWORD_MAX,
                    },
                    required: {
                      value: true,
                      message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                    },
                    pattern: {
                      value: /^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/,
                      message: VALIDATIONS_TEXT.PASSWORD_FORMAT,
                    },
                  })}
                />
                {errors.password && (
                  <small className="text-red error_from error_from">
                    {errors.password.message}
                  </small>
                )}
                {/* <div className="error_from">
                Please Password Filed is Required
              </div> */}
              </div>
              <span className="iconfeild">
                {showNewPassword ? (
                  <span
                    className="toggle-password"
                    onClick={() => setShowNewPassword(false)}
                  >
                    <OpenIcon />
                  </span>
                ) : (
                  <span
                    onClick={() => setShowNewPassword(true)}
                    className="toggle-password"
                  >
                    <CloseIcon />
                  </span>
                )}
              </span>
            </Form.Group>
            <Form.Group className="group_box">
              <Form.Label>Re-enter Password</Form.Label>
              <div className="from_box pass_icon">
                <Form.Control
                  type={!showConfirmPassword ? "password" : "text"}
                  placeholder="Re-enter Password"
                  maxLength={VALIDATIONS_TEXT.PASSWORD_MAX}
                  {...register("password_confirmation", {
                    minLength: {
                      value: VALIDATIONS.PASSWORD_MIN,
                      message: VALIDATIONS_TEXT.PASSWORD_MIN,
                    },
                    maxLength: {
                      value: VALIDATIONS.PASSWORD_MAX,
                      message: VALIDATIONS_TEXT.PASSWORD_MAX,
                    },
                    required: {
                      value: true,
                      message: VALIDATIONS_TEXT.CONFIRM_PASSWORD_REQUIRED,
                    },
                    validate: (value) =>
                      value === password ||
                      "Password and Confirm Password must be same",
                  })}
                />
                {errors.password_confirmation && (
                  <small className="text-red error_from">
                    {errors.password_confirmation.message}
                  </small>
                )}
                {/* <div className="error_from">
                Please Password Filed is Required
              </div> */}
              </div>
              <span className="iconfeild">
                {showConfirmPassword ? (
                  <span
                    className="toggle-password"
                    onClick={() => setShowConfirmPassword(false)}
                  >
                    <OpenIcon />
                  </span>
                ) : (
                  <span
                    onClick={() => setShowConfirmPassword(true)}
                    className="toggle-password"
                  >
                    <CloseIcon />
                  </span>
                )}
              </span>
            </Form.Group>

            <div className="from_box btn-commom mt-46">
              <Button className="mt-3" type="submit">Change Password</Button>
            </div>
          </Form>
          <CopyUrlModal
            show={modalShowCopyUrl}
            onHide={() => setModalShowCopyUrl(false)}
            heading="Password Changed"
            subtext="Your password has been changed"
          />
        </div>
      </section>
      <ToastContainer />
    </>
  );
};

export default ChangePassword;
