import React, { useState } from 'react';
import { Button, Table } from 'antd';
import { Container, Dropdown, DropdownButton } from 'react-bootstrap';
import EditModal from '../Table/EditModal';
import DeleteModal from '../Table/DeleteModal';
import { UserIcon } from '../Table/Tablesvg';


const AntdTable = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deletemodalShow, setDeleteModalShow] = useState(false);
  const [editmodalShow, setEditModalShow] = useState(false);

  const data = [];
for (let i = 0; i < 46; i++) {
  data.push({
    key: i,
    name: `Edward King ${i}`,
    age: 32,
    address: `London, Park Lane no. ${i}`,
    action:             <div className="user-button">
    <DropdownButton id="dropdown-basic-button" title={<UserIcon />}>
      <Dropdown.Item
        // onClick={() => setShowEditNew(true)}
        onClick={() => setEditModalShow(true)}
      >
        Edit
      </Dropdown.Item>
      <Dropdown.Item
        // onClick={() => setShowDelete(true)}
        onClick={() => setDeleteModalShow(true)}
      >
        Delete
      </Dropdown.Item>
    </DropdownButton>
  </div>
  });
}

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name)

  },
  {
    title: 'Age',
    dataIndex: 'age',
    sorter: (a, b) => a.name.localeCompare(b.name)

  },
  {
    title: 'Address',
    dataIndex: 'address',
    sorter: (a, b) => a.name.localeCompare(b.name)

  },
  {
    title: 'Action',
    dataIndex: 'action',
  },
];




  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <span style={{ marginLeft: 8 }}>
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
        </span>
      </div>
      <Container>
      <Table rowSelection={rowSelection} columns={columns} dataSource={data} />
      </Container>
      <DeleteModal
        heading="Delete Product"
        type="delete this product"
        btntext="Delete"
        display="onClick={()=>{props.onHide}}"
        show={deletemodalShow}
        onHide={() => setDeleteModalShow(false)}
      />

        <EditModal
        heading="Edit Product"
        type="edit this product"
        btntext="Edit"
        display="onClick={()=>{props.onHide}}"
        show={editmodalShow}
        onHide={() => setEditModalShow(false)}
      />
    </div>
  );
};

export default AntdTable