import React from "react";
import { Container, Row, Col } from "react-bootstrap";


import {
  MasterCardSvg,
  VisaCardSvg,
  DeleteIcon,
  CartIcon,
} from "./svg";

import { FaPlus } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Stripe from "./images/stripe.png";


import OrderPlacedPopup from "./OrderPlacedPopup";
const BrandCards = () => {
  const [modalShow, setModalShow] = React.useState(false);
 
  const closedModal = ()=>{
    setModalShow(false)
  }

  return (
    <>

        <div className="mainpadding">
          <section class="main-payment-section margin-bottom-150">
            <Container fluid>
              <Row>
                <Col sm={12} md={6} lg={6} xxl={4}>
                  <div className="selectbrand-section">
                    <InputGroup>
                      <InputGroup.Text id="inputGroup-sizing-default">
                        Select Brand Card
                      </InputGroup.Text>
                      <Form.Select aria-label="Default select example">
                        <option>
                          Nike Discount Promo <span>($150)</span>
                        </option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </Form.Select>
                    </InputGroup>
                  </div>
                  <div className="shipping-address-section">
                    <h6>Shipping Address</h6>
                    <div className="address">
                      <p>John Smith</p>
                      <p>johnsmith@gmail.com</p>
                      <p>Street: 32 Sunset Boulivard,</p>
                      <p>Texes</p>
                      <p>Zip: 2356498</p>
                    </div>
                    <div className="add-new-address">
                      <NavLink>
                        <FaPlus />
                        Add New Address
                      </NavLink>
                    </div>
                  </div>
                </Col>
                <Col sm={12} md={6} lg={6} xxl={4}>
                  <div className="order-details">
                    <h6>Order Details</h6>
                    <div className="inner-order-details">
                      <div className="card-details-div">
                        <p>Brand Card:</p>
                        <p className="price">$150.00</p>
                      </div>
                      <div className="card-details-div">
                        <p>Wallet:</p>
                        <p className="price">$350.00</p>
                      </div>
                      <div className="card-details-div">
                        <p>Remaining:</p>
                        <p className="price">$250.00</p>
                      </div>
                    </div>
                  </div>
                  <div className="select-bank-card">
                    <h6>Select Bank Card</h6>
                    <div className="strip-checkmark-div">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          <img
                            src={Stripe}
                            alt="stripe"
                            className="img-fluid"
                          />
                        </label>
                      </div>
                    </div>
                    <div className="add-new-address">
                      <NavLink>
                        <FaPlus />
                        Add New Address
                      </NavLink>
                    </div>
                  </div>
                </Col>
                <Col sm={12} md={6} lg={6} xxl={4}>
                  <div className="payment-summary">
                    <h6>Payment Summary</h6>
                    <div className="inner-order-details">
                      <div className="card-details-div">
                        <p>Brand Card:</p>
                        <p className="price">$150.00</p>
                      </div>
                      <div className="card-details-div">
                        <p>Wallet:</p>
                        <p className="price">$350.00</p>
                      </div>
                      <div className="card-details-div">
                        <p>Remaining:</p>
                        <p className="price">$250.00</p>
                      </div>
                    </div>
                  </div>
                  <div className="place-order">
                    <h6>Place Order</h6>
                    <div className="total-price-div">
                      <p>
                        <span>Total Price</span> $500
                      </p>
                    </div>
                    <div className="add-new-address">
                      <Button
                        className="btn-width"
                        onClick={() => setModalShow(true)}
                      >
                        <CartIcon /> Place Order
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            <OrderPlacedPopup
              show={modalShow}
              onHide={closedModal}
              closedModal={closedModal}
            />
          </section>
        </div>

    </>
  );
};

export default BrandCards;
