export const footerData = {
    footerslinkData: [
        {
            heading: "Furniture",
            footerlinks: [
                { name: 'Delivery', links: 'javascript:' },
                { name: 'Legal Notes', link: 'javascript:' },
                { name: 'About Us', link: 'javascript:' }
            ]
        },
        {
            heading: "Outdoor",
            footerlinks: [
                { name: 'Delivery', links: 'javascript:' },
                { name: 'Legal Notes', link: 'javascript:' },
                { name: 'About Us', link: 'javascript:' }
            ]
        }
        ,
        {
            heading: "Decor & Pillows",
            footerlinks: [
                { name: 'Delivery', links: 'javascript:' },
                { name: 'Legal Notes', link: 'javascript:' },
                { name: 'About Us', link: 'javascript:' }
            ]
        },
        {
            heading: "Appliances",
            footerlinks: [
                { name: 'Delivery', links: 'javascript:' },
                { name: 'Legal Notes', link: 'javascript:' },
                { name: 'About Us', link: 'javascript:' }
            ]
        },
        {
            heading: "Bed & Bath",
            footerlinks: [
                { name: 'Delivery', links: 'javascript:' },
                { name: 'Legal Notes', link: 'javascript:' },
                { name: 'About Us', link: 'javascript:' }
            ]
        },
        {
            heading: "Baby & Kids",
            footerlinks: [
                { name: 'Delivery', links: 'javascript:' },
                { name: 'Legal Notes', link: 'javascript:' },
                { name: 'About Us', link: 'javascript:' }
            ]
        }

    ]
}
