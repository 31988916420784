import React from 'react';
import { Container } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Toaster = () => {
    const notify = () => toast("Wow so easy!");

  return (
    <div>
        <ToastContainer />
        <Container>
            <button className='toast-btn' onClick={notify}>Notify!</button>
        </Container>
    </div>
  )
}

export default Toaster