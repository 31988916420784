import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Button, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { CloseIcon } from "../../constant/svgs";
import { OpenIcon } from "../../constant/svgs";
import {
  VALIDATIONS,
  EXCLUDE_NUMBER_INPUT,
  VALIDATIONS_TEXT,
} from "../../constant/app-constant";
const SignupForm = () => {
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [checked, setChecked] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const password = watch("password");
  const navigate = useNavigate();
  const onSubmit = (data) => {
    navigate("/");
  };

  const handleChange = () => {
    setChecked(!checked);
  };

  return (
    <>
      <div className="">
        <h1>Sign Up</h1>
        <p>Fill in all the fields to create an account</p>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col xs={12} md={6} lg={6} xl={6} xxl={12}>
              <Form.Group>
                <Form.Label>Full name</Form.Label>
                <div>
                  <Form.Control
                    placeholder="Enter your full name"
                    type="text"
                    onKeyDown={(e) =>
                      EXCLUDE_NUMBER_INPUT.includes(e.key) && e.preventDefault()
                    }
                    maxLength={VALIDATIONS.NAME}
                    {...register("full_name", {
                      maxLength: {
                        value: VALIDATIONS.NAME,
                        message: VALIDATIONS_TEXT.NAME_MAX,
                      },
                      pattern: {
                        value: /^[a-zA-Z\s]*$/,
                        message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS,
                      },
                      required: {
                        value: true,
                        message: VALIDATIONS_TEXT.NAME_REQUIRED,
                      },
                    })}
                  />
                  {errors.full_name && (
                    <small className="text-red error_from">
                      {errors.full_name.message}
                    </small>
                  )}
                  {/* <div className="error_from">Please Email Filed is Required</div> */}
                </div>
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={6} xl={6} xxl={12}>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <div>
                  <Form.Control
                    type="email"
                    formnovalidate="formnovalidate"
                    placeholder="Enter your email"
                    maxLength={VALIDATIONS.EMAIL}
                    {...register("email", {
                      maxLength: {
                        value: VALIDATIONS.EMAIL,
                        message: VALIDATIONS_TEXT.EMAIL_MAX,
                      },
                      required: {
                        value: true,
                        message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                      },
                      pattern: {
                        value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                        message: VALIDATIONS_TEXT.EMAIL_FORMAT,
                      },
                    })}
                  />
                  {errors.email && (
                    <small className="text-red error_from">
                      {errors.email.message}
                    </small>
                  )}
                  {/* <div className="error_from">Please Email Filed is Required</div> */}
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6} lg={6} xl={6} xxl={12}>
              <Form.Group>
                <Form.Label>Password</Form.Label>
                <div>
                  <Form.Control
                    type={!showCurrentPassword ? "password" : "text"}
                    placeholder="Enter your password"
                    maxLength={VALIDATIONS_TEXT.PASSWORD_MAX}
                    {...register("password", {
                      minLength: {
                        value: VALIDATIONS.PASSWORD_MIN,
                        message: VALIDATIONS_TEXT.PASSWORD_MIN,
                      },
                      maxLength: {
                        value: VALIDATIONS.PASSWORD_MAX,
                        message: VALIDATIONS_TEXT.PASSWORD_MAX,
                      },
                      required: {
                        value: true,
                        message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                      },
                      pattern: {
                        value: /^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/,
                        message: VALIDATIONS_TEXT.PASSWORD_FORMAT,
                      },
                    })}
                  />
                  {errors.password && (
                    <small className="text-red error_from">
                      {errors.password.message}
                    </small>
                  )}
                  {/* <div className="error_from">
                Please Psssword Filed is Required
              </div> */}
                </div>
                <span className="iconfeild">
                  {showCurrentPassword ? (
                    <span
                      className="toggle-password"
                      onClick={() => setShowCurrentPassword(false)}
                    >
                      <OpenIcon />
                    </span>
                  ) : (
                    <span
                      onClick={() => setShowCurrentPassword(true)}
                      className="toggle-password"
                    >
                      <CloseIcon />
                    </span>
                  )}
                </span>
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={6} xl={6} xxl={12}>
              <Form.Group>
                <Form.Label>Confirm Password</Form.Label>
                <div>
                  <Form.Control
                    type={!showConfirmPassword ? "password" : "text"}
                    placeholder="Enter your password"
                    maxLength={VALIDATIONS_TEXT.PASSWORD_MAX}
                    {...register("password_confirmation", {
                      minLength: {
                        value: VALIDATIONS.PASSWORD_MIN,
                        message: VALIDATIONS_TEXT.PASSWORD_MIN,
                      },
                      maxLength: {
                        value: VALIDATIONS.PASSWORD_MAX,
                        message: VALIDATIONS_TEXT.PASSWORD_MAX,
                      },
                      required: {
                        value: true,
                        message: VALIDATIONS_TEXT.CONFIRM_PASSWORD_REQUIRED,
                      },
                      validate: (value) =>
                        value === password ||
                        "Password and Confirm Password must be same",
                    })}
                  />
                  {errors.password_confirmation && (
                    <small className="text-red error_from">
                      {errors.password_confirmation.message}
                    </small>
                  )}
                  {/* <div className="error_from">
                Please Password Filed is Required
              </div> */}
                </div>
                <span className="iconfeild">
                  {showConfirmPassword ? (
                    <span
                      className="toggle-password"
                      onClick={() => setShowConfirmPassword(false)}
                    >
                      <OpenIcon />
                    </span>
                  ) : (
                    <span
                      onClick={() => setShowConfirmPassword(true)}
                      className="toggle-password"
                    >
                      <CloseIcon />
                    </span>
                  )}
                </span>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6} lg={6} xl={6} xxl={12}>
              <div>
                <input
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  onChange={handleChange}
                />
                <label class="form-check-label" for="flexCheckDefault">
                  I have a referral code?
                </label>
              </div>
            </Col>
            <Col
              xs={12}
              md={6}
              lg={6}
              xl={6}
              xxl={12}
              className={checked ? "showw" : "hidden"}
            >
              <Form.Group>
                <Form.Label>Referral Code</Form.Label>
                <div>
                  <Form.Control
                    type="text"
                    formnovalidate="formnovalidate"
                    placeholder="Referral Code"
                    maxLength={VALIDATIONS.REF_CODE_MAX}
                    {...register("refcode", {
                      maxLength: {
                        value: VALIDATIONS.REF_CODE_MAX,
                        message: VALIDATIONS_TEXT.REF_CODE_MAX,
                      },
                      required: {
                        value: false,
                        message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                      },
                      pattern: {
                        value: /^CT-[0-9]{4}-[0-9]{2}/gm,
                        message: VALIDATIONS_TEXT.INVALID_REF_CODE_FORMAT,
                      },
                    })}
                  />
                  {errors.refcode && (
                    <small className="text-red error_from">
                      {errors.refcode.message}
                    </small>
                  )}
                </div>
              </Form.Group>
            </Col>
          </Row>
          <div>
            <Button type="submit">Register</Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default SignupForm;
