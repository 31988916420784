export const VALIDATIONS = {
    NAME: 50,
    PHONE_MIN: 10,
    PHONE_MAX: 16,
    BIO_MAX: 255,
    EMAIL: 255,
    PASSWORD_MIN: 8,
    PASSWORD_MAX: 32,
    VERIFICATION_CODE: 4,
    CARD_NUMBER: 16,
    CVV: 3,
    ADDRESS_MAX: 200,
    STATE: 50,
    REF_CODE_MAX: 10,
    ZIP_CODE: 10,
    COUNTRY_NAME: 50,
    CITY_NAME: 50,
}

export const VALIDATIONS_TEXT = {
    NAME_REQUIRED: "Full name is required",
    FIRST_NAME_REQUIRED: "First name is required",
    LAST_NAME_REQUIRED: "Last name is required",
    EMAIL_REQUIRED: "Email is required",
    ZIP_CODE_REQUIRED: "Zip Code is required",
    INVALID_ZIP_CODE: "Enter valid zip code containing numbers only",
    EMAIL_FORMAT: "Enter valid email address",
    EXCLUDE_SPECIAL_CHARACTERS: "Enter full name containing alphabets only",
    PASSWORD_FORMAT: "Enter password with one uppercase and one lowercase",
    PASSWORD_REQUIRED: "Password is required",
    CONFIRM_PASSWORD_REQUIRED: "Confirm password is required",
    PHONE_REQUIRED: "Contact number is required",
    ADDRESS_REQUIRED: "Address is required",
    STATE_REQUIRED: "State is required",
    CARD_NUMBER_REQUIRED: "Card number is required",
    INVALID_STATE_FORMAT: "Enter state containing alphabets only",
    INVALID_FORMAT_FIRST_NAME: "Enter first name containing alphabets only",
    INVALID_FORMAT_LAST_NAME: "Enter last name containing alphabets only",
    GROUP_CODE_REQUIRED: "Group Code is required",
    COUNTRY_NAME_REQUIRED: "Country name is required",
    CITY_NAME_REQUIRED: "City name is required",

    NAME_MAX: "Maximum length is " + VALIDATIONS.NAME,
    STATE_MAX: "Maximum length is " + VALIDATIONS.STATE,
    ZIP_CODE_MAX: "Maximum length is " + VALIDATIONS.ZIP_CODE,
    CITY_MAX: "Maximum length is " + VALIDATIONS.CITY_NAME,
    COUNTRY_MAX: "Maximum length is " + VALIDATIONS.COUNTRY_NAME,
    EMAIL_MAX: "Maximum length is " + VALIDATIONS.EMAIL,
    REF_CODE_MAX: "Maximum length is " + VALIDATIONS.REF_CODE_MAX,
    PASSWORD_MIN: "Minimum length is " + VALIDATIONS.PASSWORD_MIN,
    PASSWORD_MAX: "Maximum length is " + VALIDATIONS.PASSWORD_MAX,
    CARD_NUMBER_MIN: "Minimum length is " + VALIDATIONS.CARD_NUMBER,
    CARD_NUMBER_MAX: "Maximum length is " + VALIDATIONS.CARD_NUMBER,
    PHONE_MIN: "Minimum length is " + VALIDATIONS.PHONE_MIN,
    PHONE_MAX: "Maximum length is " + VALIDATIONS.PHONE_MAX,
    BIO_MAX: "Maximum length is " + VALIDATIONS.BIO_MAX,
    CVV_MIN: "Minimum length is " + VALIDATIONS.CVV,
    CVV_MAX: "Maximum length is " + VALIDATIONS.CVV,
    ADDRESS_MAX: "Maximum length is " + VALIDATIONS.ADDRESS_MAX,
    VERIFICATION_CODE: "Length is " + VALIDATIONS.VERIFICATION_CODE,
    INVALID_FORMAT: "Invalid format",
    INVALID_PHONE_FORMAT: "Enter correct number containing 11 digits",
    INVALID_REF_CODE_FORMAT: "Invalid format CT-XXXX-XX",
    


}

export const SPECIAL_CHARACTER_ARRAY = [
    "~", "`", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "-", "_", "+", "=", "{", "}", "[", "]", ";", ":", "'", '"', "\\", "|", "<", ",", ">", ".", "?", "/"
]

export const SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS = [
    "~", "`", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "_", "+", "=", "{", "}", "[", "]", ";", ":", "'", '"', "\\", "|", "<", ">", ".", "?", "/"
]

export const CHARACTER_ARRAY_FOR_NUMBER_INPUT = [
    "e", "E", "-", "+", "."
]


export const EXCLUDE_NUMBER_INPUT = [
    "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"
]

export const SPECIAL_CHARACTER_NUMBER_ARRAY = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "~",
    "`",
    "!",
    "@",
    "#",
    "$",
    "%",
    "^",
    "&",
    "*",
    "(",
    ")",
    "-",
    "_",
    "+",
    "=",
    "{",
    "}",
    "[",
    "]",
    ";",
    ":",
    "'",
    '"',
    "\\",
    "|",
    "<",
    ",",
    ">",
    ".",
    "?",
    "/",
  ];


