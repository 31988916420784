import React from "react";
import { Container, Row, Button } from "react-bootstrap"
import { Modal } from "react-bootstrap";
import { Assets } from "./image";
import { InputGroup, Form } from "react-bootstrap";
import {  FilterCrossIcon, RecentSearchesIcon, SearchModalIcon } from "./svg";

const SearchModal = (props) => {
  return (
    <div>
      <Modal
        {...props}
        className="search-modal"
        size="md"
        aria-labelledby="contained-modal-title-center"
      >
        <Modal.Body>
          <div>
            <InputGroup className="mb-3 ">
              <InputGroup.Text>
                <SearchModalIcon />
              </InputGroup.Text>
              <Form.Control
                placeholder="Search"
                aria-label="Username"
                aria-describedby="basic-addon1"
                className="searchbar"
              />
              <InputGroup.Text className="filter-cross">
                <Button onClick={() => props.setShowFilter(false)}>
                  <FilterCrossIcon />
                </Button>
              </InputGroup.Text>
            </InputGroup>
          </div>
          <div className="recent-searches-heading">Recent Searches</div>
          <div className="d-flex align-items-center mt_10">
            <RecentSearchesIcon />
            <div className="john-doe">John Doe</div>
          </div>
          <div className="d-flex align-items-center mt_10">
            <RecentSearchesIcon />
            <div className="john-doe">John Doe</div>
          </div>

          <div className="recent-searches-heading">Recommended</div>

          <div className="search-box-container">
            <div className="search-box">
              <div className="d-flex">
                <img src={Assets.SearchAvatar1} />
                <div>
                  <h2>Logan Klitsch</h2>
                  <p>Rugby player</p>
                </div>
              </div>
            </div>

            <div className="search-box">
              <div className="d-flex">
                <img src={Assets.SearchAvatar1} />
                <div>
                  <h2>Syinite</h2>
                  <p>Streamer</p>
                </div>
              </div>
            </div>

            <div className="search-box">
              <div className="d-flex">
                <img src={Assets.SearchAvatar1} />
                <div>
                  <h2>James Sibley</h2>
                  <p>Singer</p>
                </div>
              </div>
            </div>

            <div className="search-box">
              <div className="d-flex">
                <img src={Assets.SearchAvatar1} />
                <div>
                  <h2>ItaliaChick11</h2>
                  <p>Streamer</p>
                </div>
              </div>
            </div>

            <div className="search-box">
              <div className="d-flex">
                <img src={Assets.SearchAvatar1} />
                <div>
                  <h2>JP Zamora</h2>
                  <p>Singer</p>
                </div>
              </div>
            </div>

            <div className="search-box">
              <div className="d-flex">
                <img src={Assets.SearchAvatar1} />
                <div>
                  <h2>Logan Klitsch</h2>
                  <p>Rugby player</p>
                </div>
              </div>
            </div>

            <div className="search-box">
              <div className="d-flex">
                <img src={Assets.SearchAvatar1} />
                <div>
                  <h2>Logan Klitsch</h2>
                  <p>Rugby player</p>
                </div>
              </div>
            </div>

            <div className="search-box">
              <div className="d-flex">
                <img src={Assets.SearchAvatar1} />
                <div>
                  <h2>Logan Klitsch</h2>
                  <p>Rugby player</p>
                </div>
              </div>
            </div>
            <div className="search-box">
              <div className="d-flex">
                <img src={Assets.SearchAvatar1} />
                <div>
                  <h2>Logan Klitsch</h2>
                  <p>Rugby player</p>
                </div>
              </div>
            </div>
            <div className="search-box">
              <div className="d-flex">
                <img src={Assets.SearchAvatar1} />
                <div>
                  <h2>Logan Klitsch</h2>
                  <p>Rugby player</p>
                </div>
              </div>
            </div>
            <div className="search-box">
              <div className="d-flex">
                <img src={Assets.SearchAvatar1} />
                <div>
                  <h2>Logan Klitsch</h2>
                  <p>Rugby player</p>
                </div>
              </div>
            </div>
            <div className="search-box">
              <div className="d-flex">
                <img src={Assets.SearchAvatar1} />
                <div>
                  <h2>Logan Klitsch</h2>
                  <p>Rugby player</p>
                </div>
              </div>
            </div>
            <div className="search-box">
              <div className="d-flex">
                <img src={Assets.SearchAvatar1} />
                <div>
                  <h2>Logan Klitsch</h2>
                  <p>Rugby player</p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SearchModal;
