import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import review1 from "./images/review-1.png";
import review2 from "./images/review-2.png";
import review3 from "./images/review-3.png";
import { Rating } from "./svgs";
import SideMenu from "./SideMenu";

const OrderReviewPage = () => {
  return (
    <>
      <div className="mainpadding">
        <section className="main-review-section margin-bottom-150">
          <Container fluid>
            <Row>
              <Col
                xs={12}
                xl={3}
                xxl={2}
                md={3}
                sm={12}
                className="sidebar-class-col"
              >
                <SideMenu />
              </Col>

              <Col md={9} xl={9} xxl={8} className="order-review-card-width">
                <div className="review-section">
                  <h6>Reviews</h6>
                  <div className="review-cards">
                    <Row>
                      <Col sm={6} md={6} xl={12}>
                        <Row>
                          <Col xl={4}>
                            <div className="review-img-section">
                              <div className="cards mb-3">
                                <Row className="g-0">
                                  <Col xs={12} xl={5}>
                                    <img
                                      src={review1}
                                      className="img-fluid rounded-start h-100"
                                      alt="review"
                                    />
                                  </Col>
                                  <Col xs={12} xl={7}>
                                    <div className="card-body py-0 px-3">
                                      <h5 className="card-title">
                                        Nike Air Max
                                      </h5>
                                      <p className="card-text">$125.00</p>
                                      <small>x1</small>
                                      <div className="rating">
                                        <Rating />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Col>
                          <Col xl={8} className="my-auto">
                            <div className="review-para">
                              <p>
                                I loved this Shoes so much as soon as I tried it
                                on I knew I had to buy it in another color. I am
                                5'3 about 155lbs and I carry all my weight in my
                                upper body. When I put it on I felt like it
                                thinned me put and I got so many compliments.
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={6} md={6} xl={12}>
                        <Row>
                          <Col xl={4}>
                            <div className="review-img-section">
                              <div className="cards mb-3">
                                <Row className="g-0">
                                  <Col xs={12} xl={5}>
                                    <img
                                      src={review1}
                                      className="img-fluid rounded-start h-100"
                                      alt="review"
                                    />
                                  </Col>
                                  <Col xs={12} xl={7}>
                                    <div className="card-body py-0 px-3">
                                      <h5 className="card-title">
                                        Nike Air Max
                                      </h5>
                                      <p className="card-text">$125.00</p>
                                      <small>x1</small>
                                      <div className="rating">
                                        <Rating />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Col>
                          <Col xl={8} className="my-auto">
                            <div className="review-para">
                              <p>
                                I loved this Shoes so much as soon as I tried it
                                on I knew I had to buy it in another color. I am
                                5'3 about 155lbs and I carry all my weight in my
                                upper body. When I put it on I felt like it
                                thinned me put and I got so many compliments.
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={6} md={6} xl={12}>
                        <Row>
                          <Col xl={4}>
                            <div className="review-img-section">
                              <div className="cards mb-3">
                                <Row className="g-0">
                                  <Col xs={12} xl={5}>
                                    <img
                                      src={review1}
                                      className="img-fluid rounded-start h-100"
                                      alt="review"
                                    />
                                  </Col>
                                  <Col xs={12} xl={7}>
                                    <div className="card-body py-0 px-3">
                                      <h5 className="card-title">
                                        Nike Air Max
                                      </h5>
                                      <p className="card-text">$125.00</p>
                                      <small>x1</small>
                                      <div className="rating">
                                        <Rating />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Col>
                          <Col xl={8} className="my-auto">
                            <div className="review-para">
                              <p>
                                I loved this Shoes so much as soon as I tried it
                                on I knew I had to buy it in another color. I am
                                5'3 about 155lbs and I carry all my weight in my
                                upper body. When I put it on I felt like it
                                thinned me put and I got so many compliments.
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
};

export default OrderReviewPage;
