import React from 'react'
import { NavLink } from 'react-router-dom'

const TestimonialLayout = () => {
  return (
    <>
          <NavLink to="/test-style-one">Testimonial Style One</NavLink>
    </>
  )
}

export default TestimonialLayout