import React from 'react'


const PageLayout = (props) => {
  return (
    <>
    <div className='setting-body'>
    {props.children}
    </div>

    </>
  )
}

export default PageLayout