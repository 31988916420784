import React from "react";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const Newsletter = () => {
  return (
    <>
      <div className="main-newsletter-section">
        <Container>
          <Row>
            <Col lg={6}>
              <h2>Join 2,000+ subscribers</h2>
              <p>Stay in the loop with everything you need to know.</p>
            </Col>
            <Col lg={6}>
              <div className="email-div">
                <Form>
                  <Form.Control type="email" placeholder="Enter your email" />
                  <Button>Subscribe</Button>
                </Form>
              </div>
              <p className="text-center privacy">
                We care about your data in our <NavLink to="#">privacy policy.</NavLink>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Newsletter;
