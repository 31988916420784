import React from "react";
import { NavLink } from "react-router-dom";
import { Assets } from "../../constant/images";

const CategoriesStyleOne = () => {
  return (
    <>
      <div className="main-categories-div">
        <div className="top-heading">
          <h2>BROWSE OUR CATEGORIES</h2>
        </div>
        <div className="category-card-section">
        <div className="category-card">
            <div className="col-inner">
              <NavLink to="#">
                <div className="box-image">
                  <img src={Assets.CategoryOne} alt="" />
                </div>
                <div className="box-text text-center">
                    <div className="box-text-inner">
                    <h5 class="uppercase header-title"> Men</h5>
                    <p class="is-xsmall uppercase count "> 9 Products</p>
                    </div>
                </div>
              </NavLink>
            </div>
          </div>
          <div className="category-card">
            <div className="col-inner">
              <NavLink to="#">
                <div className="box-image">
                  <img src={Assets.CategoryTwo} alt="" />
                </div>
                <div className="box-text text-center">
                    <div className="box-text-inner">
                    <h5 class="uppercase header-title"> Women</h5>
                    <p class="is-xsmall uppercase count "> 9 Products</p>
                    </div>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoriesStyleOne;
