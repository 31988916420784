import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Navigation from "./navigation/Navigation";
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/scss/style.scss";

function App() {
  useEffect(() => {
    // prevenet text selection on double click
    document.body.addEventListener(
      "mousedown",
      function (event) {
        if (event.detail > 1) {
          event.preventDefault();
        }
      },
      false
    );
  }, []);
  return (
    <BrowserRouter>
      <Navigation />
    </BrowserRouter>
  );
}

export default App;
