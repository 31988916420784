import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";


// import required modules
import { Navigation } from "swiper";



const ScrollableTabs = () => {
  const [activeTab, setActiveTab] = useState(1);

  function displayTab(tabNumber) {
    setActiveTab(tabNumber);
  }

  return (
    <>
      <div className="scrollable-tabs">
        <Swiper
          slidesPerView={3}
          // slidesPerGroup={3}
          centeredSlides={true}
          navigation={true}
          modules={[Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div onClick={() => {displayTab(1)}} className={`tabs ${activeTab === 1 ? 'active active-slide-css' : ''}`}>Tab 1</div>
          </SwiperSlide>
          <SwiperSlide>
            <div onClick={() => {displayTab(2)}} className={`tabs ${activeTab === 2 ? 'active  active-slide-css' : ''}`}>Tab 2</div>
          </SwiperSlide>
          <SwiperSlide>
            <div onClick={() => {displayTab(3)}} className={`tabs ${activeTab === 3 ? 'active  active-slide-css' : ''}`}>Tab 3</div>
          </SwiperSlide>
          <SwiperSlide>
            <div onClick={() => {displayTab(4)}} className={`tabs ${activeTab === 4 ? 'active active-slide-css' : ''}`}>Tab 4</div>
          </SwiperSlide>
          <SwiperSlide>
            <div onClick={() => {displayTab(5)}} className={`tabs ${activeTab === 5 ? 'active active-slide-css' : ''}`}>Tab 5</div>
          </SwiperSlide>
          <SwiperSlide>
            <div onClick={() => {displayTab(6)}} className={`tabs ${activeTab === 6 ? 'active active-slide-css' : ''}`}>Tab 6</div>
          </SwiperSlide>
          <SwiperSlide>
            <div onClick={() => {displayTab(7)}} className={`tabs ${activeTab === 7 ? 'active active-slide-css' : ''}`}>Tab 7</div>
          </SwiperSlide>
        </Swiper>
      </div>

      <div className="tabs-content">
        <div className={activeTab === 1 ? 'd-block' : 'd-none'}>Hello I am Tab 1</div>
        <div className={activeTab === 2 ? 'd-block' : 'd-none'}>Hello I am Tab 2</div>
        <div className={activeTab === 3 ? 'd-block' : 'd-none'}>Hello I am Tab 3</div>
        <div className={activeTab === 4 ? 'd-block' : 'd-none'}>Hello I am Tab 4</div>
        <div className={activeTab === 5 ? 'd-block' : 'd-none'}>Hello I am Tab 5</div>
        <div className={activeTab === 6 ? 'd-block' : 'd-none'}>Hello I am Tab 6</div>
        <div className={activeTab === 7 ? 'd-block' : 'd-none'}>Hello I am Tab 7</div>
      </div>
    </>
  );
};

export default ScrollableTabs;
