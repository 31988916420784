import React, { useState } from "react";
import { NavLink } from "react-bootstrap";
import {
  FaSearch,
  FaShoppingBag,
  FaShoppingCart,
  FaStar,
} from "react-icons/fa";
import { Assets } from "../../constant/images";

const ProductCardStyleOne = () => {
  const [productCards, setProductCards] = useState([
    {
      productImgOne: Assets.ProductCardOne,
      productImgTwo: Assets.ProductCardOneOne,
      badge: "sale",
      off: "20%",
      discountPrice: "$16.00",
      actualPrice: "$20.00",
      productTitle: "Men T-shirt",
    },
    {
        productImgOne: Assets.ProductCardTwo,
        productImgTwo: Assets.ProductCardTwoTwo,
        badge: "sale",
        off: "40%",
        discountPrice: "$40.00",
        actualPrice: "$60.00",
        productTitle: "Women T-shirt",
      },
  ]);
  return (
    <>
    <div className="main-product-card">
    <div className="container">
        <h3 className="h3">shopping Demo-1 </h3>
        <div className="row">
          {productCards.map((data) => (
            <>
              <div className="col-md-3 col-sm-6">
                <div className="product-grid">
                  <div className="product-image">
                    <NavLink href="#">
                      <img className="pic-1" src={data.productImgOne} />
                      <img className="pic-2" src={data.productImgTwo} />
                    </NavLink>
                    <ul className="social">
                      <li>
                        <NavLink href="" data-tip="Quick View">
                          {/* <i className="fa fa-search"></i>   */}
                          <FaSearch />
                        </NavLink>
                      </li>
                      <li>
                        <NavLink href="" data-tip="Add to Wishlist">
                          {/* <i className="fa fa-shopping-bag"></i> */}
                          <FaShoppingBag />
                        </NavLink>
                      </li>
                      <li>
                        <NavLink href="" data-tip="Add to Cart">
                          {/* <i className="fa fa-shopping-cart"></i> */}
                          <FaShoppingCart />
                        </NavLink>
                      </li>
                    </ul>
                    <span className="product-new-label">{data.badge}</span>
                    <span className="product-discount-label">{data.off}</span>
                  </div>
                  <ul className="rating">
                    <FaStar />

                    <FaStar />

                    <FaStar />

                    <FaStar />

                    <FaStar className="disable" />
                  </ul>
                  <div className="product-content">
                    <h3 className="title">
                      <NavLink href="#">{data.productTitle}</NavLink>
                    </h3>
                    <div className="price">
                      {data.discountPrice}
                      <span>{data.actualPrice}</span>
                    </div>
                    <NavLink className="add-to-cart" href="">
                      + Add To Cart
                    </NavLink>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </div>

    </>
  );
};

export default ProductCardStyleOne;
