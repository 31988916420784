import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaDownload, FaUser, FaYoutube, FaInstagram } from "react-icons/fa";
import CountUp from "react-countup";
import Aos from "aos";


const ReactCounterCards = () => {
  useEffect(function () {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <div className="mt-5">

      <Container>
        {/* <div className="try"></div> */}
        <div        
  >
            {/* <p>text</p> */}
          <Row>
            <Col md={3}>
              <div className="counter-card">
                <div className="counter-icon">
                  <FaDownload />
                </div>
                <div className="counter-count">
                  <CountUp start={50} end={250} />+
                </div>
                <div className="counter-heading">Downloads</div>
              </div>
            </Col>
            <Col md={3}>
              <div className="counter-card">
                <div className="counter-icon">
                  <FaUser />
                </div>
                <div className="counter-count">
                  <CountUp start={50} end={100} />+
                </div>
                <div className="counter-heading">Users</div>
              </div>
            </Col>
            <Col md={3}>
              <div className="counter-card">
                <div className="counter-icon">
                  <FaYoutube />
                </div>
                <div className="counter-count">
                  <CountUp start={50} end={1000} />+
                </div>
                <div className="counter-heading">Views</div>
              </div>
            </Col>
            <Col md={3}>
              <div className="counter-card">
                <div className="counter-icon">
                  <FaInstagram />
                </div>
                <div className="counter-count">
                  <CountUp start={50} end={500} />+
                </div>
                <div className="counter-heading">Followers</div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default ReactCounterCards;
