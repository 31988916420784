import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import AsyncSelect, { components } from 'react-select';
import { NormalSearch, NoSearchFound, SearchSvg } from '../../constant/svgs';

const SearchBar = () => {
    const [options, setOptions] = useState(
        [
            { value: 'Reports', label: 'Reports' },
            { value: 'Revenue', label: 'Revenue' },
            { value: 'Tenure', label: 'Tenure' },
            { value: 'Employees', label: 'Employees' },
            { value: 'Daily Checklist', label: 'Daily Checklist' }
        ]
    );

    const clearArray = () => {
        setOptions([]);
    }
    //Styling fot the dropdown 
    const colourStyles = {
        control: styles => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { data, isDisabled, isFocused, isSelected, }) => {
            return {
                ...styles,
                backgroundColor: isDisabled ? 'red' : 'white',
                cursor: "pointer",
                color: '#989898',
                textAlign: "left",
            }
        },
    };

    const SelectMenuButton = ({ children, ...props }) => {
        return (
            <components.MenuList  {...props}>
                {children.length > 0 ? <div className="menuSearchHead  mb-3">
                    <p className='menu-heading m-0 font-weight-bold'>Recent searches</p>
                    <p className='m-0 clearText' onClick={clearArray}>Clear All</p>
                </div> : null
                }

                {children.length > 0 ? children?.map((child, i) => (
                    <div className='d-flex alig-items-center search-wrapper' key={i}>
                        <NormalSearch />{child}
                    </div>
                )) : <div className='nosearchResult'>
                    <div className="noSearchImg">
                        <NoSearchFound />
                    </div>
                    <p className='text-center'>No Search Found</p>
                </div>
                }
            </components.MenuList >
        )
    }

    const Control = ({ children, ...props }) => (
        <components.Control {...props}>
            <SearchSvg />   {children}
        </components.Control>
    );



    return (
        <>

            <Row>
                <Col xs={12}>
                    <div className="w-100 d-flex flex-column justify-content-center align-items-center m-4">
                        <h4 className='mb-4'>Search Bar</h4>
                        <div className="searchBarContainer">
                            <AsyncSelect
                                className="searchBar"
                                classNamePrefix="searchBar"
                                loadOptions={() => Promise.resolve(options)}
                                options={options}
                                placeholder="Search"
                                styles={colourStyles}
                                // menuIsOpen={true} //When we need to open the menu
                                isSearchable
                                isClearable
                                components={{
                                    Control, // Indicates the view of the select dropdown
                                    IndicatorSeparator: () => null,  // Indicates the seperate line on the select controller
                                    DropdownIndicator: () => null, // Indicates the arrow dropdown on the react select 
                                    Indicator: () => null,
                                    MenuList: SelectMenuButton
                                }}
                            />
                        </div>
                    </div>
                </Col>
            </Row>


        </>


    )
}

export default SearchBar