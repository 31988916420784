import React, {useState} from "react";
import SearchModal from "./SearchModal";
import { SearchIcon } from "./svg";
const SearchModalLayout = () => {
  const [showsearch, setShowSearch] = useState(false);
  return (
    <>
      <div
        onClick={() => setShowSearch(true)}
        className="icons-bg cursor-variable"
      >
        <SearchIcon />
      </div>

      <SearchModal
        show={showsearch}
        onHide={() => setShowSearch(false)}
        showSearch={showsearch}
        setShowFilter={setShowSearch}
      />
    </>
  );
};

export default SearchModalLayout;
