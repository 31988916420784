import React, { useEffect, useRef } from 'react';
import { Assets } from '../../constant/images';

const ProductSizeChart = () => {
    const sizeOptionsRef = useRef(null);
    useEffect(() => {
        const sizeOptions = sizeOptionsRef.current.querySelectorAll(".sizeTable > button");

        const handleClick = (event) => {
            const wasSelected = event.currentTarget.classList.contains('selectedSize');
            sizeOptions.forEach(item => {
                item.classList.remove('selectedSize');
                document.body.classList.remove('sizeSelected');
            });
            if (!wasSelected) {
                event.currentTarget.classList.add('selectedSize');
                document.body.classList.add('sizeSelected');
                // console.log('Size ' + event.currentTarget.textContent + ' was selected');
            }
        }

        sizeOptions.forEach(item => {
            item.addEventListener('click', handleClick);
        });

        // cleanup function
        return () => {
            sizeOptions.forEach(item => {
                item.removeEventListener('click', handleClick);
            });
        }
    }, []);
    return (
        <>
            <section className="section product-chart-section">
                <div className="container">
                    <div className="product pt-5">
                        <div className="details">
                            <h1>Yeezy Boost 700 Wave Runner</h1>
                            <div className="sizeTable" ref={sizeOptionsRef}>
                                <h3>
                                    <span>Select&nbsp;your&nbsp;</span>size<span>&nbsp;(US Men)</span>
                                </h3>
                                <button>6</button>
                                <button>6½</button>
                                <button>7</button>
                                <button>7½</button>
                                <button>8</button>
                                <button>8½</button>
                                <button>9</button>
                                <button>9½</button>
                                <button>10</button>
                                <button>10½</button>
                                <button>11</button>
                                <button>11½</button>
                                <button>12</button>
                                <button>12½</button>
                                <button>13</button>
                            </div>
                            <div className="callsToAction">
                                <button>Add to Cart</button>
                                <button>Check Out</button>
                            </div>
                        </div>
                        <div className="gallery">
                            <img
                                src={Assets.productShoe}
                                alt="product-img"
                            />
                            <div className="price">
                                $365<span>USD</span>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

        </>
    )
}

export default ProductSizeChart