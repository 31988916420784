import React, { Fragment, useState } from 'react';
import { Row, Col, NavLink } from 'react-bootstrap';
import { Assets } from '../../../constant/images';
import { Location, PhoneIcon, MailEnvelope, TekRevolLogo, FacebookLogo, TwitterLogo, InstagramLogo, TelegramPlane } from '../../../constant/svgs';
import { footerData } from './data'
import "./seconFooter.scss"



const SecondFooter = () => {
    const [footerLinks, setFooterLinks] = useState(footerData);

    console.log(footerLinks, "footerData")
    return (
        <div className="second-footer-css">
        <footer className="second-footer">
            <div className="container">
                <div className="footer-top">
                    <Row>
                        <Col lg={8}>
                            <div className="d-flex align-items-center flex-wrap">
                                <div className="footer-logo mb-0">
                                    <NavLink href="/">
                                        <img
                                            src={Assets.loremIpsumLogo2}
                                            alt="Second slide"
                                            className=''
                                        />
                                    </NavLink>
                                    <div className="footer-text mt-4">
                                        <p>Lorem ipsum dolor sit amet, consec tetur adipisicing elit, sed do eiusmod tempor incididuntut consec tetur adipisicing
                                            elit,Lorem ipsum dolor sit amet.</p>
                                    </div>
                                </div>
                                <div className="social-list">
                                    <a href="#" className='social-item'>
                                        <div className="social-link">
                                            <FacebookLogo />
                                        </div>
                                    </a>
                                    <a href="#" className='social-item'>
                                        <div className="social-link">
                                            <TwitterLogo />
                                        </div>
                                    </a>
                                    <a href="#" className='social-item'>
                                        <div className="social-link">
                                            <InstagramLogo />
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="footer-widget">
                                <div className="footer-widget-heading">
                                    <h3>Subscribe</h3>
                                </div>
                                <div className="footer-text mb-25">
                                    <p>Don’t miss to subscribe to our new feeds, kindly fill the form below.</p>
                                </div>
                                <div className="subscribe-form">
                                    <form action="#">
                                        <input type="text" placeholder="Email Address" />
                                        <button className='subscribe-button'>
                                            <TelegramPlane />
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="foooter-middle">
                    <Row>
                        {footerLinks?.footerslinkData?.map((v, i) => (
                            <Fragment index={i}>
                                <Col lg={2} sm={6} xs={6}>
                                    <div className="footer-box">
                                        <h6 className="footer-head">{v?.heading}</h6>
                                        <ul className="footer-link-list">
                                            {v?.footerlinks?.map((d, j) => (
                                                <li className="footer-link-item" index={j}>
                                                    <a href={d?.link} className="footer-link-link">{d?.name}</a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </Col>
                            </Fragment>
                        ))}
                    </Row>
                </div>
                <div className="footer-bottom">

                    <div className="copyright-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 text-center text-lg-left">
                                    <div className="copyright-text">
                                        <p>Copyright © 2023, All Right Reserved <a href="https://www.tekrevol.com/">TekRevol</a></p>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                                    <div className="footer-menu">
                                        <ul>
                                            <li><a href="#">Home</a></li>
                                            <li><a href="#">Terms</a></li>
                                            <li><a href="#">Privacy</a></li>
                                            <li><a href="#">Policy</a></li>
                                            <li><a href="#">Contact</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer >
        </div>
    )
}

export default SecondFooter


// return (
    // <h6 className="footer-head">{data?.heading}</h6>
    //                                     <ul className="footer-link-list">

    //                                     </ul>
//     <li className="footer-link-item">
//         <a href={data?.index} className="footer-link-link">{data?.name}</a>
//     </li>
// )