import React from "react";
import { Container, Row, Col, NavLink } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { NewCrossIcon, OrderConfirmed } from "./svg";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
const OrderPlacedPopup = (props) => {

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        
      >
        <section className="confirmed-order">
          <Container fluid>
            <Row>
              <Col>
                <div className="confirmed-order-container">
                  <div className="heading-div">
                  <h1>Order Placed</h1>
                  <span onClick={() => props.closedModal()}><NewCrossIcon /></span>
                  </div>
                
                  <div className="order-svg">
                    <OrderConfirmed />
                  </div>
                  <div className="middle-text">
                    <p>Congratulations!</p>
                    <p>Your order has been placed sucessfully</p>
                  </div>
                  <p className="small-para">
                    You will get an order confirmation email shortly.
                  </p>
                  <Link to="/home">Continue</Link>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Modal>
    </>
  );
};

export default OrderPlacedPopup;
