import React from "react";
import { CheckIcon } from "./svg";
import { Container, ModalBody, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

const CopyUrlModal = (props) => {
  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="main-copy-modal"
      >
   
          <section className="main-copy">
            <div className="img-div">
              <CheckIcon />
            </div>
            <div className="copy-text">
              <h2>{props.heading}</h2>
              <p>{props.subtext}</p>
            </div>
          </section>
    
      </Modal>
    </>
  );
};

export default CopyUrlModal;
