import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { useForm } from "react-hook-form";

const OtpVerifyForm = () => {
  const [otp, setOtp] = useState("");
  const [oerror, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [counter, setCounter] = useState(30);

  useEffect(() => {
    let timer =
      counter > 0 &&
      setInterval(() => {
        setCounter(counter - 1);
      }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [counter]);

  const {
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const handleOtpChange = (otp) => {
    setOtp(otp);
    if (otp.length === 0) {
      setError(true);
      setErrorMessage("Please Enter OTP code");
    } else if (otp.length > 0 && otp.length < 5) {
      setError(true);
      setErrorMessage("OTP code is incomplete");
    } else {
      setError(false);
    }
  };

  const navigate = useNavigate();
  const onSubmit = async (data) => {
    if (otp.length === 0) {
      setError(true);
      setErrorMessage("Please Enter OTP code");
    } else if (otp.length > 0 && otp.length < 5) {
      setError(true);
      setErrorMessage("OTP code is incomplete");
    } else {
      navigate("/");
    }
  };

  return (
    <>
      <div className="main-auth-form">
        <div className="auth_from">
          <h1>OTP Verification</h1>
          <p>Enter OTP code sent to you via email</p>
          <Form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
            <div className="otp-div">
              <OtpInput
                className="otpinputfeild"
                value={otp}
                numInputs={5}
                onChange={(otp) => handleOtpChange(otp)}
                containerStyle={false}
                inputStyle={false}
                focusStyle={false}
                isInputNum={true}
                separator={false}
              />

              <div className="timer">
                {oerror && (
                  <small className="text-red ms-1">{errorMessage}</small>
                )}
                <p className="text-end ms-auto">
                  Resend in:{" "}
                  <span>
                    00:{counter < 10 && "0"}
                    {counter}
                  </span>
                </p>
              </div>
            </div>

            <div className="from_box btn-commom mt-40">
              <Button type="submit">Verify</Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default OtpVerifyForm;
