import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import ReactStars from "react-rating-stars-component";
import { Assets } from "../../constant/images";
import { CartIcon, ChatIcon, HeartIcon, ShoppingCartIcon } from "../../constant/svgs";


const ProductDesignTwo = () => {
  return (
    <Container>
      <Row>
        <Col md={6} lg={4} xl={4}>
        <div>
        <div className="product-card-two">
          <div className="img-wrapper">
            <div className="front"><img className="img-fluid" src={Assets.Girlone}/></div>
            <div className="back">
              <img className="img-fluid" src={Assets.Girltwo}/>
              <div className="heart"><HeartIcon/></div>
              <div className="cart"><CartIcon/></div>
              <div className="chat"><ChatIcon/></div>
            </div>
          </div>
          <div className="product-desc">Khaadi Long Shirts</div>
          <div className="sub-desc">Description: Long Shirts, white and Blue color. This is the description of dress.</div>
        </div>
      </div>
        </Col>
        <Col md={6} lg={4} xl={4}>
        <div>
        <div className="product-card-two">
          <div className="img-wrapper">
            <div className="front"><img className="img-fluid" src={Assets.Girlone}/></div>
            <div className="back">
              <img className="img-fluid" src={Assets.Girltwo}/>
              <div className="heart"><HeartIcon/></div>
              <div className="cart"><CartIcon/></div>
              <div className="chat"><ChatIcon/></div>
            </div>
          </div>
          <div className="product-desc">Khaadi Long Shirts</div>
          <div className="sub-desc">Description: Long Shirts, white and Blue color. This is the description of dress.</div>
        </div>
      </div>
        </Col>
        <Col md={6} lg={4} xl={4}>
        <div>
        <div className="product-card-two">
          <div className="img-wrapper">
            <div className="front"><img className="img-fluid" src={Assets.Girlone}/></div>
            <div className="back">
              <img className="img-fluid" src={Assets.Girltwo}/>
              <div className="heart"><HeartIcon/></div>
              <div className="cart"><CartIcon/></div>
              <div className="chat"><ChatIcon/></div>
            </div>
          </div>
          <div className="product-desc">Khaadi Long Shirts</div>
          <div className="sub-desc">Description: Long Shirts, white and Blue color. This is the description of dress.</div>
        </div>
      </div>
        </Col>
        <Col md={6} lg={4} xl={4}>
        <div>
        <div className="product-card-two">
          <div className="img-wrapper">
            <div className="front"><img className="img-fluid" src={Assets.Girlone}/></div>
            <div className="back">
              <img className="img-fluid" src={Assets.Girltwo}/>
              <div className="heart"><HeartIcon/></div>
              <div className="cart"><CartIcon/></div>
              <div className="chat"><ChatIcon/></div>
            </div>
          </div>
          <div className="product-desc">Khaadi Long Shirts</div>
          <div className="sub-desc">Description: Long Shirts, white and Blue color. This is the description of dress.</div>
        </div>
      </div>
        </Col>

        <Col md={6} lg={4} xl={4}>
        <div>
        <div className="product-card-two">
          <div className="img-wrapper">
            <div className="front"><img className="img-fluid" src={Assets.Girlone}/></div>
            <div className="back">
              <img className="img-fluid" src={Assets.Girltwo}/>
              <div className="heart"><HeartIcon/></div>
              <div className="cart"><CartIcon/></div>
              <div className="chat"><ChatIcon/></div>
            </div>
          </div>
          <div className="product-desc">Khaadi Long Shirts</div>
          <div className="sub-desc">Description: Long Shirts, white and Blue color. This is the description of dress.</div>
        </div>
      </div>
        </Col>
        <Col md={6} lg={4} xl={4}>
        <div>
        <div className="product-card-two">
          <div className="img-wrapper">
            <div className="front"><img className="img-fluid" src={Assets.Girlone}/></div>
            <div className="back">
              <img className="img-fluid" src={Assets.Girltwo}/>
              <div className="heart"><HeartIcon/></div>
              <div className="cart"><CartIcon/></div>
              <div className="chat"><ChatIcon/></div>
            </div>
          </div>
          <div className="product-desc">Khaadi Long Shirts</div>
          <div className="sub-desc">Description: Long Shirts, white and Blue color. This is the description of dress.</div>
        </div>
      </div>
        </Col>
        <Col md={6} lg={4} xl={4}>
        <div>
        <div className="product-card-two">
          <div className="img-wrapper">
            <div className="front"><img className="img-fluid" src={Assets.Girlone}/></div>
            <div className="back">
              <img className="img-fluid" src={Assets.Girltwo}/>
              <div className="heart"><HeartIcon/></div>
              <div className="cart"><CartIcon/></div>
              <div className="chat"><ChatIcon/></div>
            </div>
          </div>
          <div className="product-desc">Khaadi Long Shirts</div>
          <div className="sub-desc">Description: Long Shirts, white and Blue color. This is the description of dress.</div>
        </div>
      </div>
        </Col>
        <Col md={6} lg={4} xl={4}>
        <div>
        <div className="product-card-two">
          <div className="img-wrapper">
            <div className="front"><img className="img-fluid" src={Assets.Girlone}/></div>
            <div className="back">
              <img className="img-fluid" src={Assets.Girltwo}/>
              <div className="heart"><HeartIcon/></div>
              <div className="cart"><CartIcon/></div>
              <div className="chat"><ChatIcon/></div>
            </div>
          </div>
          <div className="product-desc">Khaadi Long Shirts</div>
          <div className="sub-desc">Description: Long Shirts, white and Blue color. This is the description of dress.</div>
        </div>
      </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductDesignTwo;
