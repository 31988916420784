
import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import LogoutAlert from "./LogoutAlert";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  ProfileIcon,
  CardIcon,
  WalletIcon,
  OrderIcon,
  AdIcon,
  ReviewsIcon,
  SettingIcon,
  LogoutIcon,
  PointerArrowIcon,
} from "./svgs";

import { FaBars } from "react-icons/fa";
const SideMenu = ({ name, ...props }) => {
  const [modalShow, setModalShow] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const toggleShow = () => setShow((s) => !s);

  const location = useLocation();
  const path = location.pathname.split("/")[1];
  return (
    <>
      <section className="side-menu">
        <Nav defaultActiveKey="/editprofile" className="flex-column">
          <div className="inputfield">
            <NavLink to="/edit-profile-page">
              
              <span>
                <ProfileIcon />
              </span>
              Edit Profile
            </NavLink>
          </div>
          <div className="inputfield">
            <NavLink to="/ad-page">
              <span>
                <AdIcon />
              </span>
              Advertisements <span className="number">2</span>
            </NavLink>
          </div>
          <div className="inputfield">
            <NavLink to="/order-review-page">
              <span>
                <ReviewsIcon />
              </span>
              Reviews
            </NavLink>
          </div>
          <div className="inputfield">
            <NavLink
              to="/settings/changePasswordPage"
              className={path === "settings" && "active"}
            >
              <span>
                <SettingIcon />
              </span>
              Settings
            </NavLink>
          </div>
          <div className="inputfield">
            <NavLink className="noactive" onClick={() => setModalShow(true)}>
              <span>
                <LogoutIcon />
              </span>
              Log Out
            </NavLink>
          </div>
        </Nav>
      </section>

      <Button
        variant="primary"
        onClick={toggleShow}
        className="me-2 sidebar-btn-canvas"
      >
        <FaBars />
      </Button>

      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Side Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <section className="side-menu-offcanvas">
            <Nav defaultActiveKey="/editprofile" className="flex-column">
              <div className="inputfield">
                <ProfileIcon />
                <NavLink to="/editprofilepage"> Edit Profile</NavLink>
              </div>
              <div className="inputfield">
                <CardIcon />
                <NavLink to="/BrandCardPage"> Brand Cards</NavLink>
              </div>
              <div className="inputfield">
                <WalletIcon />
                <NavLink to="/WalletPage"> Wallet</NavLink>
              </div>
              <div className="inputfield">
                <OrderIcon />
                <NavLink to="/MyOrders"> My Orders</NavLink>
              </div>
              <div className="inputfield">
                <AdIcon />
                <NavLink to="/AdvertisementPage">
                  {" "}
                  Advertisements <span>2</span>
                </NavLink>
              </div>
              <div className="inputfield">
                <ReviewsIcon />
                <NavLink to="/OrderReviewPage"> Reviews</NavLink>
              </div>
              <div className="inputfield">
                <SettingIcon />
                <NavLink to="/settings/ChangePasswordPage"> Settings</NavLink>
              </div>
              <div className="inputfield">
                <LogoutIcon />
                <NavLink
                  className="noactive"
                  onClick={() => setModalShow(true)}
                >
                  Log Out
                </NavLink>
              </div>
            </Nav>
          </section>
        </Offcanvas.Body>
      </Offcanvas>
      <LogoutAlert show={modalShow} onHide={() => setModalShow(false)} />
  
    </>
  );
};

export default SideMenu;
