import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import SignupForm from "../components/auth/SignupForm";
import SigninForm from "../components/auth/SigninForm";
import ForgetPasswordForm from "../components/auth/ForgetPasswordForm";
import CreateNewPassForm from "../components/auth/CreateNewPassForm";
import OtpVerifyForm from "../components/auth/OtpVerifyForm";
import ProductDesignOne from "../components/ProductCard/ProductDesignOne";
import ProductDesignTwo from "../components/ProductCard/ProductDesignTwo";
import SearchBar from "../components/searchBar/SearchBar";
import TypewriteBanner from "../components/TypewriteBanner/TypewriteBanner";
import ServicesCard from "../components/ServicesCard/ServicesCard";
import AccordionSection from "../components/AccordionSection/AccordionSection";
import Tiles from "../components/Tiles/Tiles";
import Tiles2 from "../components/Tiles2/Tiles2";
import TableComponent from "../components/Table/TableComponent";
import ChartGraph from "../components/Graphs/Chart/ChartGraph";
import LineGraph from "../components/Graphs/Chart/LineGraph";
import TwoLineGraph from "../components/Graphs/Chart/TwoLineGraph";
import Sidebar from "../components/Sidebar/Sidebar";
import NewSidebar from "../components/NewSidebar/NewSidebar";
import TabsComponent from "../components/TabsComponent/TabsComponent";
import BaileyComponents from "../components/BaileyComponents/BaileyComponents";
import Home from "../components/Home/Home";
import ReactCounter from "../components/ReactCounter/ReactCounter";
import ReactCounterCards from "../components/ReactCounterCards/ReactCounterCards";
import ScrollableTabs from "../components/ScrollableTabs/ScrollableTabs";
import Particlesbg from "../components/ParticlesBg/Particlesbg";

// Nabeel Routes
import BannerFirst from "../components/banners/BannerFirst";
import BannerSecond from "../components/banners/BannerSecond";
import HoverProducts from "../components/hoverProducts/HoverProducts";
import ProductSizeChart from "../components/productSizeChart/ProductSizeChart";
import DashboardPopup from "../components/DashboardPopup/DashboardPopup";
import PopupCallingScreen from "../components/DashboardPopup/PopupCallingScreen";

import TestimonialsStyleOne from "../components/testimonials/TestimonialsStyleOne";
import TestimonialLayout from "../components/testimonials/TestimonialLayout";
import CategoriesStyleOne from "../components/categories/CategoriesStyleOne";
import Newsletter from "../components/newsletter/Newsletter";
import ProductCardStyleOne from "../components/ProductCard/ProductCardStyleOne";
import OurTeamStyleOne from "../components/ourteam/OurTeamStyleOne";

import OurTeamStyleTwo from "../components/ourteam/OurTeamStyleTwo";
import SearchBarStyleOne from "../components/search/SearchBarStyleOne";
import ProductCardStyleTwo from "../components/ProductCard/ProductCardStyleTwo";
import FaqStyleOne from "../components/faq/FaqStyleOne";
import NotificationLayout from "../components/notificationmodal/NotificationLayout";
import AdvertisementPage from "../components/sidemenu/AdvertisementPage";
import EditProfile from "../components/sidemenu/EditProfile";
import OrderReviewPage from "../components/sidemenu/OrderReviewPage";
import ChangePasswordPage from "../components/sidemenu/ChangePasswordPage";
import TermsPage from "../components/sidemenu/TermsPage";
import PrivacyPage from "../components/sidemenu/PrivacyPage";
import ProductDetailPage from "../components/checkoutprocess/ProductDetailPage";
import ShoppingCart from "../components/checkoutprocess/ShoppingCart";
import PaymentMethodPage from "../components/checkoutprocess/PaymentMethodPage";
import SettingsLayout from "../components/settingscomp/SettingsLayout";
import ChangePassword from "../components/settingscomp/ChangePassword";
import PageLayout from "../components/settingscomp/PageLayout";
import Terms from "../components/settingscomp/Terms";
import Faq from "../components/settingscomp/Faq";
import SearchModalLayout from "../components/searchmodal/SearchModalLayout";
import SearchModal from "../components/searchmodal/SearchModal";
import FooterFirst from "../components/footers/first-footer/FooterFirst";
import SecondFooter from "../components/footers/second-footer/SecondFooter";
import ParentContainer from "../components/ParentContainer/ParentContainer";
import DashboardSidebar from "../components/DashboardSidebar/DashboardSidebar";
import AntdTable from "../components/AntdTable/AntdTable";
import AntdSubTable from "../components/AntdSubTable/AntdSubTable";
import ExpandableSearch from "../components/ExpandableSearch/ExpandableSearch";
import SigninPageSyntax from "../components/SigninPageSyntax/SigninPageSyntax";
import SelectRole from "../components/SelectRole/SelectRole";
import Toaster from "../components/ToastContainer/Toaster";

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate, location]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route
        path="/signup"
        element={<ParentContainer children={<SignupForm />} />}
      />
      <Route
        path="/signin"
        element={<ParentContainer children={<SigninForm />} />}
      />
      <Route
        path="/forget-password"
        element={<ParentContainer children={<ForgetPasswordForm />} />}
      />
      <Route
        path="/create-new-password"
        element={<ParentContainer children={<CreateNewPassForm />} />}
      />
      <Route
        path="/otp-verify"
        element={<ParentContainer children={<OtpVerifyForm />} />}
      />
      {/* Yousuf Routes */}
      <Route path="/product-design" element={<ProductDesignOne />} />
      <Route path="/product-design-2" element={<ProductDesignTwo />} />
      <Route path="/typewrite-banner" element={<TypewriteBanner />} />
      <Route path="/services-cards" element={<ServicesCard />} />
      <Route path="/accordion" element={<AccordionSection />} />
      <Route path="/search-bar" element={<SearchBar />} />
      <Route path="/tiles" element={<Tiles />} />
      <Route path="/tiles2" element={<Tiles2 />} />
      <Route path="/table" element={<TableComponent />} />
      <Route path="/chart" element={<ChartGraph />} />
      <Route path="/line" element={<LineGraph />} />
      <Route path="/twoline" element={<TwoLineGraph />} />
      <Route path="/sidebar" element={<Sidebar />} />
      <Route path="/sidebar-new" element={<NewSidebar />} />
      <Route path="/tabs-component" element={<TabsComponent />} />
      <Route path="/bailey-page" element={<BaileyComponents />} />
      <Route path="/react-counter" element={<ReactCounter />} />
      <Route path="/react-counter-cards" element={<ReactCounterCards />} />
      <Route path="/scrollable-tabs" element={<ScrollableTabs />} />
      <Route path="/particles" element={<Particlesbg />} />
      <Route
        path="/dashboard-popup"
        element={
          <PopupCallingScreen
            link1="/dashboard-popup-2"
            backstatus="none"
            border="33%"
            mainheading="Step 1"
            description="This is Step 1 Page"
            modalbody="You are on step 1 page press next to fo on step 2"
          />
        }
      />
      <Route
        path="/dashboard-popup-2"
        element={
          <PopupCallingScreen
            link1="/dashboard-popup-3"
            link2="/dashboard-popup"
            border="66%"
            backstatus="block"
            mainheading="Step 2"
            description="This is Step 2 Page"
            modalbody="You are on step 2 page press next to go on step 3 and back to go on step 2"
          />
        }
      />
      <Route
        path="/dashboard-popup-3"
        element={
          <PopupCallingScreen
            link1="/dashboard-popup"
            link2="/dashboard-popup-2"
            border="100%"
            mainheading="Step 3"
            description="This is Step 3 Page"
            modalbody="You are on step 3 page press back to go on step 2"
          />
        }
      />
      {/* <Route path="/dashboard-popup-4" element={<PopupCallingScreen link1="/dashboard-popup" link2="/dashboard-popup-3" border="100%"/>}/> */}
      {/* Nabeel Routes */}
      <Route path="/first-banner" element={<BannerFirst />} />
      <Route path="/second-banner" element={<BannerSecond />} />
      <Route path="/hover-products" element={<HoverProducts />} />
      <Route path="/product-size-chart" element={<ProductSizeChart />} />
      {/* Umair Routes */}
      <Route path="/testimonial" element={<TestimonialLayout />} />
      <Route path="/test-style-one" element={<TestimonialsStyleOne />} />
      <Route path="/category-style-one" element={<CategoriesStyleOne />} />
      <Route path="/newsletter-style-one" element={<Newsletter />} />
      <Route path="/product-card-style-one" element={<ProductCardStyleOne />} />
      <Route path="/our-team-style-one" element={<OurTeamStyleOne />} />
      <Route path="/our-team-style-two" element={<OurTeamStyleTwo />} />
      <Route path="/search-style-one" element={<SearchBarStyleOne />} />
      <Route path="/product-card-style-two" element={<ProductCardStyleTwo />} />
      <Route path="/faq-style-one" element={<FaqStyleOne />} />

      <Route path="/notification-layout" element={<NotificationLayout />} />
      <Route path="/ad-page" element={<AdvertisementPage />} />
      <Route path="/edit-profile-page" element={<EditProfile />} />
      <Route path="/order-review-page" element={<OrderReviewPage />} />
      <Route
        path="/settings/ChangePasswordPage"
        element={<ChangePasswordPage />}
      />
      <Route path="/settings/TermsPage" element={<TermsPage />} />
      <Route path="/settings/PrivacyPage" element={<PrivacyPage />} />
      <Route path="/product-detail-page" element={<ProductDetailPage />} />
      <Route path="/shopping-cart" element={<ShoppingCart />} />
      <Route path="/payment-method-page" element={<PaymentMethodPage />} />

      <Route
        path="/settings/change-password"
        element={
          <PageLayout
            children={<SettingsLayout children={<ChangePassword />} />}
          />
        }
      />
      <Route
        path="/settings/termsconditionspage"
        element={
          <PageLayout children={<SettingsLayout children={<Terms />} />} />
        }
      />
      <Route
        path="/settings/faq"
        element={
          <PageLayout children={<SettingsLayout children={<Faq />} />} />
        }
      />
      <Route path="/common-search" element={<SearchModalLayout />} />
      <Route path="/footers/first-footer" element={<FooterFirst />} />
      <Route path="/footers/second-footer" element={<SecondFooter />} />

      {/* Dashboard Sidebar Routings */}

      <Route path="/dashboard-sidebar" element={<DashboardSidebar />} />
      <Route
        path="/dashboard-sidebar/dashboard"
        element={
          <DashboardSidebar children={<div>This is dashboard Page</div>} />
        }
      />
      <Route
        path="/dashboard-sidebar/project"
        element={<DashboardSidebar children={<div>This is Project</div>} />}
      />
      <Route
        path="/dashboard-sidebar/nutrient"
        element={
          <DashboardSidebar children={<div>This is Nutrient Page</div>} />
        }
      />
      <Route
        path="/dashboard-sidebar/customer"
        element={
          <DashboardSidebar children={<div>This is Customer Sales Page</div>} />
        }
      />
      <Route
        path="/dashboard-sidebar/contractor"
        element={
          <DashboardSidebar children={<div>This is Contractors Page</div>} />
        }
      />
      <Route
        path="/dashboard-sidebar/report"
        element={<DashboardSidebar children={<div>This is Report Page</div>} />}
      />
      <Route
        path="/dashboard-sidebar/travel"
        element={
          <DashboardSidebar
            children={<div>This is Travel & Entertainment Page</div>}
          />
        }
      />
      <Route
        path="/dashboard-sidebar/sales"
        element={
          <DashboardSidebar
            children={<div>This is Sales & Marketing Page</div>}
          />
        }
      />
      <Route
        path="/dashboard-sidebar/workforce"
        element={
          <DashboardSidebar children={<div>This is Workforce Page</div>} />
        }
      />
      <Route
        path="/dashboard-sidebar/capex"
        element={<DashboardSidebar children={<div>This is CapEx Page</div>} />}
      />
      <Route
        path="/dashboard-sidebar/fx"
        element={<DashboardSidebar children={<div>This is FX Page</div>} />}
      />
      <Route
        path="/dashboard-sidebar/break"
        element={
          <DashboardSidebar
            children={<div>This is Break Even Analysis Page</div>}
          />
        }
      />
      <Route
        path="/dashboard-sidebar/utilities"
        element={
          <DashboardSidebar children={<div>This is Utilities Page</div>} />
        }
      />

      {/* Dashboard Sidebar Routings */}

      {/* Antd Table Route */}
      <Route path="/antdtable" element={<AntdTable />} />
      <Route path="/antdsubtable" element={<AntdSubTable />} />
      {/* Antd Table Routing */}

      {/* Expandable Search Routing */}
      <Route path="/expand-search" element={<ExpandableSearch />} />
      {/* Expandable Search Routing */}

      {/* Sign In Page Syntax */}
      <Route path="/signinsyntax" element={<SigninPageSyntax />} />
      {/* Sign In Page Syntax */}

      {/* Select Role */}
      <Route path="/select-role" element={<SelectRole />} />
      {/* Select Role */}

      {/* Select Role */}
      <Route path="/toaster" element={<Toaster />} />
      {/* Select Role */}
    </Routes>
  );
};

export default Navigation;
