import React from "react";
import { FaFacebookF, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import { Assets } from "../../constant/images";

const OurTeamStyleTwo = () => {
  return (
    <>
    <div className="our-team-section-two">
    <div id="team" class="team section-bg">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>Team</h2>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <div
                class="member d-flex align-items-start"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <div class="pic">
                  <img src={Assets.OurTeamTwo} class="img-fluid" alt="" />
                </div>
                <div class="member-info">
                  <h4>Walter White</h4>
                  <span>Chief Executive Officer</span>
                  <p>
                    Explicabo voluptatem mollitia et repellat qui dolorum quasi
                  </p>
                  <div class="social">
                    <a href="">
                      {/* <i class="fab fa-twitter"></i> */}
                      <FaTwitter />
                    </a>
                    <a href="">
                      {/* <i class="fa-brands fa-facebook-f"></i> */}
                      <FaFacebookF />
                    </a>
                    <a href="">
                      {/* <i class="fa-brands fa-instagram"></i> */}
                      <FaInstagram />
                    </a>
                    <a href="">
                      {/* <i class="fa-brands fa-linkedin"></i> */}
                      <FaLinkedin />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div
                class="member d-flex align-items-start"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <div class="pic">
                  <img src={Assets.OurTeamTwo} class="img-fluid" alt="" />
                </div>
                <div class="member-info">
                  <h4>Walter White</h4>
                  <span>Chief Executive Officer</span>
                  <p>
                    Explicabo voluptatem mollitia et repellat qui dolorum quasi
                  </p>
                  <div class="social">
                    <a href="">
                      {/* <i class="fab fa-twitter"></i> */}
                      <FaTwitter />
                    </a>
                    <a href="">
                      {/* <i class="fa-brands fa-facebook-f"></i> */}
                      <FaFacebookF />
                    </a>
                    <a href="">
                      {/* <i class="fa-brands fa-instagram"></i> */}
                      <FaInstagram />
                    </a>
                    <a href="">
                      {/* <i class="fa-brands fa-linkedin"></i> */}
                      <FaLinkedin />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div
                class="member d-flex align-items-start"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <div class="pic">
                  <img src={Assets.OurTeamTwo} class="img-fluid" alt="" />
                </div>
                <div class="member-info">
                  <h4>Walter White</h4>
                  <span>Chief Executive Officer</span>
                  <p>
                    Explicabo voluptatem mollitia et repellat qui dolorum quasi
                  </p>
                  <div class="social">
                    <a href="">
                      {/* <i class="fab fa-twitter"></i> */}
                      <FaTwitter />
                    </a>
                    <a href="">
                      {/* <i class="fa-brands fa-facebook-f"></i> */}
                      <FaFacebookF />
                    </a>
                    <a href="">
                      {/* <i class="fa-brands fa-instagram"></i> */}
                      <FaInstagram />
                    </a>
                    <a href="">
                      {/* <i class="fa-brands fa-linkedin"></i> */}
                      <FaLinkedin />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div
                class="member d-flex align-items-start"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <div class="pic">
                  <img src={Assets.OurTeamTwo} class="img-fluid" alt="" />
                </div>
                <div class="member-info">
                  <h4>Walter White</h4>
                  <span>Chief Executive Officer</span>
                  <p>
                    Explicabo voluptatem mollitia et repellat qui dolorum quasi
                  </p>
                  <div class="social">
                    <a href="">
                      {/* <i class="fab fa-twitter"></i> */}
                      <FaTwitter />
                    </a>
                    <a href="">
                      {/* <i class="fa-brands fa-facebook-f"></i> */}
                      <FaFacebookF />
                    </a>
                    <a href="">
                      {/* <i class="fa-brands fa-instagram"></i> */}
                      <FaInstagram />
                    </a>
                    <a href="">
                      {/* <i class="fa-brands fa-linkedin"></i> */}
                      <FaLinkedin />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default OurTeamStyleTwo;
