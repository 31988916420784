import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Calendar, Clock } from './Tilessvg'

const Tiles = () => {
  return (
    <div className='mt-5'>
        <Container>
            <Row>
                <Col xs={12} sm={6} lg={3}>
                    <div className='tiles d-flex align-items-center justify-content-between'>
                        <div>
                            <div className='upper-heading'>OT Criteria</div>
                            <div className='lower-heading'>OT Hours</div>
                        </div>
                        <div className='icon-container'>
                            <Clock/>
                        </div>
                    </div>
                </Col>
                <Col xs={12} sm={6} lg={3}>
                    <div className='tiles d-flex align-items-center justify-content-between mt-5 mt-sm-0'>
                        <div>
                            <div className='upper-heading'>OT Criteria</div>
                            <div className='lower-heading'>OT Hours</div>
                        </div>
                        <div className='icon-container'>
                            <Calendar/>
                        </div>
                    </div>
                </Col>
                <Col xs={12} sm={6} lg={3}>
                    <div className='tiles d-flex align-items-center justify-content-between mt-5 mt-lg-0'>
                        <div>
                            <div className='upper-heading'>OT Criteria</div>
                            <div className='lower-heading'>OT Hours</div>
                        </div>
                        <div className='icon-container'>
                            <Clock/>
                        </div>
                    </div>
                </Col>
                <Col xs={12} sm={6} lg={3}>
                    <div className='tiles d-flex align-items-center justify-content-between mt-5 mt-lg-0'>
                        <div>
                            <div className='upper-heading'>OT Criteria</div>
                            <div className='lower-heading'>OT Hours</div>
                        </div>
                        <div className='icon-container'>
                            <Calendar/>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default Tiles