import React, {useState} from "react";
import { Link, NavLink } from "react-router-dom";
import { Assets } from "./images";
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import CopyUrlModal from "./CopyUrlModal";
import {
  CopyLink,
  PushNotificationIcon,
  ChangePasswordIcon,
  TermsIcon,
  FaqIcon,
  SettingShareIcon,
  DeleteIcon,
  LeftArrowIcon,
} from "./svg";
import ShareAppModal from "./ShareAppModal";
import DeleteAccount from "./DeleteAccount";
import ExitDeleteModal from "./ExitDeleteModal";

const SideMenu = () => {
  const [modalShowDeleteAccount, setModalShowDeleteAccount] =
    React.useState(false);
  const [modalShowShareApp, setModalShowShareApp] = React.useState(false);
  const [modalShowCopyUrl, setModalShowCopyUrl] = React.useState(false);
  const [modalexitdelete, setModalExitDelete] = useState(false);
  return (
    <>
      <section className="main-sidemenu">
        <div className="main-profile">
          <div className="profile-img">
            <img src={Assets.ProfileImg} alt="avatar" className="img-fluid" />
          </div>
          <div className="profile-info">
            <h2>Aiden Methew</h2>
            <Link target="_blank">
              <CopyLink /> commonthread/aiden_methew5
            </Link>
          </div>
        </div>

        <div className="navtabs">
          <Nav
            defaultActiveKey="/settings/ChangePasswordPage"
            className="flex-column"
          >
            <div className="noactive">
              <div className="d-flex align-items-center justify-content-between inputfield">
                <div className="first-div">
                  <PushNotificationIcon /> Push Notifications
                </div>
                <div className="second-div">
                  <Form.Check type="switch" id="custom-switch" />
                </div>
              </div>
            </div>

            <NavLink to="/settings/change-password">
              <div className="inputfield">
                <div className="first-div">
                  <ChangePasswordIcon /> Change Password
                </div>
                <div className="second-div">
                  <LeftArrowIcon />
                </div>
              </div>
            </NavLink>

            <NavLink to="/settings/termsconditionspage">
              <div className="inputfield">
                <div className="first-div">
                  <TermsIcon /> Terms & Conditions
                </div>
                <div className="second-div">
                  <LeftArrowIcon />
                </div>
              </div>
            </NavLink>

            <NavLink to="/settings/faq">
              <div className="inputfield">
                <div className="first-div">
                  <FaqIcon /> FAQs
                </div>
                <div className="second-div">
                  <LeftArrowIcon />
                </div>
              </div>
            </NavLink>

            <div
              className="modal-style"
              onClick={() => setModalShowShareApp(true)}
            >
              <div className="inputfield d-flex align-items-center justify-content-between">
                <div className="first-div">
                  <SettingShareIcon className="mr-18" /> <span>Share App</span>
                </div>
                <div className="second-div">
                  <LeftArrowIcon />
                </div>
              </div>
            </div>
            <ShareAppModal
              show={modalShowShareApp}
              onHide={() => setModalShowShareApp(false)}
              modalShowCopyUrl={modalShowCopyUrl}
              setModalShowCopyUrl={setModalShowCopyUrl}
              setModalShowShareApp={setModalShowShareApp}
            />
            <CopyUrlModal
              show={modalShowCopyUrl}
              onHide={() => setModalShowCopyUrl(false)}
              heading= "Copied Profile URL"
              subtext="Link copied to clipboard"
            />
            <div
              className="modal-style"
              onClick={() => setModalExitDelete(true)}
            >
              <div className="inputfield d-flex align-items-center justify-content-between">
                <div className="first-div">
                  <DeleteIcon className="mr-18" /> <span>Delete Account</span>
                </div>
                <div className="second-div">
                  <LeftArrowIcon />
                </div>
              </div>
            </div>

            <ExitDeleteModal
              heading="Delete Account"
              description="Are you sure you want to delete this account?"
              orangebtn="Yes"
              graybtn="No"
              link="/signin"
              onHide={() => setModalExitDelete(false)}
              show={modalexitdelete}
            />
          </Nav>
        </div>
      </section>
    </>
  );
};

export default SideMenu;
