import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { BlueStar, CareProviders, Finance, PatientsIcon, Star } from './Tiles2svg'

const Tiles2 = () => {
  return (
    <div>
      <Container>
          <Row>
            <Col sm={6} md={6} lg={4} xl={2}>
            <div className='icon-main-container'>
              <div className='d-flex align-items-center'>
                <div className='icon-container'  style={{background: "#e0f9fc"}}><PatientsIcon/></div>
                <div>
                  <div className='first-heading'>345</div>
                  <div className='second-heading'>Total Patients</div>
                </div>
              </div>
            </div>
            </Col>
            <Col sm={6} md={6} lg={4} xl={2}>
            <div className='icon-main-container'>
              <div className='d-flex align-items-center'>
                <div className='icon-container'  style={{background: "#fceaeb"}}><CareProviders/></div>
                <div>
                  <div className='first-heading'>345</div>
                  <div className='second-heading'>Total Patients</div>
                </div>
              </div>
            </div>
            </Col>
            <Col sm={6} md={6} lg={4} xl={2}>
            <div className='icon-main-container'>
              <div className='d-flex align-items-center'>
                <div className='icon-container'  style={{background: "#eeedfd"}}><Finance/></div>
                <div>
                  <div className='first-heading'>345</div>
                  <div className='second-heading'>Total Patients</div>
                </div>
              </div>
            </div>
            </Col>
            <Col sm={6} md={6} lg={4} xl={2}>
            <div className='icon-main-container'>
              <div className='d-flex align-items-center'>
                <div className='icon-container'  style={{background: "#fcfbea"}}><Star/></div>
                <div>
                  <div className='first-heading'>345</div>
                  <div className='second-heading'>Total Patients</div>
                </div>
              </div>
            </div>
            </Col>
            <Col sm={6} md={6} lg={4} xl={2}>
            <div className='icon-main-container'>
              <div className='d-flex align-items-center'>
                <div className='icon-container'  style={{background: "#e5f1f8"}}><BlueStar/></div>
                <div>
                  <div className='first-heading'>345</div>
                  <div className='second-heading'>Total Patients</div>
                </div>
              </div>
              </div>
            </Col>
            <Col sm={6} md={6} lg={4} xl={2}>
            <div className='icon-main-container'>
              <div className='d-flex align-items-center'>
                <div className='icon-container'  style={{background: "#e0f9fc"}}><PatientsIcon/></div>
                <div>
                  <div className='first-heading'>345</div>
                  <div className='second-heading'>Total Patients</div>
                </div>
              </div>
            </div>
            </Col>
          </Row>
      </Container>
      </div>
  )
}

export default Tiles2