import React from "react";
import { Button, Carousel } from "react-bootstrap";
import { Form } from "react-router-dom";
import { Assets } from "../../constant/images";

const SearchBarStyleOne = () => {
  return (
    <div className="search-section-css">
          <>
      {/* <div class="container">
        <div class="row pt-1 pb-1">
          <div class="col-lg-12 text-center">
            <h4 class="text-center">Carousel with Three input search</h4>
            <h6 class="text-center">
              awesome responsive image slider with a search bar
            </h6>
          </div>
        </div>
      </div> */}
      <div>
        <Carousel >
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={Assets.BannerImgOne}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={Assets.BannerImgTwo}
              alt="Second slide"
            />
          </Carousel.Item>
        </Carousel>
      </div>
      <div class="search-sec">
        <div class="container">
          <form action="#" method="post" novalidate="novalidate">
            <div class="row">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-3 col-md-3 col-sm-12 p-0">
                    <input
                      type="text"
                      class="form-control search-slt"
                      placeholder="Enter Pickup City"
                    />
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-12 p-0">
                    <input
                      type="text"
                      class="form-control search-slt"
                      placeholder="Enter Drop City"
                    />
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-12 p-0">
                    <select
                      class="form-control search-slt"
                      id="exampleFormControlSelect1"
                    >
                      <option>Select Vehicle</option>
                      <option>Example one</option>
                      <option>Example one</option>
                      <option>Example one</option>
                      <option>Example one</option>
                      <option>Example one</option>
                      <option>Example one</option>
                    </select>
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-12 p-0">
                    <button type="button" class="btn btn-danger wrn-btn">
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
    </div>
  );
};

export default SearchBarStyleOne;
