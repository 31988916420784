import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Assets } from "../../constant/images";
import Accordion from "react-bootstrap/Accordion";

const AccordionSection = () => {
  return (
    <div className="accordion-section">
      <Container>
        <Row>
          <div className="accordion-main-heading">
            Fast and Quality Services
          </div>
          <div className="accordion-para">
            Elegant makes it easy to order super soft, stylish custom printed
            everything that <br /> get your message into the world.
          </div>
          <Col md={6}>
            <div>
              <img className="img-fluid" src={Assets.AccordionImg} />
            </div>
          </Col>
          <Col md={{ span: 5, offset: 1 }}>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Printing Services</Accordion.Header>
                <Accordion.Body>
                Fast print, flyer, and pamphlet printing organization. Pleased with our past. Printing for what’s to come. What’s more, we do it right! A full administration printing background.

                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Digital Scanning</Accordion.Header>
                <Accordion.Body>
                Fast print, flyer, and pamphlet printing organization. Pleased with our past. Printing for what’s to come. What’s more, we do it right! A full administration printing background.

                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Design Services</Accordion.Header>
                <Accordion.Body>
                Fast print, flyer, and pamphlet printing organization. Pleased with our past. Printing for what’s to come. What’s more, we do it right! A full administration printing background.

                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Copying Services</Accordion.Header>
                <Accordion.Body>
                Fast print, flyer, and pamphlet printing organization. Pleased with our past. Printing for what’s to come. What’s more, we do it right! A full administration printing background.

                </Accordion.Body>
              </Accordion.Item>

            </Accordion>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AccordionSection;
