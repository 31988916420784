import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Typewriter from "typewriter-effect";
import { FaPlayCircle } from "react-icons/fa";


const TypewriteBanner = () => {
  return (
    <div className="typewriter">
        <Container>
        <div className="typewrite-banner">
          <Row>
            <Col md={5}>
              <div className="printing-made-easier">printing made easier</div>
              <div className="banner-heading">Fast and Quality</div>
              <div>
                <Typewriter
                  options={{
                    autoStart: true,
                    loop: true,
                  }}
                  onInit={(typewriter) => {
                    typewriter

                      .typeString("Services.")

                      .pauseFor(1000)
                      .deleteAll()
                      .typeString("Products.")
                      .pauseFor(1000)
                      .start();
                  }}
                />
              </div>
              <div className="description">What’s more, we do it right! A full administration printing background.</div>
              <Button className="red-btn">Let's Talk</Button>
              <Button className="watch-now">Watch Now <FaPlayCircle/></Button>
            </Col>
          </Row>
      </div>
        </Container>
    </div>
  );
};

export default TypewriteBanner;
