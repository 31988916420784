import React from "react";
import { Container, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link, NavLink } from "react-router-dom";
import { PopupCloseIcon } from "./svg";
import CopyUrlModal from "./CopyUrlModal";
import { ToastContainer, toast } from "react-toastify";
import { success } from "../../constant/msgs";
const ShareAppModal = (props) => {




  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="sm"
        className="copyurl main-share-app-modal"
      >

        <Modal.Body>
        <section className="share-app-modal">
          <Container>
            <Row>
              <div className="col-12">
                <div className="close-icon" onClick={props.onHide}>
                  <PopupCloseIcon />
                </div>
                <div className="setupprofile-content">
                  <h2>Share App</h2>
                  <p>Share the app to earn reward points</p>
                  <input
                    type="text"
                    value="commonthread/webapp"
                    class="form-control"
                    disabled
                  />
                  <div className="button-div">
                    <NavLink to="#"
                          onClick={() => {
                            // props.setModalShowCopyUrl(true)
                            props.setModalShowShareApp(false)
                            // setTimeout(() => {
                            //     props.setModalShowCopyUrl(false)
                            //   }, 1000);
                            success(
                                <>
                                  <div className="toast-text">
                                    <h2>Copied App URL</h2>
                                    <p>Link copied to clipboard</p>
                                  </div>
                                </>
                            );
                          }}>
                      Copy URL
                    </NavLink>
                  </div>
                </div>
              </div>

            </Row>
          </Container>
        </section>
        </Modal.Body>

      </Modal>
    </>
  );
};

export default ShareAppModal;
