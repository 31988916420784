import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import {
  FaHome,
  FaBaseballBall,
  FaYoutube,
  FaAddressCard,
  FaWordpress,
  FaArrowLeft,
} from "react-icons/fa";

const NewSidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="new-sidebar">
      <div
        className={
          isOpen ? "sidebar-new sidebar-open" : "sidebar-new sidebar-closed"
        }
      >
        <ul>
          <li>
            <div className={"sidebar__listItem"}>
              <div className={"sidebar__icon"}>
                <FaHome />
              </div>
              <CSSTransition
                in={isOpen}
                timeout={200}
                classNames={"fade"}
                unmountOnExit
              >
                <span>Dashboard</span>
              </CSSTransition>
            </div>
            <div className={"sidebar__listItem"}>
              <div className={"sidebar__icon"}>
                <FaBaseballBall />
              </div>
              <CSSTransition
                in={isOpen}
                timeout={200}
                classNames={"fade"}
                unmountOnExit
              >
                <span>Order Management</span>
              </CSSTransition>
            </div>
            <div className={"sidebar__listItem"}>
              <div  className={"sidebar__icon"}><FaYoutube /></div>
              <CSSTransition
                in={isOpen}
                timeout={200}
                classNames={"fade"}
                unmountOnExit
              >
                <span>Category Management</span>
              </CSSTransition>
            </div>
            <div className={"sidebar__listItem"}>
              <div  className={"sidebar__icon"}><FaWordpress /></div>
              <CSSTransition
                in={isOpen}
                timeout={200}
                classNames={"fade"}
                unmountOnExit
              >
                <span>Category Management</span>
              </CSSTransition>
            </div>
            <div className={"sidebar__listItem"}>
              <div  className={"sidebar__icon"}><FaAddressCard /></div>
              <CSSTransition
                in={isOpen}
                timeout={200}
                classNames={"fade"}
                unmountOnExit
              >
                <span>Category Management</span>
              </CSSTransition>
            </div>
          </li>
        </ul>
        <div className={!isOpen ? "navigation-btn active-navigation" : "navigation-btn"}         
            onClick={() => {
            setIsOpen(!isOpen);
          }}>
         <FaArrowLeft />
      </div>
      </div>
    </div>
  );
};

export default NewSidebar;
