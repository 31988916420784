import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { NewCheckIcon } from "./svg";
export const success = (msg) => toast.success(msg,{
    hideProgressBar:true,
    autoClose: true,
    position:"bottom-right",
    icon: <NewCheckIcon/>
});
export const error = (msg) => toast.error(msg,{
    hideProgressBar:true,
    autoClose: true,
    position:"bottom-right",
    icon: <NewCheckIcon/>
});