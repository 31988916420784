import React, { useEffect } from 'react';
import { NavLink } from 'react-bootstrap';
import { Assets } from '../../constant/images';

function initImagesList() {
    // Get all the list items
    var listItems = document.querySelectorAll('.hover-product-list .hover-product-list__item');

    // Add a mouseenter event listener to each list item
    listItems.forEach(function (item) {
        item.addEventListener('mouseenter', function () {
            this.classList.add('active');
            // remove the active class from all other list items
            var siblings = Array.prototype.filter.call(listItems, function (el) {
                return el !== this;
            }.bind(this));
            siblings.forEach(function (sibling) {
                sibling.classList.remove('active');
            });
        });

        item.addEventListener('mouseleave', function () {
            this.classList.remove('active');
            var defaultEl = document.querySelector('.default');
            if (defaultEl) defaultEl.classList.add('active');
        });
    });
}

const HoverProducts = () => {
    useEffect(() => {
        initImagesList()
    }, [])

    return (
        <>
            <section className="section hover-product-section">
                <div className="container">
                    <div className="hover-product-list">
                        <p className="list-title">Browse Collection</p>
                        <div className="hover-product-list__items">
                            <div className="hover-product-list__item default active">
                                <div className="left-side side">
                                    <div className="side-wrapper">
                                        <img
                                            width={854}
                                            height={1000}
                                            src={Assets.product1left}
                                            className="attachment-full size-full"
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className="right-side side">
                                    <div className="side-wrapper">
                                        <img
                                            width={854}
                                            height={1056}
                                            src={Assets.product2left}
                                            className="attachment-full size-full"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="hover-product-list__item">
                                <div className="left-side side">
                                    <NavLink href="#" className="side-wrapper"
                                    >
                                        <img
                                            width={854}
                                            height={1000}
                                            src={Assets.product2left}
                                            className="attachment-full size-full"
                                            alt=""
                                        />
                                    </NavLink>
                                </div>
                                <div className="item-title">
                                    <p className="side-title">
                                        <NavLink href="#">
                                            W
                                        </NavLink>
                                    </p>
                                    <p className="title">
                                        <NavLink href="#">T-shirts </NavLink>
                                    </p>
                                    <p className="side-title">
                                        <NavLink href="#">M </NavLink>
                                    </p>
                                </div>
                                <div className="right-side side">
                                    <NavLink
                                        href="#"
                                        className="side-wrapper"
                                    >
                                        <img
                                            width={854}
                                            height={1056}
                                            src={Assets.product2right}
                                            className="attachment-full size-full"
                                            alt=""
                                        />
                                    </NavLink>
                                </div>
                            </div>
                            <div className="hover-product-list__item">
                                <div className="left-side side">
                                    <NavLink
                                        href="#"
                                        className="side-wrapper"
                                    >
                                        <img
                                            width={854}
                                            height={1000}
                                            src={Assets.product3left}
                                            className="attachment-full size-full"
                                            alt=""
                                        />
                                    </NavLink>
                                </div>
                                <div className="item-title">
                                    <p className="side-title">
                                        <NavLink href="#">
                                            W
                                        </NavLink>
                                    </p>
                                    <p className="title">
                                        <NavLink href="#">Shirts</NavLink>
                                    </p>
                                    <p className="side-title">
                                        <NavLink href="#">M</NavLink>
                                    </p>
                                </div>
                                <div className="right-side side">
                                    <NavLink
                                        href="#"
                                        className="side-wrapper"
                                    >
                                        <img
                                            width={854}
                                            height={1056}
                                            src={Assets.product3right}
                                            className="attachment-full size-full"
                                            alt=""
                                        />
                                    </NavLink>
                                </div>
                            </div>
                            <div className="hover-product-list__item">
                                <div className="left-side side">
                                    <NavLink
                                        href="#"
                                        className="side-wrapper"
                                    >
                                        <img
                                            width={854}
                                            height={1000}
                                            src={Assets.product4left}
                                            className="attachment-full size-full"
                                            alt=""
                                        />
                                    </NavLink>
                                </div>
                                <div className="item-title">
                                    <p className="side-title">
                                        <NavLink href="#">W</NavLink>
                                    </p>
                                    <p className="title">
                                        <NavLink href="#">Life Style</NavLink>
                                    </p>
                                    <p className="side-title">
                                        <NavLink href="#">M</NavLink>
                                    </p>
                                </div>
                                <div className="right-side side">
                                    <NavLink
                                        href="#"
                                        className="side-wrapper"
                                    >
                                        <img
                                            width={854}
                                            height={1056}
                                            src={Assets.product4right}
                                            className="attachment-full size-full"
                                            alt=""
                                        />
                                    </NavLink>
                                </div>
                            </div>
                            <div className="hover-product-list__item">
                                <div className="left-side side">
                                    <NavLink
                                        href="#"
                                        className="side-wrapper"
                                    >
                                        <img
                                            width={854}
                                            height={1000}
                                            src={Assets.product5left}
                                            className="attachment-full size-full"
                                            alt=""
                                        />
                                    </NavLink>
                                </div>
                                <div className="item-title">
                                    <p className="side-title">
                                        <NavLink href="#">
                                            W
                                        </NavLink>
                                    </p>
                                    <p className="title">
                                        <NavLink href="#">Trousers</NavLink>
                                    </p>
                                    <p className="side-title">
                                        <NavLink href="#">M</NavLink>
                                    </p>
                                </div>
                                <div className="right-side side">
                                    <NavLink
                                        href="#"
                                        className="side-wrapper"
                                    >
                                        <img
                                            width={854}
                                            height={1056}
                                            src={Assets.product5right}
                                            className="attachment-full size-full"
                                            alt=""
                                        />
                                    </NavLink>
                                </div>
                            </div>
                            <div className="hover-product-list__item">
                                <div className="left-side side">
                                    <NavLink
                                        href="#"
                                        className="side-wrapper"
                                    >
                                        <img
                                            width={854}
                                            height={1000}
                                            src={Assets.product6left}
                                            className="attachment-full size-full"
                                            alt=""
                                        />
                                    </NavLink>
                                </div>
                                <div className="item-title">
                                    <p className="side-title">
                                        <NavLink href="#">
                                            W
                                        </NavLink>
                                    </p>
                                    <p className="title">
                                        <NavLink href="#">Shoes</NavLink>
                                    </p>
                                    <p className="side-title">
                                        <NavLink href="#">M</NavLink>
                                    </p>
                                </div>
                                <div className="right-side side">
                                    <NavLink
                                        href="#"
                                        className="side-wrapper"
                                    >
                                        <img
                                            width={854}
                                            height={1056}
                                            src={Assets.product6right}
                                            className="attachment-full size-full"
                                            alt=""
                                        />
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="hover-product-list__bottom-link">
                            <NavLink href="#" className="bold-link">
                                See all
                            </NavLink>
                        </div>
                    </div>;

                </div>
            </section>
        </>
    )
}

export default HoverProducts