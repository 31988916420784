import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { NavLink } from "react-router-dom";
import { Assets } from "../../constant/images";
import { CrossIcon, ProfileRightArrow } from "./Tablesvg";

function EditModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="delete-modal"
    >
        <div className="main-heading">{props.heading}</div>
        <p>Are you sure you want to {props.type}?</p>
        <div className="mt_30 d-flex justify-content-end">
            <Button onClick={props.onHide} className="sub-btn">Cancel</Button>
            <Button onClick={props.onHide}  className="main-btn">{props.btntext}</Button>
        </div>

    </Modal>
  );
}

export default EditModal;