import { height } from "@mui/system";
import React, { useState } from "react";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { NavLink } from "react-router-dom";

const DashboardPopup = (props) => {
  console.log(props, "popup");
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="stepper-modal"
      >
        <Modal.Header closeButton>
          <div>
            <div className="modal-title">{props.mainheading}</div>
            <div className="modal-desc">{props.description}</div>
          </div>
        </Modal.Header>
        <Modal.Body>
        {props.modalbody}
        </Modal.Body>
        <Modal.Footer>
          <NavLink to={props.link1}>
            <Button className="green-btn">Next</Button>
          </NavLink>
          <NavLink to={props.link2}>
            <Button
              style={{ display: `${props.backstatus}` }}
              className="gray-btn ms-2"
            >
              Back
            </Button>
          </NavLink>
        </Modal.Footer>
        <div
          className="modal-bottom-border"
          style={{width: `${props.border}`}}
        ></div>
      </Modal>
    </>
  );
};

export default DashboardPopup;
