export const Assets = {
    SmartWatch: require("../assets/images/productcard/smartwatchbg.jpg"),
    SmartWatchTwo: require("../assets/images/productcard/smartwatch.jpg"),
    ShoppingCart: require("../assets/images/productcard/shopping-cart.png"),
    Girlone: require("../assets/images/productcard/girl1.jpg"),
    Girltwo: require("../assets/images/productcard/girl2.jpg"),
    ServiceOne: require("../assets/images/typewritter-banner/one.png"),
    ServiceTwo: require("../assets/images/typewritter-banner/two.png"),
    ServiceThree: require("../assets/images/typewritter-banner/three.png"),
    ServiceFour: require("../assets/images/typewritter-banner/four.png"),
    AccordionImg: require("../assets/images/typewritter-banner/five.png"),
    Tableavatarone: require("../assets/images/Dashboard/tableone.png"),
    Tableavatartwo: require("../assets/images/Dashboard/tabletwo.png"),
    Tableavatarthree: require("../assets/images/Dashboard/tablethree.png"),
    Tableavatarfour: require("../assets/images/Dashboard/tablefour.png"),
    ProductOne: require("../components/BaileyComponents/product1.jpg"),
    ProductTwo: require("../components/BaileyComponents/product2.jpg"),
    ProductThree: require("../components/BaileyComponents/product3.jpg"),
    Demo : require("../components/BaileyComponents/demo.jpg"),
    bannerFirst1: require('../assets/images/banner/slider1.png'),
    bannerFirst2: require('../assets/images/banner/slider2.png'),
    bannerFirst3: require('../assets/images/banner/slider3.png'),
    bannerSecond1: require("../assets/images/secondBanner/second-ban-1.jpg"),
    bannerSecond2: require("../assets/images/secondBanner/second-ban-2.jpg"),
    bannerCard1: require('../assets/images/secondBanner/banner-card.jpg'),
    bannerCard2: require('../assets/images/secondBanner/deal-card.jpg'),
    product1left: require('../assets/images/hover-products/categrory-trousers-women.png'),
    product1right: require('../assets/images/hover-products/categrory-trousers-men.png'),
    product2left: require('../assets/images/hover-products/categrory-tshirts-women.png'),
    product2right: require('../assets/images/hover-products/categrory-tshirts-men.png'),
    product3left: require('../assets/images/hover-products/categrory-shirts-women.png'),
    product3right: require('../assets/images/hover-products/categrory-shirts-men.png'),
    product4left: require('../assets/images/hover-products/categrory-lifestyle-women.png'),
    product4right: require('../assets/images/hover-products/categrory-lifestyle-men.png'),
    product5left: require('../assets/images/hover-products/categrory-trousers-women.png'),
    product5right: require('../assets/images/hover-products/categrory-trousers-men.png'),
    product6left: require('../assets/images/hover-products/categrory-shoes-women.png'),
    product6right: require('../assets/images/hover-products/categrory-shoes-men.png'),
    productShoe: require("../assets/images/product-size-chart/shoe-product.png"),
    TestimonialAvatarOne: require("../assets/img/testimonial/avatar1.jpg"),
    CategoryOne: require("../assets/img/category/categoryone.jpeg"),
    CategoryTwo: require("../assets/img/category/categorytwo.jpeg"),
    ProductCardOne: require("../assets/img/productcard/productcardone.jpeg"),
    ProductCardOneOne: require("../assets/img/productcard/productcardoneone.jpeg"),
    ProductCardTwo: require("../assets/img/productcard/productcardtwo.jpeg"),
    ProductCardTwoTwo: require("../assets/img/productcard/productcardtwotwo.jpeg"),
    ProductCardThree: require("../assets/img/productcard/productcardthree.jpeg"),
    OurTeamOne: require("../assets/img/ourteam/ourteamone.jpg"),
    OurTeamTwo: require("../assets/img/ourteam/team-1.jpg"),
    OurTeamThree: require("../assets/img/ourteam/team-2.jpg"),
    OurTeamFour: require("../assets/img/ourteam/team-3.jpg"),
    BannerImgOne: require("../assets/img/searchbar/bannerone.jpg"),
    BannerImgTwo: require("../assets/img/searchbar/bannertwo.jpg"),
    loremIpsumLogo: require("../assets/images/loremLogo.png"),
    loremIpsumLogo2: require("../assets/images/loremLogo2.png"),
    cuetiplogo: require("../assets/images/DashboardSidebar/cuetiplogo.png"),
    cuetipsignin: require("../components/SigninPageSyntax/images/cuetiplogo.png")
}