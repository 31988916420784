import React from "react";
import { Container } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Tab1 } from "./svg";

const TabsComponent = () => {
  return (
    <Container>
      <Tabs
        defaultActiveKey="profile"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="home" title={ <Tab1/>}>
          Tab 1
          <Tab1/>
        </Tab>
        <Tab eventKey="profile" title="Profile">
          Tab 2
        </Tab>
        <Tab eventKey="contact" title="Contact">
          Tab 3
        </Tab>
      </Tabs>
    </Container>
  );
};

export default TabsComponent;
