import React, { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { SellerIcon, ShoppingCart } from "../../constant/svgs";
import { useForm } from "react-hook-form";

const SelectRole = () => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data)=>{
    const {role} = data
    if(role === "shopping"){
      navigate("/signup-buyer")
    }
    else{
      navigate("/signup-seller")
    }
  }


  return (
    <div>
        <Container>
        <Row>
        <Col md={6}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="select-role-form">
              <div className="wrapper mb-3 mt-3">
                <Form.Check
                  value="shopping"
                  name="role"
                  type="radio"
                  id="shopping"
                  {...register("role", {
                    required: {
                      value: true,
                      message: "Required",
                    },
                  })}
                />
                <label htmlFor="shopping">
                  <div className="select-role">
                    <div className="icon-container">
                      <ShoppingCart />
                    </div>
                    <div className="registered-as">
                      Register as <b>Buyer</b>
                    </div>
                  </div>
                </label>
              </div>

              <div className="wrapper">
                <Form.Check
                  value="brand_owner"
                  name="role"
                  type="radio"
                  id="brand-owner"
                  {...register("role", {
                    required: {
                      value: true,
                      message: "Required",
                    },
                  })}
                />
                <label htmlFor="brand-owner">
                  <div className="select-role">
                    <div className="icon-container">
                      <SellerIcon />
                    </div>
                    <div className="registered-as">
                      Register as <b>Seller</b>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </Form>
        </Col>
      </Row>
        </Container>
    </div>
  );
};

export default SelectRole;