import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { CloseIcon } from "../../constant/svgs";
import { OpenIcon } from "../../constant/svgs";
import {
  VALIDATIONS,
  VALIDATIONS_TEXT,
} from "../../constant/app-constant";
const CreateNewPassForm = () => {
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const password = watch("password");
  const navigate = useNavigate();
  const onSubmit = (data) => {
    navigate("/");
  };

  return (
    <>
      <div>
        <div>
          <h1>Create New Password</h1>
          <p>Create a new password</p>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
              <Form.Label>New Password</Form.Label>
              <div>
                <Form.Control
                  type={!showCurrentPassword ? "password" : "text"}
                  placeholder="Enter your password"
                  maxLength={VALIDATIONS_TEXT.PASSWORD_MAX}
                  {...register("password", {
                    minLength: {
                      value: VALIDATIONS.PASSWORD_MIN,
                      message: VALIDATIONS_TEXT.PASSWORD_MIN,
                    },
                    maxLength: {
                      value: VALIDATIONS.PASSWORD_MAX,
                      message: VALIDATIONS_TEXT.PASSWORD_MAX,
                    },
                    required: {
                      value: true,
                      message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                    },
                    pattern: {
                      value: /^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/,
                      message: VALIDATIONS_TEXT.PASSWORD_FORMAT,
                    },
                  })}
                />
                {errors.password && (
                  <small className="text-red error_from">
                    {errors.password.message}
                  </small>
                )}
              </div>
              <span className="iconfeild">
                {showCurrentPassword ? (
                  <span
                    className="toggle-password"
                    onClick={() => setShowCurrentPassword(false)}
                  >
                    <OpenIcon />
                  </span>
                ) : (
                  <span
                    onClick={() => setShowCurrentPassword(true)}
                    className="toggle-password"
                  >
                    <CloseIcon />
                  </span>
                )}
              </span>
            </Form.Group>
            <Form.Group>
              <Form.Label>Confirm Password</Form.Label>
              <div>
                <Form.Control
                  type={!showConfirmPassword ? "password" : "text"}
                  placeholder="Enter your password"
                  maxLength={VALIDATIONS_TEXT.PASSWORD_MAX}
                  {...register("password_confirmation", {
                    minLength: {
                      value: VALIDATIONS.PASSWORD_MIN,
                      message: VALIDATIONS_TEXT.PASSWORD_MIN,
                    },
                    maxLength: {
                      value: VALIDATIONS.PASSWORD_MAX,
                      message: VALIDATIONS_TEXT.PASSWORD_MAX,
                    },
                    required: {
                      value: true,
                      message: VALIDATIONS_TEXT.CONFIRM_PASSWORD_REQUIRED,
                    },
                    validate: (value) =>
                      value === password ||
                      "Password and Confirm Password must be same",
                  })}
                />
                {errors.password_confirmation && (
                  <small className="text-red error_from">
                    {errors.password_confirmation.message}
                  </small>
                )}
              </div>
              <span className="iconfeild">
                {showConfirmPassword ? (
                  <span
                    className="toggle-password"
                    onClick={() => setShowConfirmPassword(false)}
                  >
                    <OpenIcon />
                  </span>
                ) : (
                  <span
                    onClick={() => setShowConfirmPassword(true)}
                    className="toggle-password"
                  >
                    <CloseIcon />
                  </span>
                )}
              </span>
            </Form.Group>

            <div>
              <Button type="submit">Verify</Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default CreateNewPassForm;
