import React from "react";

const Terms = () => {
  return (
    <>
      <section className="main-terms">
        <h2>Terms and Conditions</h2>
        <div className="terms-content">
          <p>
            This is dummy copy. It is not meant to be read. It has been placed
            here solely to demonstrate the look and feel of finished, typeset
            text. Only for show. He who searches for meaning here will be sorely
            disappointed. These words are here to provide the reader with a
            basic impression of how actual text will appear in its final
            presentation. Think of them merely as actors on a paper stage, in a
            performance devoid of content yet rich in form. That being the case,
            there is really no point in your continuing to read them. After all,
            you have many other things you should be doing.
          </p>
          <p>
            It breaks up the intimidating blocks of text and makes the page more
            inviting to read, which is again ironic, considering this is dummy
            copy. It is not meant to be read. Someday this space will be
            occupied by real copy that is meant to be read. What other form of
            filler would dare be so politically incorrect? This is dummy copy.
            It is not meant to be read. Good thing.
          </p>
          <p>
            This is dummy copy. It is not meant to be read. Accordingly, it is
            difficult to figure out when to end it. If this were real copy, it
            would have ended long ago, because‚Äîas we all know‚Äîno one reads
            body copy, and even fewer read body copy this long. But then, this
            is dummy copy. It is not meant to be read. Period.
          </p>
        </div>
      </section>
    </>
  );
};

export default Terms;
